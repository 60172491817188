import ApexTest4 from './ApexTest4';

function DripCalculatorGraph (data) {
    const data2 = data.data[0]
    

    let startCap = data2['price'] * data2['amount'];
    //console.log(start_cap);
    let divYieldOriginal = data2['divYield']
    let amount = parseInt(data2['amount']);
    let originalStartCap = startCap;
    let totalNewShares = 0;
    let receivedShares = 0;
    let receivedDividends = 0;
    let capitalEndPeriod = 0;
    let newShares =0;
    let interimDividendCounter = 0;
    let arr = [];
    //console.log(data2['holding']);
    for (let years = 0; years < data2['holding']; years++) {
        let yearlyReceivedDividends = 0;
        let totalNewShares = 0;
        //console.log(years);
        let toInsert = '';
        //console.log('year: ', years);
        let dividendsPerYear = 0;
        let divYield = divYieldOriginal * ((1 + data2['divIncrease']/100) ** years)
        let sharePrice = parseInt(data2['price']) * ((1 + data2['priceIncrease']/100) ** years)
        for (let x = 1; x < 5; x++) {
            receivedDividends = receivedDividends + (data2['drip'] * (startCap * divYield)/(4 * 100));
            interimDividendCounter = parseInt(data2['drip']) * (startCap * divYield)/(4 * 100);
            newShares = parseInt(((3 * data2['contribution']) + receivedDividends) / sharePrice);
            
            //console.log(parseInt((3 * data2['contribution']) + receivedDividends))
            //console.log(sharePrice)
            ////console.log(newShares);
            let remainder = parseInt((3 * (data2['contribution']) + receivedDividends) % sharePrice);
            if (newShares > 0 ) {
                receivedDividends = remainder
            }    
            capitalEndPeriod = startCap + (newShares * sharePrice) + remainder;
            //console.log('after ', 3*x, 'months: ',startCap, '(dividends = ', interimDividendCounter,') and we buy ', newShares, 'new shares and leftover is ', remainder)
            totalNewShares = newShares;
            dividendsPerYear = dividendsPerYear + interimDividendCounter;
            //console.log('amount: ', amount, '+ newShares:', totalNewShares)
            amount = amount + totalNewShares;
            //console.log('hierbove ...', amount)
            startCap = amount * sharePrice;
            //console.log('amount ', amount,' ,newshares: ', newShares);
            startCap = amount * sharePrice;
        }
        // ********* output to screen
        //console.log ('needs to be exported to screen');
        //console.log('end of year shares: ', amount)
        //console.log('end of year balance: ', capitalEndPeriod)
        //console.log('annual dividend: ', dividendsPerYear)
        toInsert = { 'Year': years,
                    'StartCapital' : startCap,
                    'EndShares': amount,
                    'EndDiv': dividendsPerYear,
                    'end of year balance' : capitalEndPeriod
                            };
        //console.log(toInsert)
        arr.push(toInsert);
        //console.log(arr);


    }

    return <div>In Graph Part<ApexTest4 data = {arr}/></div>
}

export default DripCalculatorGraph