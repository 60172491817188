import { useGetStockDetailsQuery } from '../../store';

function GetDetailsBis(id) {
    console.log('in get stock detailsBis, id is: ', id);
    const { data, error, isLoading } = useGetStockDetailsQuery(id);
    

    if(data){
       //console.log(data[0]['symbol']);
       //console.log(data); 
       let newArrayOG = data;
       newArrayOG = [...data]
       return newArrayOG[0];
    }
    
}
  
export default GetDetailsBis    ;