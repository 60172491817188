import ApexTestAll from '../Charts/ApexTestAll';

function SectorDiversification({data}) {
    //console.log(data.sectors2);
    const Cyclical = ['Basic materials', 'Consumer Cyclical', 'Financial Services', 'Real Estate'];
    const Defensive = ['Consumer staples', 'Healthcare', 'Utilities'];
    const Sensitive = ['Communication Services', 'Energy', 'Industrials', 'Technology'];
    let yydata = [];
    let xxdata = ['Cyclical', 'Defensive', 'Sensitive'];
    let total = 0
    Cyclical.map((sector) => {
        let test = data.sectors2.filter((x) => {
            //console.log('total: ', total)
            if (x.sector === sector){
            //console.log('sector: ', x.sector)
            //console.log('sector.value: ', x.value)
            total = total + x.value}
             })
        //console.log('test in map loop: ',total) 
        
    })
    yydata.push(total);

    let total1 = 0
    Defensive.map((sector) => {
        let test = data.sectors2.filter((x) => {
            //console.log('total: ', total1)
            if (x.sector === sector){
            //console.log('sector: ', x.sector)
            //console.log('sector.value: ', x.value)
            total1 = total1 + x.value}
             })
        //console.log('test in map loop: ',total1) 
        
    })
    yydata.push(total1)

    let total2 = 0
    Sensitive.map((sector) => {
        let test = data.sectors2.filter((x) => {
            //console.log('total: ', total2)
            if (x.sector === sector){
            //console.log('sector: ', x.sector)
            //console.log('sector.value: ', x.value)
            total2 = total2 + x.value}
             })
        //console.log('test in map loop: ',total2) 
        
    })
    yydata.push(total2)


    //console.log('yydata: ', yydata)
    
    //console.log('test ...: ', test)
    
    //console.log(xxdata, yydata);
    return (
        <div >
        
            <ApexTestAll y_data = {yydata} x_axis = {xxdata} /></div>
            )
}

export default SectorDiversification