
import { useGetStockAnalysis_2Query } from '../../store';

function GetAnalysis_2(x) {
    //console.log('in get analysis_2:');
    const { data, error, isLoading } = useGetStockAnalysis_2Query(x);
    

    if(data){
       //console.log(data[0]['symbol']); 
       //console.log('data in _2: ', data); 
       //if (data.length === 0) {
       // console.log('ticker does not exist')
      // }
       let newArrayOG = data;
       newArrayOG = [...data]
       return newArrayOG[0];
    }
    
}

export default GetAnalysis_2;