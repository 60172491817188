
import { GiRailRoad } from 'react-icons/gi';
import StockAnalysis from '../Details/StockAnalysis';
import StockAnalysis_2 from '../Details/StockAnalysis_2';
import { useState } from 'react';
import Button from '../Button';
import GetDetailsBis from './StockDetailsBis';


function HeadToHead() {
    const [t1, setT1] = useState(null);
    const [t2, setT2] = useState(null);
    const [start, setStart] = useState(false)
    const [inputBox, setInputBox] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    let id2 =  'JNJ';
    const data = GetDetailsBis(id2);
    console.log('data in headtohead is: ', data       )

    //const t2 = 'ENB'
    //let ticker1 = [{'dividendYielPercentageTTM':0, 'dividendPerShareTTM': 0, 'payoutRatioTTM': 0 }]
    //let ticker2 = [];
    let contentLower = 'head to head'
    
    let ticker1 = StockAnalysis(t1);
    let ticker1A //= GetDetailsBis(t1);
    let ticker1B
    ticker1A = GetDetailsBis(t1);
    ticker1B = StockAnalysis_2(t1);
    //console.log('length of ticker1:', ticker1)
    //if (ticker1) {
        //console.log('there should be a result');
    //}
    //if (!ticker1) {
        //console.log('there is a wrong ticker')
    //}
    let ticker2 = StockAnalysis(t2);
    let ticker2A //= GetDetailsBis(t2);
    let ticker2B;
    ticker2A = GetDetailsBis(t2);
    ticker2B = StockAnalysis_2(t2);
    const handleStart =() =>  {
        
        if (ticker1 && ticker2) {
            
            setStart(true)
            setErrorMessage('')
            setInputBox(false)    
        };

        if (!ticker1 || !ticker2) {
            setErrorMessage('Please enter a valid ticker')
            //console.log('een van de 2 bestaat niet')   
        };

    }
    const handleReset = () => {
        setT1(null)
        setT2(null)
        setInputBox(true)
        setStart(false)
    }

    const xxx = {bold: 'text-center text-blue-600 font-bold'};
    //originally ticker2 && ticker1
    //if(start){
        //console.log('ticker1 & ticker2')
        //console.log(data[0]['symbol']);
        //console.log(ticker2); 
        //console.log('payout ticker 1: ',ticker1['payoutRatioTTM'])
        //console.log('payout ticker 2: ',ticker2['payoutRatioTTM'])
        const space = ' '

        function twoDigits(number) {
            return parseFloat(number).toFixed(2)
        }

        contentLower = (<div className = 'flex gap-x-3'>
                    <div  className = ' flex flex-col'>
                    
                        <div className = 'text-center text-blue-600 font-bold text-lg'>{t1}</div>
                        
                        <div className = 'whitespace-pre'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1A['price'])}$ </div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{ticker1A['exchangeShortName']} </div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1A['beta'])} </div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['dividendYielPercentageTTM'])}% </div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['dividendPerShareTTM'])}$</div>: <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['payoutRatioTTM']*100)}</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['returnOnAssetsTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['returnOnEquityTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1B['roicTTM']*100)}%</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['peRatioTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['priceToSalesRatioTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['priceToBookRatioTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['priceToFreeCashFlowsRatioTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['pegRatioTTM'])}%</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['currentRatioTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['quickRatioTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['debtEquityRatioTTM']*100)}%</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1B['evToSalesTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1B['evToSalesTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1B['evToFreeCashFlowTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1B['enterpriseValueOverEBITDATTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1B['evToOperatingCashFlowTTM'])}</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['grossProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['operatingProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['pretaxProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['netProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker1['effectiveTaxRateTTM']*100)}%</div> : <div>-</div>}    
                    <div></div>
                    </div>
                    <div className = ' flex flex-col'>
                        <div className = 'text-center'>vs.</div>
                        <div className = 'text-center font-bold'>General</div>
                        <div className = 'text-center'>Current price</div>
                        <div className = 'text-center'>Exchange</div>
                        <div className = 'text-center'>Beta-score</div>
                        <div className = 'text-center font-bold mt-1'>Dividends</div>
                        <div className = 'text-center '>Dividend yield</div>
                        <div className = 'text-center'>Dividend last 12m</div>
                        <div className = 'text-center'>Dividend payout ratio</div>
                        <div className = 'text-center font-bold mt-1'>Financial Efficiency Ratios</div>
                        <div className = 'text-center '>Return on Assets (RoA)</div>
                        <div className = 'text-center '>Return on Equity (RoE)</div>
                        <div className = 'text-center '>Return on Invested Capital (RoIC)</div>
                        <div className = 'text-center font-bold mt-1'>Valuation Ratios</div>
                        <div className = 'text-center '>Price to Equity (PE)</div>
                        <div className = 'text-center '>Price to Sales (PS)</div>
                        <div className = 'text-center '>Price to Book (PB)</div>
                        <div className = 'text-center '>Price to Free Cash Flow (P/FCF)</div>
                        <div className = 'text-center '>Price to Earnings to Grow (PEG)</div>
                        <div className = 'text-center font-bold mt-1'>Valuation Ratios</div>
                        <div className = 'text-center '>Current Ratio</div>
                        <div className = 'text-center '>Quick Ratio</div>
                        <div className = 'text-center '>Debt to Equity</div>
                        <div className = 'text-center font-bold mt-1'>Enterprise Ratios</div>
                        <div className = 'text-center '>EV/Sales</div>
                        <div className = 'text-center '>EV/Earnings</div>
                        <div className = 'text-center '>EV/FCF</div>
                        <div className = 'text-center '>EV/EBITDA</div>
                        <div className = 'text-center '>EV/EBIT</div>
                        <div className = 'text-center font-bold mt-1'>Margins</div>
                        <div className = 'text-center '>Gross Margin</div>
                        <div className = 'text-center '>Operational Margin</div>
                        <div className = 'text-center '>Pretax Margin</div>
                        <div className = 'text-center '>Net Margin</div>
                        <div className = 'text-center '>Tax Rate</div>
                        
                        <div></div>
                    </div>
                    <div  className = ' flex flex-col'>
                        <div className = 'text-center text-blue-600 font-bold text-lg'>{t2}</div>
                        
                        <div className = 'whitespace-pre'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2A['price'])}$ </div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{ticker2A['exchangeShortName']} </div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2A['beta'])} </div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['dividendYielPercentageTTM'])}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['dividendPerShareTTM'])}$</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['payoutRatioTTM']*100)}</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['returnOnAssetsTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['returnOnEquityTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2B['roicTTM']*100)}%</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['peRatioTTM'])}</div> : '-'}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['priceToSalesRatioTTM'])}</div> : <div>-</div>}
                    {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['priceToBookRatioTTM'])}</div> : <div>-</div>}
                    {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['priceToFreeCashFlowsRatioTTM'])}</div> : <div>-</div>}
                    {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['pegRatioTTM'])}%</div> : <div>-</div>}
                    <div className = 'whitespace-pre mt-1'> {' '}</div>
                    {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['currentRatioTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['quickRatioTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['debtEquityRatioTTM']*100)}%</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2B['evToSalesTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2B['evToSalesTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2B['evToFreeCashFlowTTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2B['enterpriseValueOverEBITDATTM'])}</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2B['evToOperatingCashFlowTTM'])}</div> : <div>-</div>}
                        <div className = 'whitespace-pre mt-1'> {' '}</div>
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['grossProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['operatingProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['pretaxProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['netProfitMarginTTM']*100)}%</div> : <div>-</div>}
                        {start ? <div className = {`${xxx.bold}`}>{twoDigits(ticker2['effectiveTaxRateTTM']*100)}%</div> : <div>-</div>}    
                    <div></div>
                </div>



                
            </div>
        )
     //}
    return <div>
                <div className = 'justify-center rounded-lg bg-white shadow-md px-5 py-3 mb-5'>
                    <div className = 'flex flex-column justify-center'>
                    <div>
                        <div className = 'pt-3'>
                        <label>Ticker 1:</label>
                        <input name = 'ticker1'  disabled = {!inputBox} value={t1 || ''} onChange = {(e) =>  {setT1(e.target.value.toUpperCase())}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500'  placeholder='Ticker 1'>
                        </input>
                    </div>

                    <div className = 'pt-3'>
                        <label>Ticker 2:</label>
                        <input name = 'ticker2'  disabled = {!inputBox} value={t2 || ''} onChange = {(e) =>  {setT2(e.target.value.toUpperCase())}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 '  placeholder='Ticker 2'>
                        </input>
                    </div>
                    <div className = 'text-red-600'>
                         {errorMessage}
                    </div>
                    </div>
                    <div className = 'ml-10'>
                        <Button primary onClick = {handleStart} >Compare</Button>
                        <Button primary onClick = {handleReset} >Reset</Button>
                    </div>
                    </div>
                <div className = 'flex justify-center'>
                {contentLower}
                </div>
                </div>
            </div>
    
}

export default HeadToHead;