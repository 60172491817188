import { NavLink} from "react-router-dom";
import { Link } from 'react-router-dom';
import {GoHome, GoCalendar } from 'react-icons/go';
//import {GiPieChart} from 'react-icons/gi';
import {VscTools} from 'react-icons/vsc';
import {HiOutlineCalculator, HiOutlineChartPie, HiOutlineGift} from 'react-icons/hi'
import { CgProfile} from 'react-icons/cg'
import { BsPersonWorkspace } from 'react-icons/bs'
import Modal from './Portfolios/ModalNew';
import SidebarLogout from "./SidebarLogout";
import { useState, useEffect } from "react";

function Sidebar() {
    //const location = useLocation();
    //const {pathname} = location;
    //const splitted = pathname.split("/");
    //console.log(splitted);
    //console.log(splitted[1]);
    //let navigate = useNavigate();

    //let id = 'AAPL';
    const [showModal, setShowModal] = useState(false);

    const links = [
        { Icon: <GoHome /> , label: 'Portfolios', path: '/Portfolios' },
        { Icon: <HiOutlineChartPie />, label: 'Diversification', path: '/Diversification'},
        { Icon: <GoCalendar />, label: 'Calendar', path: '/Calendar' },
        { Icon: <VscTools />, label: 'Tools', path: '/Tools' },
        { Icon: <HiOutlineCalculator />, label: 'Calculators', path: '/Calculators' },
        { Icon: <HiOutlineGift />, label: 'Pricing', path: '/Pricing' },
        //{ label: 'Pricing', path: '/Pricing' },
        { Icon: <BsPersonWorkspace />, label: 'Upcoming', path: '/Upcoming' },
        //{ Icon: <GoHome />, label: 'home', path: `/home` },
        { Icon: <CgProfile />, label: 'Profile', path: `/Profile` },
        //{ Icon: <GoHome />, label: 'logout', path: `/logout` },
        //{ label: 'Login', path: `/Login` },
        //{ label: 'CalendarTest', path: `/CalendarTest` },
        //{ label: 'Test', path: `/Test` },
    ]; 
    //const user = false;
    const [user, setUser] = useState({});
  useEffect (() => {
    const theUser = localStorage.getItem('user');
    //console.log('the user: ', theUser);
    if (theUser && !theUser.includes('undefined')){
      setUser(JSON.parse(theUser));
      //console.log(user.email);
    }
},[])
  console.log(user);
    const logout = () =>  {
        console.log('clicked on Logout...')
        localStorage.removeItem('user');
        window.location.reload();
    };
    const handleClose = () => {
        console.log('cllciked on close');
        console.log('llll');
        setShowModal(false);
      };
      const handleClick = () => {
        setShowModal(true);
      };
    const modalLogout = (<Modal Sluiten = {handleClose} >
        <div>
            <SidebarLogout werktSluiten = {handleClose} logout = {logout}/>
        </div>
        </Modal>
  );

    let renderedLinks = links.map((link) => {
        return (
       
       
        <NavLink 
        className = {({isActive}) =>
         isActive ? 'rounded-full bg-blue-800 text-white my-1 flex items-center' : 'flex items-center my-1 text-white rounded-full hover:text-white hover:bg-blue-800' }
        key={link.label} 
        to={link.path} >
            
                
                    <div className = 'mr-2 ml-2'>{link.Icon}</div>
               
                    {link.label}
                   
                       
        </NavLink>
        
           
        
        
        )
    });

    //if (!user) {
      //  renderedLinks = navigate('/Login')
    //}
    //console.log(user);
    //under Mydividendcompagnon: <GoHome />
    return (
        <div >
            
              <div className='sticky h-full top-0 overflow-y-hidden text-white font-xl  text-xl flex-col px-1 py-1 bg-blue-700'>
              <div className = 'fixed'>
                  <div className = 'mx-2 my-2 mb-6'>
                    <Link key={'xxx'} to={`/Portfolios`}>
                      <div className = 'text-sm font-bold'>MyDividendCompagnon</div>
                    </Link>
                  </div  >
                  {renderedLinks}
                  <div className = 'flex items-center my-2 text-white rounded-full hover:text-white hover:bg-blue-800'>
                      <div className = 'mr-2 ml-2 flex flex-row'>
                        <div><GoHome />   </div> 
                        <div className = '-mt-1 ml-2 flex flex-row'> <button onClick = {handleClick}  >Log out</button></div>
                        </div>    
                      {showModal &&  modalLogout}       
                  </div>
                  </div>
                  
            </div>
            

            
          



          </div>
          
            
        
    )
}

export default Sidebar