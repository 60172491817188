import { useFetchHistorySingleQuery } from "../../store";

function GetHistorySingleOne(pf_id) {
    console.log('in GetHistorySingleOne; ', pf_id);
        const { data, error, isLoading } = useFetchHistorySingleQuery(pf_id);
        if (error) {}
        if (isLoading) {}
        if(data){
            if(data.length === 0){
                return 0;
        }
            //let data2 = data;
            //data2= [...data];
            //console.log('last one: ',data2.at(-1));
            console.log('history data ...: ', data);
    
            return data;
        }
    
return data
}

export default GetHistorySingleOne