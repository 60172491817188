import { useGetPricePercentageQuery }  from'../../store';
import ApexTestDetails from '../Charts/ApexTestDetails';

function DividendPricePercentage ({ticker}) {
    //console.log('ticker...: ',ticker);
    const { data, error, isLoading } = useGetPricePercentageQuery(ticker);
    let partToDisplay;
    if (error){
        partToDisplay = 'An error occurred'
    }
    if (isLoading) {
        partToDisplay = 'Data is being gathered'
    }
    if (data) {
        //console.log(data)
        const legend = 'percentage';
        partToDisplay = <ApexTestDetails data = {data} y_legend = {legend} />
    }
    return <div>
        {partToDisplay}
    </div>
}

export default DividendPricePercentage