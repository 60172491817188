import React from 'react';
import Chart from "react-apexcharts";


const ApexTestDetails = ({data, y_legend}) => {
    //let data2 = data;
    //data2 = [...data];
    //console.log(data);
    //console.log(data);
    //console.log(data);
   // console.log(y_legend);
    //let y_legend = 'percentage'
    


    const yydata = data.map((column) => {
      if (y_legend === 'percentage'){
        return column.percentage}
      if (y_legend !== 'percentage') {
        return column.adjClose;}
    })
    //console.log(yydata);
    const xxdata = data.map((column) => {
      return column.date;
    })
    //console.log(data);
  

  //const yData = [30,40,35,50,49,60,70,91,125,88,72];
  const series = [ //data on the y-axis
    {
      name: "Daily value",
      data: yydata 
    }
  ];
  const options = { //data on the x-axis
    chart: { id: '...bar-chart...',
    toolbar: {
      show: false
    }},
    stroke: {
        curve: 'smooth',
        width: 2,
      },
    xaxis: {
      categories: xxdata
    }
  };

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
       
        
      />
    </div>
  )

};

export default ApexTestDetails;