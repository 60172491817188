import React from 'react';
import Chart from "react-apexcharts";
//import { useFetchDailyValueByPfIdQuery } from '../store';

const ApexTest = ({y_data, x_axis, name}) => {
  
    //console.log(y_data);
    //console.log(x_axis);
    //console.log(name);
    
    //const yydata = y_data.map((column) => {
    //  return column.value;
    //})
    //const xxdata = x_axis.map((column) => {
    //  return column.date
    //})

  const series = [ //data on the y-axis
    {
      name: name,
      data: y_data 
    }
  ];
  const options = { //data on the x-axis
    chart: { id: 'bar-chart',
    toolbar: {
      show: false
    }},
    //stroke: {
    //    curve: 'smooth',
    //    width: 2,
    //  },
    xaxis: {
      categories: x_axis
    }
  };

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
       />
    </div>
  )
};

export default ApexTest;