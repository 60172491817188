
import UpdateUpperValue from '../components/UpdateUpperValue'
import MiddlePart from '../components/MiddlePart';
import { useState} from 'react'; 
import DiversificationBigOne from '../components/Diversification/DiversificationBigOne';
import DiversificationIndividual from '../components/Diversification/DiversificationIndividual';
import Footer from '../components/footer';
import SidebarSmall from '../components/SidebarSmall';
function DiversificationPage({user}) {
    //const [test, setTest] = useState();
    const [activeButton, setActiveButton] = useState(123456789);
    //const name = {id:6}; 
    //const userr = {user}
    //console.log(userr);
  const name = {id: user.userid};
  //console.log('in diversification, userid = ', name)

    

    let contentMiddle;
    const {ContentMiddlePart, data_middle_loaded } = MiddlePart(name);
    //const {cd, isLoaded} = ShowDiversificationBigOneBis(name)
    //<ShowDiversificationBigOne name= {name}  />
    //useEffect(() => {<ShowDiversificationBigOneBis name = {name} />}, [])
    const [contentLower, setContentLower] = useState(
        <div>
            <div>
            <DiversificationBigOne name= {name}  />
            </div>
            
        </div>);

    
const handleOnclick = (value) => {
    //<ShowDiversificationIndividual ietske = {value} />
    //console.log('in handleclick - value: ', value)
    setActiveButton(value);
    setContentLower(
        <div>
            <div>
            <DiversificationIndividual ietske = {value} name = {name}/>
            </div>
            
        </div>
    );     
};

const handleOnclickBis = (value) => {
//<ShowDiversificationBigOne name= {name}  />
    setActiveButton(123456789);
    setContentLower(
        <div>
            <div>
            <DiversificationBigOne name= {name}  />
            </div>
            
        </div>
    );     
};
   
      
    

    if (!data_middle_loaded) {
        contentMiddle =  <div>Waiting for data</div>;
    }
    if (data_middle_loaded) {
        
        //console.log(ContentMiddlePart);
        contentMiddle = ContentMiddlePart.map((pf) => {
            return (
                
                <div key = {pf.id} >
                    <div className = 'group bg-blue-600 rounded-full'>
                    <button onClick = {() => handleOnclick(pf.id)}  
                    className={activeButton === pf.id ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-white hover:bg-blue-800'}>{pf.name}</button></div>
                </div>
                
            )
        });
    };


 

    return (
       
        <div className='bg-neutral-100 '>
             <div className = 'mb-5 md:invisible'>
                <SidebarSmall />
             </div>
            

                <div className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                <UpdateUpperValue />

                </div>
                <div className = 'shadow-md rounded-lg flex flex-col md:flex-row bg-blue-600 content-center md:space-x-5 py-5 px-2 mx-2 mb-5 md:px-5 my-2 md:mr-5'>
                    <div>
                        <button className = 'group bg-blue-600 rounded-full' onClick = {() => handleOnclickBis(1)}>
                            <div className = {activeButton === 123456789 ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}>
                            Overview</div></button>
                    </div>
                    <div> 
                <div className = 'grid grid-cols-2 md:flex md:content-center md:space-x-5'>
                    {contentMiddle}
                    
                </div>
            </div>
                    </div>
                    <div >
                        
                        {contentLower}
                    </div>
                <div className = 'mr-5'>
                    <Footer />
                </div>

            
        </div>
        
    )

    
}

export default DiversificationPage;