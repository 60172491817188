import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const stocks2Api = createApi({
    reducerPath: 'stocks2',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://nodejs.mydividendcompagnon.com"
    }),
    endpoints(builder) {
        return {

            getCompetitors: builder.query({
                query: (arg) => {
                    //const {ticker} = arg;
                    console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/competitors',
                        params: {
                            ticker: arg,                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),



             addTickerToPortfolio: builder.mutation({
                invalidatesTags:['stocksbypfid', 'stocksbyuserid', 'singlehistory'],
                query: (arg) => {
                    // ---const {pfId, ticker, amount, buy_price} = arg;
                    //console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/addticker',
                        params: {
                            pfId: arg.pfId,
                            ticker: arg.ticker,
                            amount: arg.amount,
                            buyPrice: arg.buy_price,
                            userId: arg.userId,
                            buyDate: arg.buyDate

                           
                            
                        },
                        method: 'POST',
                        
                    };
                }
              }),


              editEntry: builder.mutation({
                invalidatesTags:['stocksbypfid', 'stocksbyuserid', 'singlehistory'],
                query: (arg) => {
                    // ---const { id, amount, buy_price, date} = arg;
                    //console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/updateEntry',
                        params: {
                            id: arg.id,
                            amount: arg.amount,
                            buyPrice: arg.buy_price,
                            date: arg.date,

                           
                            
                        },
                        method: 'POST',
                        
                    };
                }
              }),

              deleteEntry: builder.mutation({
                invalidatesTags:['stocksbypfid', 'stocksbyuserid', 'singlehistory'],
                query: (arg) => {
                    // ---const { ticker, pfId} = arg;
                    //console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/deleteEntry',
                        params: {
                            pfId: arg.pfId,
                            ticker: arg.ticker,
                            

                           
                            
                        },
                        method: 'POST',
                        
                    };
                }
              }),


              fetchHistoryAll: builder.query({
                //providesTags: ['portfolios'],
                query: (arg) => {
                    //console.log(name);
                    //console.log('base_url in portfoliosapi: ', process.env.REACT_APP_BACKEND_SERVER)
                    return {
                        url: '/historyAll',
                        params: {
                            user_id: arg
                        },
                        method: 'GET',
                    };
                    
                },
            }),

            fetchHistorySingle: builder.query({
                providesTags: ['singlehistory'],
                query: (arg) => {
                    //console.log(name);
                    //console.log('base_url in portfoliosapi: ', process.env.REACT_APP_BACKEND_SERVER)
                    return {
                        url: '/historySingle',
                        params: {
                            pf_id: arg
                        },
                        method: 'GET',
                    };
                    
                },
            }),






            fetchStocksByUserId: builder.query({
                query: (iets) => {
                    return {
                        url: '/stocksbyuserid',
                        params: {
                            user_id: [iets.id]
                        },
                        method: 'GET', 
                    };                    
                },
            }),

            fetchStocksAndPrices: builder.query({
                providesTags: ['stocksbyuserid'],
                query: (iets) => {
                    return {
                        url: '/stocksandprices',
                        params: {
                            user_id: [iets]
                        },
                        method: 'GET', 
                    };                    
                },
            }), 
 

            fetchStocks2: builder.query({
                providesTags: ['stocksbypfid'],
                query: (iets) => {
                    return {
                        url: '/stocks',
                        params: {
                            pf_id: iets
                        },
                        method: 'GET',
                    };                    
                },
            }),
            
            
            fetchDividendsYear: builder.query({
                query: (iets) => {
                    return {
                        url: '/dividendsYear',
                        params: {
                            ticker: iets
                        },
                        method: 'GET', 
                    };                    
                },
            }),



        };
    },
});

export const { useDeleteEntryMutation, useEditEntryMutation, useFetchHistorySingleQuery ,useFetchHistoryAllQuery, useGetCompetitorsQuery ,useAddTickerToPortfolioMutation, useFetchStocks2Query, useFetchStocksByUserIdQuery, useFetchStocksAndPricesQuery, useFetchDividendsYearQuery} = stocks2Api;
export { stocks2Api };