import { useState } from 'react'; 
import Modal from './ModalNew';
import AddPortfolio from './AddPortfolio'

function AddPortfolioModal() {
        const [showModal, setShowModal] = useState(false);
        const handleClick = () => {
          setShowModal(true);
        };
      
        const handleClose = () => {
          console.log('cllciked on close');
          console.log('llll');
          setShowModal(false);
        };
      
        const actionBar = <div><button onClick = {handleClose} >X</button>
                                
                        </div>;
        const modal = (<Modal Sluiten = {handleClose} actionBar = {actionBar}>
              <div>
                  <AddPortfolio Sluiten = {handleClose} />
              </div>
              </Modal>
        ); 
           
          return (
          <div className = 'mt-2 md:mt-0 md:ml-5'>
              <button className = 'border rounded-full text-neutral-200 border-2 border-skyblue-500 px-2 md:ml-5' onClick = {handleClick} > + Add Portfolio</button>
              {showModal &&  modal}
            </div>
          );

}

export default AddPortfolioModal