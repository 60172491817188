import Accordion2 from '../components/Accordion2';
import SidebarSmall from '../components/SidebarSmall'

function UpcomingPage () {
    const items = [
        {
            id: 'design',
            title: 'Design',
            text: 'We are currently working on a new and upgraded design that will bring a fresh and modern look to our site. Our dedicated team is diligently crafting a design that combines aesthetics, functionality and user-friendliness to enhance the overall experience for our valued customers',
            text2: 'With this upcoming design, we aim to exceed expectations and set a new standard in terms of visual appeal and usability. Stay tuned for the unveiling of our improved and contempory design, as we are confident it will elevate your experience to new heights.'    
        },
        {
            id: 'etf',
            title: 'ETFs',
            text: 'We are thrilled to announce that in the near future we are expanding My Dividend Compagnion to incclude not only dividend paying stocks but also Exchange Trading Funds (ETF). This enhancement will provide our users with a comprehensive tool to monitor and manage their investment holdings more effectively',
            text2: 'By incorporating ETFs into out portfolio tracker, we are broadening the scope of investment opportunities available to our users, enabling to diversify their portfolios and tap into the potential of a wider range of asset classes.',
            text3: 'Our goal is to empower investors with the ability to track and analyze both dividend stocks and ETFs in a single and intuitive platform, providing a holistic view of their investments and helping them make informed decisions.'
        },
        {
            id: 'paid',
            title: '(paid) Subscription plans',
            text: 'For the moment, all parts of this site are publicly available. We plan to make some parts paid, only accessible via paid subscription plans'
        },       
        {
            id: 'stocks',
            title: 'Non dividend paying stocks',
            text: 'Plan is to add normal stocks too, so stocks that dont pay dividends. SO you will have a perfect overview of all your investments> Also a section Free Cash will be added.'
        },
        {
            id: 'crypto',
            title: 'Crypto Currency',
            text: 'This site is only for US dividend paying stocks but we are currently working on an upgrade so you can add non dividend paying stocks too'
        },
        {
            id: 'AI stocks',
            title: 'AI Stock picks',
            text: 'We will implement a tool to optimize your portfolio. This tool will be an AI driven portfolio optimizer'
        },
        {
            id: 'newsletter',
            title: 'Bi-weekly newsletter',
            text: 'The plan is to send out a bi-weekly newsletter for those who subscribe to it.'
        },
        {
            id: 'weekly',
            title: 'Weekly overview by mail',
            text: 'Every weekend, we will send a mail with the status of your portfolio, what dividends you will receive the next week, ...'
        },
        {
            id: 'dark',
            title: 'Dark mode',
            text: 'Currently there is only 1 display mode, but in the future we will implement a dark-mode option too'
        },
        {
            id: 'help',
            title: 'Help and explanation page',
            text: 'A help page will be added, explaining all terms used on this site.'
        }
        ,
        {
            id: 'currencies',
            title: 'More currencies',
            text: 'Only dollars for the moment, but there will be an option for other currencies too in the future'
        },
        {
            id: 'data',
            title: 'More data and charts',
            text: 'We are currently checking what other data and charts we can offer you.'
        },
    
    ]

    return (
        <div>
            <div className = 'md:invisible'>
        <SidebarSmall />
        
        </div>
        <div className = 'rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
            <div className = 'text-blue-600 font-bold'>
                What you may expect the upcoming weeks:
            </div>
             <div className = 'mt-2'>
                 <Accordion2  items = {items} />
             </div>
             </div>
        


</div>
    )
}

export default UpcomingPage