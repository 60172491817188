import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const stockDetailsApi = createApi( {
        reducerPath: 'stockDetails',
        baseQuery: fetchBaseQuery({
            baseUrl: 'https://financialmodelingprep.com/api/v3'
        }),
        endpoints(builder) {
            return {  
                getStockHistory: builder.query ({
                    query: (arg) => {  
                        //onsole.log('heeeeeeeeeeeeeeeey', arg);
                        const rrrrr = 'fe07eefb7e22e24d5733a2ff74b998bb'; 
                        const {ticker1, start, end} = arg; 
                          
                        let ticker = arg.ticker           
                        return {
                            url: '/historical-price-full/'+ arg[0]+'?from='+arg[1]+'&to='+arg[2], //+'/competition',
                            params: {//apikey : '?apikey=fe07eefb7e22e24d5733a2ff74b998bb'
    
                                
                               //y: 'kkkkkk',
                               apikey: rrrrr
                            },
                            method: 'GET'
                        };
                        
                    },
                }) ,    



                


            getStockDetails: builder.query ({
                query: (ticker) => {  
                    const rrrrr = 'fe07eefb7e22e24d5733a2ff74b998bb';                  
                    return {
                        url: '/profile/'+ ticker, //+'/competition',
                        params: {//apikey : '?apikey=fe07eefb7e22e24d5733a2ff74b998bb'

                            
                           //y: 'kkkkkk',
                           apikey: rrrrr
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,  
            
            getStockNews: builder.query ({
                query: (ticker) => {  
                    const rrrrr = 'fe07eefb7e22e24d5733a2ff74b998bb';                  
                    return {
                        url: '/stock_news?tickers='+ ticker +'&limit=50',
                        params: {//apikey : '?apikey=fe07eefb7e22e24d5733a2ff74b998bb'

                            
                           //y: 'kkkkkk',
                           apikey: rrrrr
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,

            getDividends: builder.query ({
                query: (ticker) => {  
                    const rrrrr = 'fe07eefb7e22e24d5733a2ff74b998bb';                  
                    return {
                        url: '/historical-price-full/stock_dividend/'+ticker,
                        params: {//apikey : '?apikey=fe07eefb7e22e24d5733a2ff74b998bb'

                            
                           //y: 'kkkkkk',
                           apikey: rrrrr
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,

            getStockAnalysis: builder.query ({
                query: (ticker) => {  
                    const rrrrr = 'fe07eefb7e22e24d5733a2ff74b998bb';  
                    //console.log('ticker in api:' ,ticker)                
                    return {
                        url: '/ratios-ttm/'+ ticker, //+'/competition',
                        params: {//apikey : '?apikey=fe07eefb7e22e24d5733a2ff74b998bb'

                            
                           //y: 'kkkkkk',
                           apikey: rrrrr
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,
                
            
        };
    },

});

export const { useGetStockScreenerQuery, useGetStockDetailsQuery, useGetStockAnalysisQuery, useGetStockHistoryQuery, useGetStockNewsQuery, useGetDividendsQuery } = stockDetailsApi;
export { stockDetailsApi }