import { loadStripe } from '@stripe/stripe-js';
import { Elements} from '@stripe/react-stripe-js';
//import CheckoutForm from './CheckoutForm';
import { useState, useEffect } from 'react'
import {Link, useLocation } from 'react-router-dom';
//import { BrowserRouter } from 'react-router-dom';
import StepTwo from './StepTwo';
import SidebarSmall from '../SidebarSmall'
const STRIPE_KEY = 'pk_test_51LONzUEmOCxfDzgdTlpuwqfiMSZZIonQT7CdjGUbdxUdFcpBOeGPt6jk7toHgntLe4ZzyXMLg1ujvTk2ve0uwXNw00CxxB0uqp';


const stripePromise = loadStripe(STRIPE_KEY);

function StepOne({user, fullname}) {
    const [clientSecret, setClientSecret] = useState('');
    const [display, setDisplay] = useState(false);
    const location = useLocation();
    const dataPreviousStep = location.state
   // const [continue, setContinue] = useState(false);
    console.log('in step one, user = ', user)
    console.log('in step one, fullname = ', dataPreviousStep);
    console.log('in step one, datapreviousstep.priceid = ', dataPreviousStep.priceId);
    const [email, setEmail] = useState(user.email)
    const [price_Id, setPrice_Id] = useState(dataPreviousStep.priceId)

    async function x() {
        const xxx =  await fetch('https://nodejs.mydividendcompagnon.com/user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        //paymentMethod: paymentMethod.id,
                        name: 'peter',
                        //email: 'peterjawel@gmail.com',
                        email: email,
                        priceId: price_Id
                        })
                
                }) 
                .then((res) => res.json());
                console.log('xxx ->: ', xxx);
                console.log('result ... secret', xxx.clientSecret)
                console.log('____')
                setClientSecret(xxx.clientSecret)
                setDisplay(true);
                console.log('from usestate: ',clientSecret)
                return xxx.clientSecret
        }
    //useEffect(() => {
    //x(); 
    //}, []);

    const appearance = {
        theme: 'stripe'
      }
      const options = {
        clientSecret,
        appearance
     }

     const handleContinue = (value) => {
        x();
     }


    return (
        <div>
            <div className = 'md:invisible'>
    <SidebarSmall />
    
    </div>
            <div className = 'mt-10 md:mt-0 grid grid-cols-1 md:flex md:flex-column content-center space-x-5'>
                <div className = 'md:w-1/2 rounded-lg bg-white shadow-md px-2 md:px-5 md:py-3 mb-5 my-2 md:mr-5 mx-3 md:pt-0'>
                <div> Namee: {user.firstName} {user.lastName}</div>
                <div> Email: {email}</div>
                <div>Plan: {dataPreviousStep.product}</div>
                <div>Price: {dataPreviousStep.price}</div>
                <div className = 'whitespace-pre'> {' '}</div>
                <div>
                    <button 
                    className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mb-1 hover:text-white hover:bg-blue-600'
                    onClick = {() => handleContinue()}>Continue</button>
                </div>
                </div>
                <div className = 'md:w-1/2 rounded-lg bg-white shadow-md px-2 md:px-5 md:py-3 mb-5 my-2 md:mr-5 mx-3 md:pt-0'>
                    {stripePromise && clientSecret && (
                        <Elements stripe = {stripePromise} options = {options}>
            
                         { display ? <StepTwo  /> : 'nog niks'}
                        </Elements>)
                    }
        </div>
      </div>
        </div>
        )
}

export default StepOne