import { useLoginQuery } from "../../store";

function ManualLogin({email, password}) {
    console.log('in Manual login')
    console.log('email, password :', email, password)
    const { data, error, isLoading  } = useLoginQuery({email: email, password: password});
    console.log('log in: ')
    //'console.log('results: ', data)
    if (isLoading) {console.log('is loading')}
    if (data) {console.log('data returned from nodejs: ',data)}
    if (data === 'wrong') { 
        return <div>Email/Password did not match - please try again</div>
        //  console.log('data.user: ', data.user);
        //localStorage.setItem('user', JSON.stringify(data?.user));
        //window.location.reload();  
        } 
    if (data && data !== 'wrong') {
        console.log('continue to login data: ',data);
        console.log('data.user: ', data.user);
        localStorage.setItem('user', JSON.stringify(data?.user));
        window.location.reload();  
    } 
    
}

export default ManualLogin