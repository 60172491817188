import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../slices/AuthSlice';

const usersApi = createApi({
    reducerPath: 'users',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://nodejs.mydividendcompagnon.com"
    }), 
    endpoints(builder) {
        return {
            signup: builder.mutation({
                query: (arg) => {
                    const {email, password} = arg;
                    console.log('arg: ', arg)
                    return {
                        url: '/signup2',
                        params: {
                            email: arg.email,
                            password: arg.password,                                                   
                        },
                        method: 'POST',
                        
                    };
                }
              }),

              login: builder.query({
                query: (arg) => {
                    const {email, password} = arg;
                    console.log('arg: ', arg)
                    return {
                        url: '/manuallogin',
                        params: {
                            email: arg.email,
                            password: arg.password,                                                   
                        },
                        method: 'GET',
                        
                    };
                }
              }),


           

          
        };
    },

});

export const { useSignupMutation, useLoginQuery } = usersApi;
export { usersApi };