
import CleanupArray from '../Portfolios/CleanupArray';
import CreateCalendar from './CreateCalendar';
import { useFetchStocks2Query } from '../../store';
//import CalendarMonthly from './CalendarMonthly';

function CalendarIndividual (ietske) {
       //console.log(ietske);

        //console.log(name.name.id);
        const { data, error, isLoading } = useFetchStocks2Query(ietske.ietske);
        //console.log(data)
        let content_down, calendarM;
        //let test = [];
        if (isLoading) {
            content_down = 'loading data ...';
            
        } else if (error) {
            content_down = 'error ...';
            
        } else if (data) {
            //console.log('nu ok');
            let newArrayOG = data;
            console.log(data);
            newArrayOG = [...data]
            const { cleanArray, total2} = CleanupArray(newArrayOG);
            //console.log(cleanArray);
            //test.push({title: 'KMI', allday: true,start: new Date('2023-03-12'),  end: new Date('2023-03-13') })
            
            //console.log(test);

            content_down = <CreateCalendar data = {cleanArray} value = {total2} user = {ietske.ietske} type = 'individual'/>
            //ca lendarM = <CalendarMonthly data = {test}/>
            
        }

        
            
    
        
    
    
    
    
    




    return (
        <div>
            <div>
                {content_down}
            </div>
            <div className='rounded-lg shadow-md px-5 py-3 mb-3 my-2 mr-5'>
                {calendarM}
                List (table of upcoming dividend)
            </div>
    </div>
    )
}
export default CalendarIndividual