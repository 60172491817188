
import { useFetchStocks2Query } from '../../store';
import CleanupArray from './CleanupArray';
import SortableTable from '../Table/SortableTable';
import { Link } from 'react-router-dom';
//import Modal from './Modal';
import EditDelete from './EditDelete';
//import { useState} from 'react';
import CalculatePercentages from './CalculatePercentages';
import GetName from '../../HelperFunctions/GetName';


function ListOfStocks(ietske) {
    //const [showModal, setShowModal] = useState(false);
    //const [modalAmount, setModalAmount] = useState(null);
    //const [ticker, setTicker] = useState(null)
    const { data, error, isLoading } = useFetchStocks2Query(ietske.ietske);

    let content_down, percentages;

    
    if (isLoading) {
        content_down = 'stop';
    } else if (error) {
        content_down = 'stop';
    } else if (data) {
        //console.log('nu ok?????', data);
        let newArrayOG = data;
        newArrayOG = [...data]
        const { cleanArray, total2 } = CleanupArray(newArrayOG);
        //const modalHandleClick = (val, ticker) => {
            //...<Modal>iets</Modal>
          //  console.log('clicked on modal');
          //  console.log('waarde', val);
          //  setTicker(ticker); 
          //  setModalAmount(val);
          //  setShowModal(true);
            
        //}
        //const toModal = [modalAmount, ticker]
        //modal = <Modal>{toModal}</Modal>
        //const fff = GetName();
        //console.log(fff);
        function im(iets) {
        const name = iets;
        const x = 'https://financialmodelingprep.com/image-stock/';
        //const x = 'https://eodhistoricaldata.com/img/logos/US/'
        let y = x.concat(name).concat('.png')
        return y}

        

        const config = [
            { label: 'Ticker', 
            render: (xx) => (
                
                <div >
                     <div className = 'h-10 flex ' >
                        <img alt = 'rrr'  src = {im(xx.ticker)}  className = "object-cover w-8 h-8 pt-2 mr-2" />
                        <div className = 'text-blue-500 font-semibold'>
                            <strong>
                                <Link key={'xxx'} to={`/Details/${xx.ticker}`}>
                                    {xx.ticker}
                                </Link>
                            </strong>
                            <div className = 'invisible md:visible text-gray-400'>
                                <GetName ticker = {xx.ticker} />
                                </div>
                            </div>
                        </div>
               </div>
            ),
            header: () => <th className = "sticky left-0">ticker</th>,
            sortValue: (xx) => xx.ticker},
            { label: 'Amount',
            render: (xx) => xx.amount},
            { label: 'Current',
            render: (xx) => parseFloat(xx.price/xx.amount).toFixed(2),
            sortValue: (xx) => xx.price/xx.amount},
            { label: 'Average',
            render: (xx) => parseFloat(xx.buy_price/xx.amount).toFixed(2),
            sortValue: (xx) => xx.buy_price/xx.amount},
            { label: '+/-',
            render: (xx) => parseFloat((xx.price-xx.buy_price)*1).toFixed(2),
            sortValue: (xx) => xx.price-xx.buy_price},
            { label: 'Payout',
            render: (xx) => parseFloat(xx.frequency * xx.payout * xx.amount).toFixed(2),
            sortValue: (xx) => xx.frequency * xx.payout * xx.amount },
            { label: 'YOC',
            render: (xx) => parseFloat(xx.frequency*xx.payout*xx.amount*100/xx.buy_price).toFixed(2) ,
            sortValue: (xx) => xx.frequency*xx.payout*xx.amount*100/xx.buy_price},
            { label: 'YOP',
            render: (xx) => parseFloat(xx.frequency*xx.payout*xx.amount*100/xx.price).toFixed(2) ,
            sortValue: (xx) => xx.frequency*xx.payout*xx.amount*100/xx.price},
            { label :'',
           // render: (xx) => <button onClick = {() => modalHandleClick(xx.amount, xx.ticker)}>delete</button>
            render: (xx) => <EditDelete pf_id = {ietske} ticker = {xx.ticker} totalAmount = {xx.amount} />           
        }
            ]
        //console.log(cleanArray);
        const keyFn = (iets) => {
            return iets.ticker
        }
        let newArray = cleanArray;
        newArray = [...cleanArray]
            
        content_down = <SortableTable content2 = {newArray} config ={config} keyFn = {keyFn} value = {total2}  />;
        percentages = <CalculatePercentages data = {newArray} total = {total2} pf_id = {ietske.ietske} overview = 'no'/>
        
      }
      //<div >
      //{showModal && modal}
  //</div>

    return (<div >
                <div className = 'shadow-md rounded-lg bg-white content-center py-2  mb-3 md:px-5 my-2 md:mr-5 md:mx-0 mx-2 px-2'>
                    {percentages}
                </div>
               
                <div className = 'overflow-x-scroll overflow-y-hidden shadow-md rounded-lg bg-white content-center py-2  mb-3 md:px-5 my-2 md:mr-5 mx-2 md:mx-0 px-2'>
                    {content_down}
                </div>
            </div>) 
};

export default ListOfStocks;


