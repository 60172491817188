
import { useFetchStocksAndPricesQuery } from '../../store';
import CleanupArray from './CleanupArray';
import { useState, useEffect } from 'react';
import SortableTable from '../Table/SortableTable';
import { Link } from 'react-router-dom';
import CalculatePercentages from './CalculatePercentages';
import GetName from '../../HelperFunctions/GetName';
//import History from './History';

function ListBigOne() {
    const [user, setUser] = useState({});

    useEffect (() => {
      const theUser = localStorage.getItem('user');
      if (theUser && !theUser.includes('undefined')){
        setUser(JSON.parse(theUser));
 
      }
  },[])
 
    const { data, error, isLoading } = useFetchStocksAndPricesQuery(user.userid);
 
    let content_down;
    let percentages;
    //let historyDown;
    if (isLoading) {
        content_down = 'loading data ...';
        
    } else if (error) {
        content_down = 'error ...';
        
    } else if (data) {
        //console.log('nu ok');
        let newArrayOG = data;
        //console.log('dataaa .....',data);
        if (data.length === 0){
            //console.log('empty array');
        }
        newArrayOG = [...data]
        const { cleanArray, total2} = CleanupArray(newArrayOG);

        function im(iets) {
            const name = iets;
            const x = 'https://financialmodelingprep.com/image-stock/';
            let y = x.concat(name).concat('.png')
            return y}
       
        const config = [
            { label: 'Ticker', 
            render: (xx) => (
                
                <div className = ' sticky left-0'>
                     <div className = 'h-10 flex ' >
                        <img alt = 'rrr'  src = {im(xx.ticker)}  className = "object-cover w-8 h-8 pt-2 mr-2" />
                        <div className = 'mt-2 md:mt-0 text-blue-500 font-semibold '>
                            <strong>
                                <Link key={'xxx'} to={`/Details/${xx.ticker}`}>
                                    {xx.ticker}
                                </Link>
                            </strong>
                            <div className = 'invisible md:visible text-gray-400'>
                                <GetName ticker = {xx.ticker} />
                                </div>
                            </div>
                        </div>
               </div>),
            header: () => <th className = "sticky left-0">ticker</th>,
            sortValue: (xx) => xx.ticker},
            { label: 'Amount',
            render: (xx) => xx.amount},
            { label: 'Current',
            render: (xx) => parseFloat(xx.price/xx.amount).toFixed(2),
            sortValue: (xx) => xx.price/xx.amount},
            { label: 'Average',
            render: (xx) => parseFloat(xx.buy_price/xx.amount).toFixed(2),
            sortValue: (xx) => xx.buy_price/xx.amount},
            { label: '+/-',
            render: (xx) => parseFloat((xx.price-xx.buy_price)*1).toFixed(2),
            sortValue: (xx) => xx.price-xx.buy_price},
            { label: 'Payout',
            render: (xx) => parseFloat(xx.frequency * xx.payout * xx.amount).toFixed(2),
            sortValue: (xx) => xx.frequency * xx.payout * xx.amount},
            { label: 'YOC',
            render: (xx) => parseFloat(xx.frequency*xx.payout*xx.amount*100/xx.buy_price).toFixed(2) ,
            sortValue: (xx) => xx.frequency*xx.payout*xx.amount*100/xx.price},
            { label: 'YOP',
            render: (xx) => parseFloat(xx.frequency*xx.payout*xx.amount*100/xx.price).toFixed(2) ,
            sortValue: (xx) => xx.frequency*xx.payout*xx.amount*100/xx.price},
            
            
            ]
        //console.log(cleanArray);
        const keyFn = (iets) => {
            return iets.ticker
        }
        let newArray = cleanArray;
        newArray = [...cleanArray]
        
        if (data.length !== 0){
            content_down = <SortableTable content2 = {newArray} config ={config} keyFn = {keyFn} value = {total2} />;
            percentages = <CalculatePercentages data = {newArray} total = {total2} overview = 'yes' />
            //historyDown = 'for later';
        }
        if (data.length === 0) {
            percentages = 'Welcome to MyDividendCompagnon';
            content_down = "Please click on 'Add portfolio' to create your first portfolio" ;
            //historyDown = '';
        }
      }
 
    return (<div>
                <div className = ' bg-white shadow-md rounded-lg content-center py-2  mb-3 mx-2 md:mx-0 md:px-5 my-2 md:mr-5 px-2'>
                    {percentages}
                </div>
                <div className = 'overflow-x-scroll  overflow-y-hidden bg-white md:overflow-hidden shadow-md rounded-lg content-center py-2  mb-3 md:px-5 my-2 md:mr-5 mx-2 md:mx-0 px-2'>
                    {content_down}
                </div>
                <div>
                
                </div>
            </div> )
};

export default ListBigOne;


