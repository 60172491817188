import { useState } from 'react';

const  ToggleButton = ({handleCheckBoxChance, isChecked}) => {
    //console.log('in toggle button, isChecked = ', isChecked);
    //const [isChecked, setIsChecked] = useState(false);

    //const handleCheckBoxChance = () => {
    //    console.log('clicked on it')
    //    setIsChecked(!isChecked)
    //    setMonthly(!isChecked)
    //}

    return (
        <>
        <label className = 'flex cursor-pointer select-none items-center'>
            <div className = 'relative'>
                <input
                    type = 'checkbox'
                    checked = {isChecked}
                    onChange = {handleCheckBoxChance}
                    className = 'sr-only'
                    />
                <div className = {`box block h-6 w-10 rounded-full ${isChecked ? 'bg-blue-600' : 'bg-blue-200'}`} ></div>
                <div className = {`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${isChecked ? 'translate-x-full' : ''}`}></div>        
            </div>
        </label>
        </>
    )

}

export default ToggleButton