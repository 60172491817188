import { useGetStockDetailsQuery } from '../../store';

function GetDetails(id) {
    //console.log('in get stock details, id is: ', id);
    const { data, error, isLoading } = useGetStockDetailsQuery(id.id);
    
    if(data){
       let newArrayOG = data;
       newArrayOG = [...data]
       return newArrayOG[0];
    }
    
}
  
export default GetDetails;