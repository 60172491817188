import { useState, useEffect } from 'react';
import StockScreener from '../components/Tools/StockScreener';
import Aristocrats from '../components/Tools/Aristocrats';
import Kings from '../components/Tools/Kings';
import HeadToHead from '../components/Tools/HeadToHead';
import Monthly from '../components/Tools/Monthly';
import {GiSprint, GiCrossedSwords} from 'react-icons/gi';
import {FaChessKing} from 'react-icons/fa';
import SidebarSmall from '../components/SidebarSmall';

function ToolsPage() {
    const [activeButton, setActiveButton] = useState(null);
    const [contentLower, setContentLower] = useState(null);
    const [message, setMessage] = useState('Please select from above')

    const handleScreener = () => {
        console.log('in handle screener')
        setActiveButton('Screener');
        setMessage('Stock Screener')
        setContentLower(<StockScreener />)
    }
    const handleAristocrats = () => {
        console.log('in handle screener')
        setActiveButton('Aristocrats');
        setMessage('Dividend Aristrocrats')
        setMessage(<div className='flex'><div className = 'mr-2  mt-1'><FaChessKing /></div><div>Dividend Aristocrats</div></div>)
        setContentLower(<Aristocrats />)
    }
    const handleKings = () => {
        console.log('in handle screener')
        setActiveButton('Kings');
        setMessage('Dividend Kings')
        setContentLower(<Kings />)
    }
    const handleHead = () => {
        console.log('in handle screener')
        setActiveButton('Head');
        setMessage('Head to Head')
        setContentLower(<HeadToHead />)
    }
    const handleClick = (value) => {
        console.log('in handle click - value = ', value)
        setActiveButton(value);
        setMessage('Monthly Dividends')
        setContentLower(<Monthly />)
    }

    useEffect(() => {
        setContentLower(null)
    }, [])
        
    
    return (
        <div className='bg-neutral-100 md:mx-2'>
             <div className = 'z-50 mb-5 md:invisible'>
        <SidebarSmall />
        </div>
          
            <div className = 'grid grid-cols-2 md:flex justify-between py-3 my-2 mx-3'>
                
                <div className = 'flex bg-white border-solid border-2 border-blue-600 rounded-full mt-1 text-sm md:text-lg items-center'>
                    <button onClick = {() => handleScreener()} 
                        className = {activeButton === 'Screener' ? 'rounded-full bg-blue-600 text-white flex items-center px-2' : 'rounded-full text-blue-600 hover:text-white hover:bg-blue-600 flex items-center px-2'}>
                       
                            <div className = 'mr-2  mt-1'><GiSprint /></div>
                             <div>Stock Screener</div>
                     </button>
                </div>
            
                <div className = 'flex bg-white border-solid border-2 border-blue-600 rounded-full mt-1 text-sm md:text-lg items-center'>
                    <button onClick = {() => handleHead()} 
                            className = {activeButton === 'Head' ? 'rounded-full bg-blue-600 text-white flex items-center px-2' : 'rounded-full text-blue-600 hover:text-white hover:bg-blue-600 flex items-center px-2'}>
                                <div className = 'mr-2  mt-1'><GiCrossedSwords /></div>
                                <div>Head to head</div>
                    </button>
                </div>  

                <div className = 'flex bg-white border-solid border-2 border-blue-600 rounded-full mt-1 text-sm md:text-lg items-center'>
                    <button onClick = {() => handleKings()} 
                            className = {activeButton === 'Kings' ? 'rounded-full bg-blue-600 text-white flex items-center px-2' : 'rounded-full text-blue-600 hover:text-white hover:bg-blue-600 flex items-center px-2'}>
                                <div className = 'mr-2 '><FaChessKing /></div>
                                <div>Dividend Kings</div>
                    </button>
                </div> 
          
            
            
            <div className = 'flex bg-white border-solid border-2 border-blue-600 rounded-full mt-1 text-sm md:text-lg items-center'>
                <button onClick = {() => handleAristocrats()} 
                        className = {activeButton === 'Aristocrats' ? 'rounded-full bg-blue-600 text-white flex items-center px-2' : 'rounded-full text-blue-600 hover:text-white hover:bg-blue-600 flex items-center px-2'}>
                            <div className = 'mr-2 '><FaChessKing /></div>
                            <div>Dividend Aristocrats</div>
                </button>
            </div>            

            <div className = 'flex bg-white border-solid border-2 border-blue-600 rounded-full mt-1 text-sm md:text-lg items-center'>
                <button onClick = {() => handleClick('Monthly')} 
                        className = {activeButton === 'Monthly' ? 'rounded-full bg-blue-600 text-white flex items-center px-2' : 'rounded-full text-blue-600 hover:text-white hover:bg-blue-600 flex items-center px-2'}>
                            <div className = 'mr-2 '><FaChessKing /></div>
                            <div>Monthly payers</div>
                </button>
            </div> 

            <div className = 'flex bg-white border-solid border-2 border-blue-600 rounded-full mt-1 text-sm md:text-lg items-center'>
                <button onClick = {() => handleKings()} 
                        className = {activeButton === 'Most' ? 'flex-1 rounded-full bg-blue-600 text-white flex items-center px-2' : 'rounded-full text-blue-600 hover:text-white hover:bg-blue-600 flex items-center px-2'}>
                            <div className = 'mr-2 '><FaChessKing /></div>
                            <div>Most popular</div>
                </button>
            </div> 
            
              
            </div>
            <div className = 'items-center justify-center shadow-md rounded-lg flex flex-row bg-blue-600 content-center space-x-5 py-5  mb-5 px-5 my-2 md:mr-5'>
                <div className = 'font-lg text-lg text-white text-center'>{message}</div>
            </div>
            <div >
            {contentLower}
            </div>
            
        </div>
    )
}

export default ToolsPage