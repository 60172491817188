import React from 'react';
//import Chart from "react-apexcharts";
// { useSelector } from 'react-redux';
import { useFetchDailyValueByUserIdQuery } from '../../store';
//import { useDispatch } from 'react-redux';
//import { useEffect, useState } from 'react';
//import { editValue, editYesterday } from '../../store';
//import UpdateYesterdayValue from '../Portfolios/UpdateYesterdayValue';
import ApexPortfolio from '../Charts/ApexPortfolio';
//import GetYesterdayValue from '../Diversification/GetYesterdayValue';

function DataChartPortfolio(user_id) {
  //console.log(user_id.user_id)
  //const dispatch = useDispatch();
  //const [yesterday, setYesterday] = useState(0)
  const { data, error, isLoading } = useFetchDailyValueByUserIdQuery(user_id.user_id);
  //const todaysValue  = useSelector((state) =>{
    //console.log(state)
    //return state.value
//})

let  toShow;


//console.log('todays value: ', todaysValue);
//const value_of_today = parseFloat(todaysValue).toFixed(2)

//console.log('todays value: ', todaysValue);
if (isLoading) {}
if (error) {}
  
  if (data) {
    //console.log('in Apex2 -> data: ', data)
    let data2 = data;
    data2= [...data];
    //console.log('last one: ',data2.at(-1));
    let value;
    let dataOk = data2.reduce((colBuy,y) => {
        const date = y.date;    
        value = +y.value;
        colBuy[date] = (colBuy[date] || 0) + value;
        return colBuy;
    },{}); 
   
    const arrayOk = Object.entries(dataOk).reduce((acc, curr) => {
        const [key, val] = curr;
        acc.push({
            date: key,
            value: val
        });
        return acc;
    },[]);
    //below is the last date !!!!!!!!!!!
    //console.log(arrayOk.at(-1));
    

    //const x = arrayOk.at(-1)
    //xx = x.value
    //setYesterday(x.value)
    //const action2 = editYesterday(xx);
    //dispatch(action2);
    //t = <UpdateYesterdayValue value = {xx} />
    //console.log(t) 
    

   
  
  toShow = <ApexPortfolio data = {arrayOk} /> 
  return (
    <div>
      {toShow}
    </div>
  )
}
};

export default DataChartPortfolio;