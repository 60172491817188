import { useEffect } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../Hooks/useFetch";
import { useState } from "react";
import  ManualLogin  from "./ManualLogin";

const Login = () =>  {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [test, setTest] = useState('')

    //const { manual, results } = useFetch('http://192.168.0.224:8800/login2');
    const { handleGoogle, loading, error } = useFetch("https://nodejs.mydividendcompagnon.com/login");
    //const { handleGoogle, loading, error } = useFetch('http://192.168.0.224:8800/login');
    //const { login, results } = useFetch('https://localhost:8800/login2',{email: 'ik@bla.com'});
    const handleSubmit = () => {
       // const { login, results } = useFetch('https://localhost:8800/login2', {email: 'ik@bla.com'});
        //const t = < ManualLogin />;
        //console.log('t: ',t)
        //console.log(results);
        //setAut  henticated(true);
        //console.log(results.isSuccess);
        //console.log(authenticated,'is okay');
        //<Navigate to='/Portfolios' />
        //Navigate('/Portfolios')
        
    }

    useEffect(() => {
        /* global google */
        if (window.google) {
            google.accounts.id.initialize({
                client_id: '79888983542-30b3s128pjmo57eekkv5lqr37o2khfqm.apps.googleusercontent.com',
                callback: handleGoogle
            });
            google.accounts.id.renderButton(document.getElementById('loginDiv'), {
                        theme: 'outline',
                        text: 'continue with',
                        shape: 'pill',
                        //size: 'small'
            })
        }
    }, [handleGoogle]);

    const handleManual = () => {
        //const t = ManualLogin('iii')//<ManualLogin x = 'qqq' />;
        //console.log('t: ',t)
        setTest(<ManualLogin email = {email} password = {password} />)
    }


    return (
        <div className = 'bg-purple-200 flex items-center justify-center content-center h-screen'> 
            <div className = 'rounded-md bg-gray-500 py-6 px-6 text-white'>
                <div className = 'text-lg font-bold items-center justify-center pb-4 ml-5 x'>
                    Please log in to your account</div>
                <div>
                    <div>Email</div>
                    <input name = 'email'  placeholder='WarrenBuffet@gmail.con'
                        onChange = {(e) =>  {setEmail(e.target.value)}}
                        className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-72 text-blue-800'></input>
                </div>
                <div>
                    <div>Password</div>
                    <input name = 'password' type = 'password'
                        onChange = {(e) =>  {setPassword(e.target.value)}}
                        className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-72 text-blue-800'></input>
                </div>
                <div className = 'text-sm text-red-200'>{test}</div>
                <div className = 'flex w-full'>
                    <button onClick = {handleManual} className = 'w-full mb-5  mt-5 px-2 border  bg-blue-400 rounded-md'>login</button>
                </div>
                
                <main style = {{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                }}>
                    
                    {error && <p className = 'text-red-500 pb-2'>{error}</p> }
                    {loading ? <div>...Loading</div> : <div id = 'loginDiv'></div>}
                </main>
                <div className = 'pt-4 text-sm text-blue-100'>
                you don't have an account yet? 
                <Link to = '/signup' className = 'text-blue-500'>Register here</Link>
                </div>
            </div>
         
        </div>
        )
}

export default Login