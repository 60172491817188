//import className from 'classnames';
import { useState } from 'react';
import DropDown from '../components/DropDown';
import DripCalculatorGraph from '../components/Charts/DripCalculatorGraph';
import SidebarSmall from '../components/SidebarSmall'

function CalculatorsPage() {
    //const 
    let lowerPart ='';
    const [amount, setAmount] = useState(0);
    const [price, setPrice] = useState(0);
    const [divYield,setDivYield] = useState(0);
    const [frequency, setFrequency] = useState(0);
    const [contribution, setContribution] = useState(0);
    const [divIncrease, setDivIncrease] = useState(0);
    const [priceIncrease, setPriceIncrease] = useState(0);
    const [holding, setHolding] = useState(0);
    const [hidden, setHidden] = useState(false)
    //const [drip, setDrip] = useState();
    //const [drip, setDrip] = useState('');
    const [x,setX] = useState(0);
    const [dripSelection, setDripSelection] = useState(0);
    const [drip, setDrip] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const options = [
        { label: 'With DRIP', value: 1},
        { label: 'Without DRIP', value: 0}
    ]

    const handleSelect = (option) => {
        setDrip(option.value);
    };


    const tekst = 'blablabla'
    
    
    const onHandleSubmit = (event) => {
        console.log('in onHandleSubmit')
        event.preventDefault();
        const formData = new FormData(event.target);
        const payload = Object.fromEntries(formData)
        console.log(payload); 
     }

     const handleReset = (event) => {
        //console.log(drip);
            event.preventDefault(); 
            setAmount(0);
            setContribution(0);
            setDivIncrease(0);
            setDivYield(0);
            setFrequency(0);
            setHolding(0);
            setPrice(0);
            setPriceIncrease(0);
            setDrip(0);
            setDripSelection(0);
            setDisabled(disabled);
            setHidden(false);
        };
 
    const handleOnclick2 = (event) => {
        //console.log(drip);
            event.preventDefault(); 
            setDisabled(!disabled);
        };
    
    const handleHide = (event) => {
        event.preventDefault(); 
        setHidden(!hidden)
    }
    
    if (!disabled) {
            lowerPart = <div>Result will be shown here ...</div>
        }
    if (disabled) {
        const calc = [{amount, price, divYield, frequency, contribution, divIncrease, priceIncrease, holding, drip}
        ]
        console.log('calc data: ', calc);
        lowerPart = <DripCalculatorGraph  data = {calc} />
        
    }

    //<DropDown options = {options} selection = { drip } onSelect = {handleSelect} />

    //const lowerPart = 
    
    return (
    <div>
            <div className = 'md:invisible'>
        <SidebarSmall />
        </div>
               <div className = 'grid grid-cols-1 md:flex rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                <div>
                    <table>
                    <thead>
                    <tr>
                        <td>Amount of shares</td>
                        <td>  </td>
                        <td> Price of Share</td>
                        <td>  </td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><input name = 'am' disabled = {disabled} value={amount || ''} onChange = {(e) =>  {setAmount(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32' type='number' placeholder='0'>
                </input></td>
                        <td>X {' '}</td>
                        <td>
                <input name = 'pri' disabled = {disabled} value={price || ''} onChange = {(e) =>  {setPrice(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32' type='number' placeholder='0'>
                </input></td>
                <td>= </td> 
                <td>{amount * price}</td>
                </tr>
                
                <tr>
                <td>Exp. Dividend Yield</td>
                <td></td>
                <td>Payout frequency</td>
                <td></td>
                <td>Monthtly contribution</td>
                </tr>
                <tr>
                    <td>
                        <input disabled = {disabled} value = {divYield || ''} type = 'number' placeholder = '0' onChange = {(e) =>  {setDivYield(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32'></input>
                    </td>
                    <td></td>
                    <td>
                        <input disabled = {disabled} value = {frequency || ''} type = 'number' placeholder = '0' onChange = {(e) =>  {setFrequency(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32'></input>
                    </td>
                    <td></td> 
                    <td>
                    <input disabled = {disabled} value = {contribution || ''} type = 'number' placeholder = '0' onChange = {(e) =>  {setContribution(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32'></input>
            
                    </td>
                </tr>
                  <tr>
                    <td>Exp. dividend increase
                        </td>
                    <td></td>
                    <td>Exp. price increase</td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                        <td>
            <input disabled = {disabled} value = {divIncrease || ''} type = 'number' placeholder = '0' onChange = {(e) =>  {setDivIncrease(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32'></input>
                    </td>
                    <td></td>
                    <td>
            <input disabled = {disabled} value = {priceIncrease || ''} type = 'number' placeholder = '0' onChange = {(e) =>  {setPriceIncrease(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32'></input>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
           
            <tr>
                <td>Holding Period</td>
                <td></td>
                <td>Drip</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>
                    <input disabled = {disabled} value = {holding || ''} type = 'number' placeholder = '0' onChange = {(e) =>  {setHolding(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32'></input>
                </td>
                <td></td>
                <td >
                <DropDown options = {options} selection = { drip }
                 onSelect = {handleSelect} 
                 className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500 w-32' />
        </td><td></td><td></td>
                 </tr>   
                 </tbody>
                    </table>

                    <div className = 'pt-5'>
        
                
        <button type = 'submit'  onClick = {handleOnclick2}
         className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600'>Calculate</button>
         <div >
         <button type = 'submit'  onClick = {handleReset}
         className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-2 mb-3 hover:text-white hover:bg-blue-600'>Reset</button>
        </div>
        </div>
                    </div>
            <div className = 'mx-10 md:w-1/3'>
                <button   onClick = {handleHide}> Show/Hide Explanation</button><br />
            
                { hidden ? 'Exp. div yield (Expected DividendYield):': ''}<br />
                { hidden ? '- the dividend yield you expect ' : ''}<br />
                { hidden ? 'Payout frequency:': ''}<br />
                { hidden ? '- how many times a year a dividend is paid': ''}<br />
                { hidden ? '- 1: dividend is paid once a year': ''}<br />
                { hidden ? '- 2: dividend is paid twice a year': ''}<br />
                { hidden ? '- 4: quarterly paid dividend': ''}<br />
                { hidden ? '- 12: monthly paid dividend': ''}<br />
                { hidden ? 'Exp. dividend increase (Expected dividend increase):': ''}<br />
                { hidden ? '- the percentage you think the dividend will increase next next year': ''}<br />
                { hidden ? 'Exp. price increase:': ''}<br />
                { hidden ? '- the percentage you expect the price of the stock to increase in a year': ''}<br />
                { hidden ? ' Holding period:': ''}<br />
                { hidden ? '- how many years you want to calculate': ''}<br />
                { hidden ? 'Drip (Dividend Reinvestment Plan):': ''}<br />
                { hidden ? "- select 'With DRIP' if you want to reinvest the received dividends": ''} <br />
                </div> 
        
        
        </div>

            
    
        
      
    <div>

    {lowerPart}
    </div>
    
    </div>
    )
}

export default CalculatorsPage