import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const dailyValueApi = createApi ({
    reducerPath: 'dailyValue',
    baseQuery: fetchBaseQuery ({
        baseUrl: "https://nodejs.mydividendcompagnon.com"
        }),
        endpoints(builder) {
            return {
                fetchDailyValueByUserId: builder.query({
                    query: (pf) => {
                        return {
                            url: '/dailyvalueuserid',
                            params: {
                                user_id: pf
                            },
                            method: 'GET'
                        };
                    },
                }),


                fetchSectors: builder.query({
                    query: (arg) => {
                        //const {sector, pf_id} = arg;
                        const { user_id } = arg
                        return {
                            url: '/sectors',
                            params: 
                                //{sector, pf_id}
                                {user_id}
                            ,
                            method: 'GET'
                        };
                    },
                }),

                fetchSectorsByPf: builder.query({
                    query: (arg) => {
                        //const {sector, pf_id} = arg;
                        const { pf } = arg
                        return {
                            url: '/sectorsbypfid',
                            params: 
                                //{sector, pf_id}
                                {pf}
                            ,
                            method: 'GET'
                        };
                    },
                }),


                fetchKingsAristocrats: builder.query({
                    query: (arg) => {
                        const { kind } = arg
                        console.log(arg);
                        return {
                            url: '/kingsaristocrats',
                            params: 
                                //{sector, pf_id}
                                {kind: arg}
                            ,
                            method: 'GET'
                        };
                    },
                }),

                fetchUpcomingWeek: builder.query({
                    query: (arg) => {
                        //const { kind } = arg
                        console.log('here we are in dailyvalueapi');
                        return {
                            url: '/UpcomingWeek',
                            params: {
                            kind: arg[0],
                            from: arg[1],
                            to: arg[2],
                            },
                            method: 'GET'
                        };
                    },
                }),

                fetchDailyValueByPfId: builder.query({
                    query: (pf) => {
                        return {
                            url: '/dailyvaluepfid',
                            params: {
                                pf_id: pf
                            },
                            method: 'GET'
                        };
                    },
                }),
            };
        }
});

export const { useFetchUpcomingWeekQuery, useFetchKingsAristocratsQuery, useFetchDailyValueByPfIdQuery, useFetchDailyValueByUserIdQuery, useFetchSectorsQuery, useFetchSectorsByPfQuery } = dailyValueApi;
export { dailyValueApi };
