function Display () {
    return (
        <div className = 'mt-5 flex flex-column rounded-lg bg-white shadow-md px-5 py-3'>
            <div className = 'basis-1/3'>
                <div >
                    <div className= 'text-blue-600 font-bold'>
                        Display
                    </div>
                    <div>Change the way items are displayed</div>
                </div>
            </div>
            <div className = 'basis-2/3 border-neutral-600 border-l-1'>
                <div >
                    Dark mode
                </div>
                <div >
                    Default currency
                </div>
                
            </div>
        </div>
    )
}

export default Display