//import GetSectorInfo from '../helperFunctions/GetSectorInfo';
//import axios from 'axios';
import { useState} from 'react'; 
import GetHistorySingleOne from "./GetHistorySingleOne";
import { useEditEntryMutation } from '../../store';
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';

 
 
function EditInModal({portfolio, ticker, werktSluiten}) {
        console.log('portfolio: ', portfolio.ietske)
        console.log('in EditInModal ... ticker: ', ticker)
        const ListOfTickers = GetHistorySingleOne(portfolio.ietske);

        const [updateEntry, results] = useEditEntryMutation();
        const [activeEntry, setActiveEntry] = useState();
        const [amount, setAmount] = useState();
        const [buyPrice, setBuyPrice] = useState();
        const [date, setDate] = useState();
        const [id, setId] = useState();
        const toAdd = {id: id, amount: amount, buy_price: buyPrice, date: date}
  
        const handleEdit = (value, second, price, date) => {
                setActiveEntry(value);
                setId(value);
                setAmount(second);
                setBuyPrice(price);
                setDate(date);
                console.log(value);
                console.log('second...: ', second);
                
        }
        const handleEditToMysql = () => {
                updateEntry(toAdd);
                //<werktSluiten />
        }
     
        let renderedRows = '';
        //className = {activeEntry === rowData.id ? 'bg-blue-800' : 'bg-yellow-500'}
        if (ListOfTickers) {
                renderedRows = ListOfTickers.filter(x => x.ticker === ticker).map((rowData) => {
                return (<><tr key = {rowData.id}>
                        <td>
                        <button 
                        className = 'mr-5'
                        onClick={() => handleEdit(rowData.id, rowData.amount, rowData.buy_price, rowData.date)}> 
                        {activeEntry === rowData.id ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
                        </button>
                        
                         </td>
                                <td>
                         
                                {rowData.amount}
                                </td>
                                <td>
                                {rowData.buy_price}
                                </td>
                                <td>{rowData.date}
                                </td>
                                
                                
                        </tr>
                        {activeEntry ===rowData.id ? <tr>
                                <td></td>
                        <td>
                                <input placeholder={rowData.amount}
                                onChange = {(e) =>  {setAmount(e.target.value)}}
                                className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-20 text-blue-800'>
                                </input>
                        </td>
                        <td>
                                <input placeholder={rowData.buy_price}
                                onChange = {(e) =>  {setBuyPrice(e.target.value)}}
                                className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-20 text-blue-800'>
                                </input>
                        </td>
                        <td>
                                <input placeholder={rowData.date}
                                onChange = {(e) =>  {setDate(e.target.value)}}
                                className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-24 text-blue-800'>
                                </input>
                        </td>
                        <td><button 
                        className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600'
                        onClick= {handleEditToMysql} >Update</button></td>
                        
                </tr> : ''}
                </>
                        
                        
                )
        });
        }
      

        return (
            <div className = '-mt-3 '>
             <div>Buy/Sell history for {ticker}</div>
             
             <table>
                <thead>
                <tr>
                        <td></td>
                        <td>
                                Amount &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;
                                
                        </td>
                        <td>
                                Price &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        </td>
                        <td>
                                Date
                        </td>
                        <td></td>
                </tr>
                </thead>
                <tbody>
                        {renderedRows}
                </tbody>
                
             </table>
             <div className = 'mt-5'>
             <button onClick= {werktSluiten} 
             className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600'>
                Close</button>
                </div>
             <div className = 'mt-5 text-sm text-blue-600'>
                Note: updating an entry will update all data but will NOT have an effect on the daily value graph
                </div>
        </div>
        )

}

export default EditInModal