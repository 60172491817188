import { useFetchDailyValueByPfIdQuery } from "../../store";

function GetYesterdayValueIndividual(pf_id) {
        //console.log('=============================', pf_id)
        const { data, error, isLoading } = useFetchDailyValueByPfIdQuery(pf_id);
        if (error) {}
        if (isLoading) {}
        if(data){
            //console.log(data);
                if(data.length === 0){
                        return 0;
                }

    const x = data.at(-1)
    let xx = x.value
            return xx
        }
    
return 0
}

export default GetYesterdayValueIndividual