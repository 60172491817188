//import { useEffect, useState } from 'react';

function SidebarLogout({werktSluiten, logout}) {
    
return (
    <div>
        Are you sure you want to Log out?
        <div>
            <button 
    onClick = {logout}
    style = {{
        cursor: 'pointer',
        color: 'red',
    }} >
    Logout
</button></div>
    <button onClick= {werktSluiten} 
             className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600'>
                Cancel</button>
                </div>
)
}

export default SidebarLogout