import { Link } from "react-router-dom"
import Login from "./LoginStuff/Login"
function Landing() {
    //<Link to = "/signup">
    //Sign up
//</Link>

    return (
        <>
       
        <div>
            <h1>Stop </h1> waisting time 
            <p>This is the landing page. Here comes lots of text and some buttons ans CTA to login and register</p>
            <p>For demonstration purpose only ...</p>
            <p>Layout needs to change</p>
            Please Sign in:
            --------------
            <Login />
        </div>
        
        </>
    )
}

export default Landing