import { useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js"
import { useState, useEffect } from "react"


function StepTwo() {
     //console.log('here secret: ',secretkey);
     //const [name, setName] = useState('');
     //const [email, setEmail] = useState('');
     //const [priceId, setPriceId] = useState('');
     const [isProcessing, setIsProcessing] = useState(false)
     //const [clientSecret, setClientSecret] = useState()
     //const appearance = {
     //    theme: 'stripe'
     //  }
      
     //console.log('options:' , options)
     const stripe = useStripe();
     const elements = useElements();
 
     //useEffect(() => {
     ////    setPriceId('price_1N0dzuEmOCxfDzgdgj81UscT');
    // }, []);
    
     
     console.log(elements)
 
     const createSubscriptiontris = async (e) => {
         e.preventDefault();
         if (!stripe || !elements){
             console.log('something not loqded');
             return;
         }
         setIsProcessing(true);
         
         
         const { error, paymentIntent } = await stripe.confirmPayment ({
             elements,
             confirmParams: {
                 return_url: `${window.location.origin}/Ok`
             },
             //redirect: 'if needed'
         })
             if (error) {
                 console.log('something went wrong: ', error.message)
             }
             else if (paymentIntent) {
                 console.log('payment status: ', paymentIntent.status)
             }
         
         
     }
 
 
     const test = {
         layout: 'tabs'
     }
     //const options = {
       //  clientSecret
         
     //}
     //console.log('in options: ', options);
     //const elements = stripe.elements({clientSecret,appearance})
     //const elements = stripe.elements(options)const
     const paymentElementOptions = {
         layout : 'tabs'
         //<PaymentElement options = {paymentElementOptions} />
     }

    return (
        <div>
            Step two
            <PaymentElement />
        
       
        <button 
        className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mb-1 hover:text-white hover:bg-blue-600'
        onClick = {createSubscriptiontris} disabled ={isProcessing}>
            {isProcessing ? 'Processing ....' : 'Pay now'}
        </button>
        </div>)
}

export default StepTwo