import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/Pricing/CheckoutForm';
import ToggleButton from '../components/ToggleButton';
import { useState, Fragment }from 'react';
import SidebarSmall from '../components/SidebarSmall'

const STRIPE_KEY = 'pk_test_51LONzUEmOCxfDzgdTlpuwqfiMSZZIonQT7CdjGUbdxUdFcpBOeGPt6jk7toHgntLe4ZzyXMLg1ujvTk2ve0uwXNw00CxxB0uqp';
    

const stripePromise = loadStripe(STRIPE_KEY);
//const stripe = Stripe(STRIPE_KEY); 
function PricingPage () {
    const [isChecked, setIsChecked] = useState(false);
    const MonthlySubscription = {
        price: 9.99, 
        priceId: 'price_1O2V5tEmOCxfDzgdOS2BcsnN', 
        product: 'Monthly subscription'
    };
     const YearlySubscription = {
        price: 99.99, 
        priceId: 'price_1O2V6qEmOCxfDzgdPD2snz5u', 
        product: 'Yearly subscription'
    } ;
    const LifetimeSubscription = {
        price: 249.99, 
        priceId: 'price_1O2VZVEmOCxfDzgdUOXwtXpX', 
        product: 'Yearly subscription'
    } 

    const handleCheckBoxChance = () => {
        console.log('clicked on it')
        setIsChecked(!isChecked)
        //setMonthly(!isChecked)
    }
    let Monthly;
    let selectedPlan;
    if (isChecked) {
        Monthly = '99.99$/Year';
        selectedPlan = YearlySubscription;
    }
    if (!isChecked) {
        Monthly = '9.99$/Month';
        selectedPlan = MonthlySubscription
    }
    console.log('in pricing, handleMonthly = ', isChecked)
    console.log('in pricing page');
    //const Price = 'Premium';
    const disabledButton = true;
    
    const appearance = {
        theme: 'stripe'
      }
      const options = {
        //clientSecret,
        appearance
      }
   
      //const elements = stripe.elments({clientSecret, appearance});
      //c/onst paymentElement = elements.create('payment', options);
      //paymentElement.mount('#payment-element')
      //<Elements stripe = {stripePromise} options = {options}>
      //<CheckoutForm />
  //</Elements>
    return (
    <div><div className = 'md:invisible'>
    <SidebarSmall />
    
    </div>
    <div className = 'grid grid-cols-1 md:flex md:gap-5'> 
        <div className = 'mx-5 md:mx-0 mt-10 md:mt-0'>
            <div className = 'shadow-md rounded-lg bg-neutral-100 px-2 my-1 py-1'>
                <div className = 'whitespace-pre'> {' '}</div>
                <div className = 'flex text-lg bg-blue-200 -mx-2 justify-center my-2 font-bold '>Free plan</div>
                <div className = '-ml-2 flex font-bold justify-center'>0$</div>
                <div>1 Manual entry portfolio</div>
                <div>maximum 20 stock in portfolio</div>
                <div>maximum 20 stocks in watchlist</div>
                <div>Limited portfolio diversification analysis</div>
                <div>US stock and ETF data </div>
                <div>Limited stock analyis</div>
                <div className = 'whitespace-pre'> {' '}</div>
                <div className = 'whitespace-pre'> {' '}</div>
                <div>
                
                </div>
                
            </div>
            <div className = 'pt-1 md:pt-5 pd-4 md:pb-0 flex justify-center'>
            <button 
                disabled = {disabledButton}
                className = 'border rounded-full text-neutral-400 border-2  border-neutral-400 px-2 mt-1'>
                                    Current plan
                                </button>
                               
                                </div>
        </div>
        <div className = 'mx-5 md:mx-0 mt-10 md:mt-0'>
            <div className = 'shadow-md rounded-lg border border-2 border-blue-600 px-2 my-1 py-1'>
                <div className = 'flex flex-row justify-center'>
                    <div className = 'mr-2'>Monthly</div>
                    <div><ToggleButton handleCheckBoxChance = {handleCheckBoxChance} isChecked = {isChecked} />
                    </div> 
                    <div className = 'ml-2'>Yearly
                    </div>
                
                </div>
                <div className = 'flex text-lg bg-blue-600 -mx-2 justify-center my-2 font-bold text-white'>Premium Plan</div>
                <div className = 'flex font-bold justify-center'>{Monthly}</div>
                <div>Unlimited (and automatic) portfolios</div>
                <div>Unlimited stocks in portfolios</div>
                <div>Unlimited stocks in watchlist</div>
                <div>Deep portfolio diversification analysis</div>
                <div>US, Canada and Europe stock and ETF data </div>
                <div>Deep stock analyis</div>
            
            <div className = 'whitespace-pre'> {' '}</div>
            <div className = 'whitespace-pre'> {' '}</div>
            </div>
        
            <div className = 'pt-1 md:pt-5 pd-4 md:pb-0 flex justify-center'>
            <Link className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600' key='xxx' to={`/Checkout`} state = {selectedPlan}>
                                    Select Plan
                                </Link>
            </div>
        </div>
        
        <div className = 'mx-5 md:mx-0 mt-10 md:mt-0'>
            <div className = 'shadow-md rounded-lg bg-neutral-100 px-2 my-1 py-1'>
            <div className = 'whitespace-pre'> {' '}</div>
                <div className = 'flex mt-2 text-lg bg-blue-200 -mx-2 justify-center my-2 font-bold '>Lifetime Plan</div>
            <div className = '-ml-2 flex font-bold justify-center'>249.99$</div>
            <div>Unlimited (and automatic) portfolios</div>
            <div>Unlimited stocks in portfolios</div>
            <div>Unlimited stocks in watchlist</div>
            <div>Deep portfolio diversification analysis</div>
            <div>US, Canada and Europe stock and ETF data </div>
            <div>Deep stock analyis</div>
            <div>Our deepest respect</div>
            <div>Forever a place in our hearts</div>
        </div>
        <div  className = 'pt-1 md:pt-5 pd-4 md:pb-0 flex justify-center'>
            <Link className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600' key='xxx' to={`/Checkout`} state = {LifetimeSubscription}>
                                    Select Plan
                                </Link> 
            </div>
        </div>
    </div>
    </div>
    )
}

export default PricingPage