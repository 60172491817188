import Email from '../components/Profile/Email';
import Display from '../components/Profile/Display';
import SidebarSmall from '../components/SidebarSmall';

function ProfilePage ({user}) {
    console.log('user = ', user);
    const space = ' ';
    return (
    <div>
        <div className = 'z-50 mb-5 md:invisible'>
        <SidebarSmall />
        </div>
        <div className = 'mt-5 flex flex-column rounded-lg bg-white shadow-md px-5 py-3'>
            <div className = 'basis-1/3'>
                <div >
                    <div className= 'text-blue-600 font-bold'>
                        Profile Info
                    </div>
                    <div>Update your profile information</div>
                </div>
            </div>
            <div className = 'basis-2/3'>
                <div >
                    Profile Picture:
                </div>
                <div >
                    First name: {user.firstName}
                </div>
                <div >
                    Last name: {user.lastName}
                </div>
                <div >
                    Email:
                    {user.email}
                </div>
            </div>
        </div>
        

        <Email user = {user.email} />
        <Display />
        
        
        </div>
    )
}

export default ProfilePage