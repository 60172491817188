import { useState } from 'react';
import DropDown from '../components/DropDown';
import { useEffect } from 'react'; 
import { useFetchTickersQuery } from '../store';
import { useAddTickerToPortfolioMutation } from '../store';


function Home({user}) {
    console.log(user);
    const logout = () =>  {
        localStorage.removeItem('user');
        window.location.reload();
    };
    //c onsole.log('home');
    const [drip, setDrip] = useState('');
    const [sectorSelection, setSectorSelection] = useState('');
    const options = [
        { label: 'Basic Materials', value: 'Basic Materials'},
        { label: 'Communication Services', value: 'Communication Services'},
        { label: 'Consumer Cyclical', value: 'Consumer Cyclical'},
        { label: 'Consumer Defensive', value: 'Consumer Defensive'},
        { label: 'Energy', value: 'Energy'},
        { label: 'Healthcare', value: 'Healthcare'},
        { label: 'Financial', value: 'Financial'},
        { label: 'Financial Services', value: 'Financial Services'},
        { label: 'Industrial Goods', value: 'Industrial Goods'},
        { label: 'Industrials', value: 'Industrials'},
        { label: 'Real Estate', value: 'Real Estate'},
        { label: 'Services', value: 'Services'},
        { label: 'Technology', value: 'Technology'},
        { label: 'Utilities', value: 'Utilities'},
    ];
    const handleSelect = (option) => {
        setSectorSelection(option);
        console.log(sectorSelection)
    };





    const { data, error, isLoading } = useFetchTickersQuery();
        const [tickermatch, setTickermatch] = useState([]);
        
        const [ticker, setTicker] = useState('');
        const [searchTerm, setSearchTerm] = useState('');
        const [number, setNumber] = useState('');
        const [price, setPrice] = useState('');

        
    //co nst { data, error, isLoading } = useFetchPortfoliosQuery(name);
    
    const [addTicker, results] = useAddTickerToPortfolioMutation();
    
    //const [userid, setUserid] = useState('');

    
  const toAdd = {pfId: 163, ticker: searchTerm, amount: number, buy_price: price, userId: 3};
    console.log(results.isSuccess);
 
        
        let renderitems = '';
        let tickerNames = []
        if (isLoading) {
          console.log('-------sector----Loading---');
            
        } else if (error) {
            console.log('-------sector-----Error--');
            //loaded = false;        
        } else if (data) {
            tickerNames = [...data];
        };

        const search = (text) => {
                console.log('tekst: ',text);
                if (text.length > 0 ) {
                const str = text;
                setSearchTerm(text)
                
                let matches = tickerNames.filter((ticker) => {
                        const regex = new RegExp(str, "gi");
                        //console.log(ticker.ticker.match(regex))
                        return ticker.ticker.match(regex)
                        })
                        setTickermatch(matches)
                } else if (text.length < 1){
                        setTickermatch('')
                        setSearchTerm('')
                }       
                }
                let options2 =[];
        if (tickermatch) {
            
            options2.push(tickermatch.map((item,index) => {
                
                return {label: item.ticker, value: item.name}
        })
        )}
        console.log('otions2: ', options2)
        
                





        const selectHandler = (x) => {
                setTickermatch('')
                console.log('selected :', x);
                setSearchTerm(x);                
        }
        const addNumber = (y) => {
                setNumber(y)
                console.log(y)
        }
        const addPrice = (z) => {
                setPrice(z)
                console.log(z)
        }

        const handleAdd = () => {
                addTicker(toAdd)
        
            }























        let content2 = 'kies iets'
//<DropDown options = {options} selection = { sectorSelection } onSelect = {handleSelect} content = 'kies iets' />
  //          drip is ... : {sectorSelection.value}
    //    </div>


    return (
    <div>
        
        
        Home ---- Signed in
        <button 
            onClick = {logout}
            style = {{
                cursor: 'pointer',
                color: 'red',
            }} >
            Logout
        </button>  




-----------------------
<div>
                        adding to Portfolio: xxx
                         Please enter ticker:
                </div>
                <div>
                        <input placeholder = 'enter something'
                                value = {searchTerm}
                                onChange = {(e) => search(e.target.value)}
                        />                                          
                        <DropDown options = {options2[0]} selection = { ticker } onSelect = {handleSelect} />
                </div>
                <div>
                        Number of stocks:
                        <input placeholder = 'Number of stocks'
                                value = {number}
                                onChange = {(e) => addNumber(e.target.value)}
                        />
                </div>
                <div>
                        Cost per share:
                        <input placeholder = 'cost per share'
                                value = {price}
                                onChange = {(e) => addPrice(e.target.value)}
                        />
                </div>
                <div>
                        iets nieuws:
                        <div>
                                <button onClick = {handleAdd}>
                                        add Ticker
                                </button>
                        </div>
                </div>

  
    </div>
    )
}

export default Home