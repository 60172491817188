import { useState, useEffect, useRef } from 'react';
//import { GoChevronDown }  from 'react-icons/go';
import Panel from './Panel';

function DropDown({options, selection, onSelect}) {
    console.log(options);
    const [isOpen, setIsOpen] = useState(false);
    const divEl = useRef();
    const domNodeRef = useRef(null);
    //if (domNodeRef.current) {}
    useEffect(() => {
        
        const handler = (event) => {
            if(!divEl.current.contains(event.target)){setIsOpen(false)};
        }
        if (domNodeRef.current) {document.addEventListener('click', handler, true);}

        return () => {
            document.removeEventListener('Click', handler);
        }
    },[]);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setIsOpen(false);
        console.log('in dropdown - handleoptionclick: ',option);
        onSelect(option);
    };
    

    const renderedOptions = options.map((option) => {
        console.log(option);
        return <div className = 'hover:bg-sky-100 rounded cursor-pointer p-1' 
            onClick = { () => handleOptionClick(option) } key = {option.value}>
            {option.label}
        </div>
});

    let content = 'Select ...';
    console.log('Selection in DropDown: ', selection)
    if (selection){
        content = selection.label;
    }
 //put after content hieronder: <GoChevronDown className = 'text-lg'/>
    return (
        <div ref = {divEl} className = 'w-48 pb-2 relative'>
        <Panel
         className = 'flex justify-between items-center cursor-pointer' 
            onClick = {handleClick}>{content}
         </Panel>
        {isOpen && <Panel className = "absolute top-full border" >{renderedOptions}
        </Panel>}
        </div>
    )
}

export default DropDown