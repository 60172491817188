import TestPage from "./TestPage";

function CalculatePercentages({data, total, pf_id, overview}) {
    //console.log('in Percentages:');
    //console.log('Data :', data);
    //console.log('total: ', total);
    //console.log(pf_id);
    let to_show = true;
    let to_show2 = true

    if (data.length <1){
        //console.log('nothing to display');
        to_show = false;
    }
    if (pf_id === undefined){
        //console.log('in true')
        //to_show = false;
    } 
    if (overview === 'yes') {
        to_show2 = false;
    }

    
let div_sum = 0;
    data.forEach( x => {
        div_sum = div_sum + (x.payout * x.frequency * x.amount)

    })
    let current_sum = 0;
    data.forEach( x => {
        current_sum = current_sum + x.price

    })
    let buy_sum = 0;
    data.forEach( x => {
        buy_sum = buy_sum + x.buy_price

    })
    const yopPercentage = parseFloat(((div_sum * 100)/current_sum)).toFixed(2);
    const yocPercentage = parseFloat(((div_sum * 100)/buy_sum)).toFixed(2);

    const rows = 2;
    return (
        <div>
            {to_show ? (
            <table className='w-full'>
            <thead>
                <tr key = 'werty' className = 'text-left'>
                    <td>
                        Annual payout
                    </td>
                    <td>
                        Total Yield
                    </td>
                    <td>
                        Yield on Price
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className = 'text-blue-600 font-bold text-lg'>
                        {parseFloat(div_sum).toFixed(2)}$
                    </td>
                    <td className = 'text-blue-600 font-bold text-lg'>
                        {yocPercentage}%
                    </td>
                    <td className = 'text-blue-600 font-bold text-lg'>
                        {yopPercentage}%
                    </td>
                    {to_show2 ? (
                    <td rowSpan={rows}>
                        <TestPage pf_id = {pf_id} /> 
                    </td>) : <td className = 'px-10'>{' '}</td>}
                </tr>
            </tbody>
        </table>)
        : ( <div className = 'flex'>
            <div>Welcome to MyDividendCompagnon, add a ticker to your portfolio ... </div> 
            <div><TestPage pf_id = {pf_id} /></div>
            </div>
        )}
        </div>
    )
}
 
export default CalculatePercentages