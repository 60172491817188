import { useEffect } from "react";

import { useSignupMutation } from "../../store";

 
function ManualSignin({email, password}) {
   
    //con sole.log('email: ' , email)
    
    const [signup, results] = useSignupMutation();
    let toReturn; 
    useEffect(() => {signup({email: email , password: password})}, [])


            if (results.data) {
                console.log(results.data)
                if (results.data === 'already') {
                    console.log('data returned from nodejs: ',results.data)
                    //message = 'Email address is already registered. Pleae Log in.'
                    toReturn = 'Already';
            }
                if (results.data.user){
                    console.log('to portfolios');
                    console.log('result.data.user.email: ', results.data.user.email)

                    localStorage.setItem('user', JSON.stringify({email: results.data.user.email, userid: results.data.user.userid}))
                    window.location.reload();
                    toReturn = 'OK';
  
                }
        }
        if (!results.data) {
            console.log('nothing')
            return 'noppes'
        }
        return toReturn;
    }

    export default ManualSignin