import { useFetchKingsAristocratsQuery } from "../../store";
import { Link } from "react-router-dom";
import GetName from "../../HelperFunctions/GetName";
import SortableTable from '../Table/SortableTable';
import UpcomingWeek from './UpcomingWeek';

function Aristocrats() {
    const toPass = 'A'
    const { data, error, isLoading } = useFetchKingsAristocratsQuery(toPass);
    let lowerPart = '';
    let x;

    const keyFn = (iets) => {
        return iets.ticker
    }

    function image(iets) {
        const name = iets;
        const x = 'https://financialmodelingprep.com/image-stock/';
        //const x = 'https://eodhistoricaldata.com/img/logos/US/'
        let y = x.concat(name).concat('.png')
        return y}
        let tablePart;
    const config = [
        { label: 'Ticker', 
        render: (xx) => (
            
            <div >
                 <div className = 'h-10 flex' >
                    <img alt = ' '  src = {image(xx.ticker)}  className = "object-cover w-8 h-8 pt-2 mr-2" />
                    
                        <div className = 'text-blue-500 font-semibold'>
                        <strong>
                            <Link key={'xxx'} to={`/Details/${xx.ticker}`}>
                                {xx.ticker}
                            </Link>
                        </strong>
                        <div className = 'text-gray-400 '>
                        <GetName ticker = {xx.ticker} />
                            </div>
                        </div>
                    
                </div>
           </div>),
        header: () => <th className = "bg-red-500 w-fit">ticker</th>,
        sortValue: (xx) => xx.ticker},
        { label: 'Price',
        render: (xx) => xx.price,
        sortValue: (xx) => xx.price},
        { label: 'Yield',
        render: (xx) => xx.div_yield,
        sortValue: (xx) => xx.div_yield},
        ];
    if (error){
        lowerPart = 'an error happening'
    }
    if (isLoading){
        lowerPart = 'Gathering data'
    }
    if(data){
        console.log(data);
        const data2 = [{id: 46, ticker: 'ABT', kind: 'A', price: 100.91, div_yield: 1.86305}];
        //let data2 = Object.keys(data).map((key) => data[key]);
        //console.log(data2);
        //let content  = data2;//CleanupArray(data);
        //const renderedItems = '';
        lowerPart = data.map((test) => {
            return <div>{test.ticker} - {test.price} - {test.div_yield}</div>
        })
        tablePart = <SortableTable content2 = {data} config ={config} keyFn = {keyFn} />
        console.log('x', x);
        console.log(data2);
        //lowerPart = {x}
    }
    let weekly = <UpcomingWeek kind = 'A' />;

    return (
         <div>
            <div className = 'grid grid-cols-1 md:flex md:flex-column'>
            <div className = 'visible md:hidden'>
                    <div className = 'rounded-lg bg-white shadow-md px-5 py-3 mb-5 mx-3'>
                        {weekly}
                        </div></div>

                <div className = 'mx-3 md:basis-1/2 rounded-lg bg-white shadow-md px-5 py-3'>
                    {tablePart}
                </div>
                <div className = 'mx-3 md:basis-1/2'>
                    <div className = 'invisible md:visible rounded-lg bg-white shadow-md px-5 py-3 mb-5'>
                        {weekly}
                    </div>
                
                </div>
            </div>
        </div>
    )
}

export default Aristocrats