import { useFetchStocksAndPricesQuery } from '../../store';
import CleanupArray from '../Portfolios/CleanupArray';
import MinMax from '../../HelperFunctions/MinMax';
import DiversificationBreakdown from './DiversificationBreakdown';
import ApexTest3 from '../Charts/ApexTest3'
import PortfolioDiversification from './PortfolioDiversification';
import SectorDiversification from './SectorDiversification';
//import classname from 'classnames';
import FinalGetSectorInfo from './FinalGetSectorInfo';
import ApexTest6 from '../Charts/ApexTest6';
import DividendBreakdown from './DividendBreakdown';
import TabulatedCycles from './TabulatedCycles';
//import { useEffect, useState } from 'react';
//import { useState } from 'react';



function DiversificationBigOne(name) {
    //console.log(name.name.id)
   // console.log('show div big one bis')
    //const [sectors, setSectors] = useState([])
    const xxx = FinalGetSectorInfo(name.name.id)
    
    //console.log('xxx', xxx);
//console.log(name);
//const test = {id:18}
    const { data: myData, error, isLoading, isSuccess } = useFetchStocksAndPricesQuery(name.name.id);
    //console.log(name)
    let content_down; 
    let result;
    let newArray;
    //let loaded = false;
    if (isLoading) {
        content_down = 'loading data ...';
        
        
    } else if (error) {
        content_down = 'error ...';
        
         
    } else if (isSuccess && xxx) {
        //console.log('data received fron useFS&P', myData);
        let newArrayOG = myData;
        newArrayOG = [...myData]
        const { cleanArray, total2} = CleanupArray(newArrayOG);
        

       
        newArray = cleanArray;
        newArray = [...cleanArray]
        //console.log('newarray to MinMax', newArray)
        //console.log('xxx to be sent to minmax: ',xxx);
         result = MinMax(newArray, xxx);
         //result = <MinMax newArray = {newArray} />;
        //console.log('new array: ', newArray);
        //console.log('result: ', result);
         //console.log(result.min);
         //return result;
        
        
        //console.log('value in listBigOne :', total2)
        //content_down = <SortableTable content2 = {newArray} config ={config} keyFn = {keyFn} value = {total2} />;
        
        //console.log(value);
 
        content_down = (
            <div>
                <div className = 'grid grid-cols-1 md:flex md:flex-row'>
                    <div className = 'md:flex-1 rounded-lg bg-white shadow-md md:px-5 md:py-3 mb-5 my-2 md:mr-5 mx-3 pt-5 md:pt-0'>
                        <div className = 'flex justify-center  text-bold text-blue-600 '>
                        <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3 md:my-3'>
                                        Diversification Breakdown
                                    </button>
                        </div>
                        <div  className = 'grid grid-cols-1 justify-items-center md:flex md:flex-column md:content-center'>
                            <div className = 'md:w-1/2'>
                            <DiversificationBreakdown data = {result} value = {total2} type = 'big' user = {name.name.id} />
                            </div>
                            <div className = 'md:w-1/2 md:ml-5'>
                            <ApexTest3 donutData = {newArray} />
                            </div>
                        </div>
                    </div>
                </div>
                
                

                
                <div className = 'grid grid-cols-1 md:flex md:flex-row'>
                    <div className = 'flex-1 rounded-lg bg-white shadow-md md:px-5 md:py-3 mb-5 my-2 md:mr-5 mx-3 pt-10'>
                        <div className = 'flex justify-center  text-blue-600'>
                        <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3 md:my-3'>
                                        Dividend Breakdown
                                    </button>
                        </div>
                        <div  className = 'grid grid-cols-1 justify-items-center md:flex md:flex-column md:content-center '>
                        <div className = 'md:w-1/2'>
                            <DividendBreakdown data = {result}  />
                        </div>
                        <div className = 'md:w-1/2 md:ml-5'>
                            <ApexTest6 donutData = {newArray} />
                        </div>
                        </div>
                    </div>
                </div>
                

                
                <div className = 'flex flex-col md:flex-row'>                    
                    <div className = 'flex basis-1/2 rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                        <div className = 'flex-1'>              
                            <div className = 'flex justify-center  text-blue-600'>
                            <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3'>
                                        Sector Breakdown
                                    </button>
                            </div>   
                                                    
                            <PortfolioDiversification data = {result} /> 
                        </div>             
                    </div>
                
                    <div className = 'flex basis-1/2 rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                        <div className = 'flex-1'>              
                            <div className = 'flex justify-center  text-blue-600'>
                            <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3'>
                                        Cycle Breakdown
                                    </button>
                            </div>
                
                        <SectorDiversification data = {result} />
                        </div>
                    </div>
                </div>
            
                
                 
                <div className = 'flex-1 flex-row'>
                    <div className = 'shadow-md bg-white rounded-lg space-x-5 py-2  mb-3 pl-5 my-2 mr-5'>
                    <div className = 'flex justify-center  text-blue-600'>
                        <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3'>
                                        Tabulated Cycles
                                    </button>
                        </div>
                    
                    <TabulatedCycles stockData = {newArray} stockSector = {xxx} />
                    </div>
                </div>
                
            </div>
        )
    }
return <div>{content_down}</div>
}

export default DiversificationBigOne