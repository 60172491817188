import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { editValue, editYesterday } from '../../store';
import GetYesterdayValue from './GetYesterdayValue';
import GetYesterdayValueIndividual from './GetYesterdayValueIndividual';

function DiversificationBreakdown({data, value, type, user}) {
    //console.log('type: ', type);
    //console.log('user: ', user);
    //const {userOrPf, data } = props;
    //console.log(props);
    //console.log('array in div breakdowmn: ',data);
    //console.log(value);
    const array = data;
    //console.log(data);
    let tttt = 0;
    //type = 'big'
    if (type === 'big'){
        //console.log('in type is big');
        tttt = GetYesterdayValue(user);
        //console.log('ttt in big is ', tttt)
        const action2 = editYesterday(tttt);
    setTimeout(() => { dispatch(action2)}, 0);
    }
    if (type === 'individual'){
        //console.log('in type is SMALL');
        tttt = GetYesterdayValueIndividual(user);
        //console.log('ttt in small is ', tttt)
        const action2 = editYesterday(tttt);
    setTimeout(() => { dispatch(action2)}, 0);
    }
     
    

    const dispatch = useDispatch();
    const waarde = value;
    const action = editValue(waarde);
    useEffect(() => {dispatch(action);});
    

    return (
        <div>
        <table className = 'border-separate border-spacing-x-5 '>
            <tbody>
            <tr >
                <td>Amount of stocks </td>
                <td className = 'ml-2'>{array.totalStocks}</td>
            </tr>
            <tr>
                <td>Biggest stock</td>
                <td>{array.maxTicker} - ${parseFloat(array.max).toFixed(2)}</td>
            </tr>
            <tr >
                <td> </td>
                <td>{parseFloat(array.max * 100 / array.totalValue).toFixed(2)}%</td>
            </tr>
            <tr>
                <td>Smallest stock</td>
                <td>{array.minTicker} - ${parseFloat(array.min).toFixed(2)}</td>
            </tr>
            <tr>
                <td> </td>
                <td>{parseFloat(array.min * 100 / array.totalValue).toFixed(2)}%</td>
            </tr>
            <tr>
                <td>Total sectors</td>
                <td>{array.totalSectors}</td>
            </tr>
            <tr>
                <td>Largest sector</td>
                <td>{array.maxSector} - ${parseFloat(array.maxValueSector).toFixed(2)}</td>
            </tr>
            <tr>
                <td> </td>
                <td>{parseFloat(array.maxValueSector * 100 / array.totalValue).toFixed(2)}%</td>
            </tr>
            <tr>
                <td>Smallest sector</td>
                <td>{array.minSector}</td>
            </tr>
            <tr>
                <td> </td>
                <td>{parseFloat(array.minValueSector * 100 / array.totalValue).toFixed(2)}%</td>
            </tr>
            
            </tbody>
        </table>
        
        </div>
    )
}

export default DiversificationBreakdown