import { useGetScreenerQuery } from '../../store';
import { Link } from 'react-router-dom';
import SortableTable from '../Table/SortableTable';
//import DropDown from '../DropDown';
import { useState } from 'react';

function StockScreenerSearch(data2) {
    let sector = '' 
    const exchange = 'NYSE'
    
    if(data2.data2.sectorSelection.value) {
        sector = data2.data2.sectorSelection.value
    };
    const dividend = data2.data2.dividend;
    const price = data2.data2.price;
 
    
    const { data, error, isLoading } = useGetScreenerQuery([dividend, sector, price, exchange]);
    let contentLower, contentLower2 = ''
    const config = [
        { label: 'Ticker', 
        render: (xx) => (
            
            <div><Link key={'xxx'} to={`/Details/${xx.symbol}`}>{xx.symbol}</Link></div>
        
        ),
        header: () => <th className = "bg-red-500">ticker</th>,
        sortValue: (xx) => xx.symbol},
        { label: 'Price',
        render: (xx) => xx.price},
        { label: 'Dividend',
        render: (xx) => parseFloat(xx.lastAnnualDividend).toFixed(2),
        sortValue: (xx) => xx.lastAnnualDividend},
        ]
    if (error) {
        console.log(error);
    }
    if (isLoading) {
        contentLower = 'Gathering data';
    }
    if (data) {
        //console.log(data);
        //const total2 = 0;
        const keyFn = (iets) => {
            //console.log(iets);
            return iets.symbol
        }
        contentLower = <SortableTable content2 = {data} config ={config} keyFn = {keyFn}   />;
        contentLower2 = 'The data shown takes in consideration the last paid out dividend, so it might be that the company has stopped paying dividends but is still shown here in the list. Please do further research, this table is only indicative.'
    }
    return (
        <div>
            {contentLower}  
            {contentLower2}
                      
        </div>
    )
}

export default StockScreenerSearch