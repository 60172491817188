import ReactDOM from 'react-dom';

function Modal({ Sluiten, children, actionBar }) {
    //className = 'absolute inset-0 bg-white opacity-20 flex'
    //'absolute inset-40 ml-28  w-1/2 h-1/2 rounded-lg p-5 bg-neutral-100 opacity-100'
    // voor center 1st iv: 'h-screen bg-purple-200 flex items-center justify-center'
    //'rounded-md bg-gray-500 py-6 px-6 text-white'
    return ReactDOM.createPortal(
      <div >
        <div onClick= {Sluiten} className = 'absolute inset-0 bg-white opacity-60 flex' ></div>
        <div className = 'fixed inset-2 md:inset-40 md:ml-28  h-1/2 w-full md:w-1/2 md:h-1/2 rounded-lg p-5 bg-neutral-100 opacity-100'>
            <div className = 'flex md:justify-end'>
                    {actionBar}
                </div>
            <div className = 'flex flex-col justify-between h-full'>
                {children}
                
            </div>
        </div>
    </div>,
    document.querySelector('.modal-container')
    );
}

export default Modal;