import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const searchApi = createApi ({
    reducerPath: 'search',
    baseQuery: fetchBaseQuery ({
        baseUrl: "https://nodejs.mydividendcompagnon.com"
        }),
        endpoints(builder) {
            return {
                fetchTickers: builder.query({
                    query: (ticker) => {
                        return {
                            url: '/searchTicker',
                            params: {
                                ticker: ticker
                            },
                            method: 'GET'
                        };
                    },
                }),  
                fetchName: builder.query({
                    query: (x) => {
                        //console.log('x in api: ', x)
                        return {
                            url: '/searchNameByTicker',
                            params: {
                                ticker: x
                            },
                            method: 'GET'
                        };
                    },
                }),                      
            };
        }
});

export const { useFetchTickersQuery, useFetchNameQuery } = searchApi;
export { searchApi };
