import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const priceApi = createApi( {
        reducerPath: 'currentPrice',
        baseQuery: fetchBaseQuery({
            baseUrl: "https://nodejs.mydividendcompagnon.com"
        }),
        endpoints(builder) {
            return {     
            fetchBulkCurrentPrice: builder.query ({
                query: (list_of_tickers) => {
                    
                    return {
                        
                        url: '/bulkrecentprices',
                        
                        params: {

                            
                           // const y = list_of_tickers
                            ticker: list_of_tickers
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,   
                
            fetchCurrentPrice: builder.query ({
                query: (ticker) => {
                    return {
                        url: '/recentprices',
                        params: {
                            ticker: ticker
                        },
                        method: 'GET'
                    };
                    
                },
            }),
        };
    },

});

export const { useFetchCurrentPriceQuery, useFetchBulkCurrentPriceQuery } = priceApi;
export { priceApi }