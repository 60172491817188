import CalendarBigOne from "../components/Calendar/CalendarBigOne";
import CalendarIndividual from "../components/Calendar/CalendarIndividual";
import UpdateUpperValue from '../components/UpdateUpperValue'

import MiddlePart from '../components/MiddlePart';

import { useState } from 'react'; 
import SidebarSmall from '../components/SidebarSmall'


function CalendarPage ({user}) {
    const name = {id:user.userid}; 

    

    let contentMiddle;
    const {ContentMiddlePart, data_middle_loaded } = MiddlePart(name);
    //const {cd, isLoaded} = ShowDiversificationBigOneBis(name)
    //<ShowDiversificationBigOne name= {name}  /> 
    const [activeButton, setActiveButton] = useState(123456789);
    const [contentLower, setContentLower] = useState(
        <div>
            <div>
            <CalendarBigOne name= {name} />
            </div>
            
        </div>);

    
const handleOnclick = (value) => {
    //<ShowDiversificationIndividual ietske = {value} />
    setActiveButton(value);
    setContentLower(
        <div>
            <div>
            <CalendarIndividual ietske= {value} />
            </div>
            
        </div>
    );     
};

const handleOnclickBis = (value) => {
//<ShowDiversificationBigOne name= {name}  />
    setActiveButton(123456789);
    setContentLower(
        <div>
            <div>
            <CalendarBigOne name= {name} />
            </div>
            
        </div>
    );     
};
   
      
    

    if (!data_middle_loaded) {
        contentMiddle =  <div>Waiting for data</div>;
    }
    if (data_middle_loaded) {
        
        //console.log(ContentMiddlePart);
        contentMiddle = ContentMiddlePart.map((pf) => {
            return (
                
                <div key = {pf.id} >
                    
                    <div className = 'group bg-blue-600 rounded-full'>
                        <button onClick = {() => handleOnclick(pf.id)}  
                        className={activeButton === pf.id ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-white hover:bg-blue-800'}>{pf.name}</button></div>
                </div>
                
            )
        });

    };

    return (<div className='bg-neutral-100'>
                <div className = 'md:invisible'>
                <SidebarSmall />
                </div>
                <div className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                    <UpdateUpperValue />

                </div>
                <div className = 'shadow-md rounded-lg flex flex-col md:flex-row bg-blue-600 content-center md:space-x-5 py-5 px-2 mx-2 mb-5 md:px-5 my-2 md:mr-5'>
            <div>
                <button className = 'group bg-blue-600 rounded-full'
                        onClick = {() => handleOnclickBis(1)}>
                    <div 
                        className = {activeButton === 123456789 ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}
                        >Overview
                    </div>
                </button>
            </div>

            <div> 
                <div className = 'grid grid-cols-2 md:flex md:content-center md:space-x-5'>
                    {contentMiddle}
                    
                </div>
            </div>
            
        </div>
   <div >
       
       {contentLower}
   </div>

</div>

)
    
    
    
    
    
    
    
}

export default CalendarPage