function DetailsLowerPart(x) {
    function twoDigits(number) {
        return parseFloat(number).toFixed(2)
    }
    //console.log(x);
    //console.log('lowerpart');
    return (
    <div>
        <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-3'>
                                    General
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/4'>
                            <div className = 'text-center'>
                                Full name
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['companyName']}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                                CEO
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {x['ceo']}
                            </div>
                            
                            
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                                Website
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['website']}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                                Phone number
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {x['phone']}
                            </div>
                            
                            
                        </div>

                    </div>





                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/4'>
                            
                            <div className = 'text-center mt-2'>
                                Country
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['country']}
                            </div>
                        </div>
                        <div className = 'basis-1/4'>
                            
                            <div className = 'text-center mt-2'>
                                Address
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['address']}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/4'>
                            
                            <div className = 'text-center mt-2'>
                                IPO Date
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['ipoDate']}
                            </div>
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                               
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            
                            </div>
                            
                            
                        </div>

                    </div>

                </div>
        </div> 

        <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-3'>
                                    Key Metrics
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/4'>
                            <div className = 'text-center'>
                                Industry
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['industry']}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                            Sector
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {x['sector']}
                            </div>
                            
                            
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                                Exchange
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['exchangeShortName']}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/4'>
                            <div className = 'text-center'>
                                Isin Code
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {x['isin']}
                            </div>
                            
                            
                            
                        </div>

                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/4'>
                            
                            <div className = 'text-center mt-2'>
                                Price
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['price']}
                            </div>
                        </div>
                        <div className = 'basis-1/4'>
                           
                            <div className = 'text-center mt-2'>
                                Last Divindend
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {x['lastDiv']}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/4'>
                        
                            <div className = 'text-center mt-2'>
                                MarketCap
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {parseFloat(x['mktCap']/1000000000).toFixed(2)}bln
                            </div>
                        </div>
                        <div className = 'basis-1/4'>
                            
                            <div className = 'text-center mt-2'>
                                Beta Score
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {x['beta']}
                            </div>
                            
                            
                        </div>

                    </div>

                </div>
        </div> 


    
    </div>
   
    )
}

export default DetailsLowerPart;