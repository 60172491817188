import { useState } from 'react';
import { GoChevronDown, GoChevronLeft } from 'react-icons/go';

function Accordion2 ({ items }) {
    const [expandedIndex, setExpandedIndex] = useState(-1);

    const handleClick = (indexOutOfMapping) => {
        if (indexOutOfMapping === expandedIndex){
            setExpandedIndex(-1);
        } else { 
            setExpandedIndex(indexOutOfMapping);
        }
    }
     
    const renderedItems = items.map((item, index) => {
        const isExpanded = index === expandedIndex; 
        //const content = isExpanded && <div>{item.content}</div>
        //we can only use the part after the = 
       
        const icon = <span> {isExpanded ? <GoChevronDown /> : <GoChevronLeft />}</span>;
        return (
            <div key = {item.title}>
                <div className = 'flex font-semibold justify-between p-2  bg-neutral-100  item-center cursor-pointer' onClick ={() => handleClick(index)}>
                    {item.title}{icon}
                    
                </div>
                {isExpanded && <div className = 'border-b p-2 bg-blue-100 mr-5'>
                    <div>{item.text}</div><div>{item.text2}</div><div>{item.text3}</div>
                    {item.url ? <div><a href = {item.url} target = '_blank'>full article</a></div> : null}
                    </div>}
            </div>
        );
    });
    return (
    <div className = 'border-x border-t rounded'>{renderedItems} </div>
    
    )
}

export default Accordion2;