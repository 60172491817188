function LogoutPage({user}) {
    console.log(user);
    const logout = () =>  {
        localStorage.removeItem('user');
        window.location.reload();
    };
    return (
    <div><button 
    onClick = {logout}
    style = {{
        cursor: 'pointer',
        color: 'red',
    }} >
    Logout
</button></div>)
}

export default LogoutPage