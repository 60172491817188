import { useState } from 'react';
import MiddlePart from '../components/MiddlePart';
import UpdateUpperValue from '../components/UpdateUpperValue'
import ListOfStocks from '../components/Portfolios/ListOfStocks';
import ListBigOne from '../components/Portfolios/ListBigOne';
import AddPortfolioModal from '../components/Portfolios/AddPortfolioModal';
import Footer from '../components/footer';
import DataChartPortfolio from '../components/Portfolios/DataChartPortfolio';
import DataChartSingle from '../components/Portfolios/DataChartSingle';
import History from '../components/Portfolios/History';
import SidebarSmall from '../components/SidebarSmall'

 
function PortfoliosPage({user}) {

  const name = {id: user.userid};
  const id = name.id;

    let ContentMiddle;

    const {ContentMiddlePart, data_middle_loaded } = MiddlePart(name);
    const [activeButton, setActiveButton] = useState(123456789);
    const [contentLower, setContentLower] = useState(
        <div >
            <div >
                <ListBigOne name= {name}  />
            </div>
            <div className = 'rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
            
            <DataChartPortfolio user_id = {id} />
            </div>
            <div className = 'rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                <History user_id = {name.id} which = 'Big' />
            </div>
        </div>);
  
    const handleOnclick = (value) => {
        setActiveButton(value);
        setContentLower(
            <div>
                 
                <div>
                    
                    <ListOfStocks ietske = {value} />
                </div>
                <div className = 'rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                    <DataChartSingle pf_id = {value} />
                </div>
                <div className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                <History user_id = {value} which = 'Small' />
            </div>
                
            </div>
        );     
    };
    const handleOnclickBis = (value) => {
        setActiveButton(123456789);
        setContentLower(
            <div>
                
                <div>
                    <ListBigOne name= {name}  />
                </div>
                <div className = 'rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                    <DataChartPortfolio user_id = {id} />
                </div>
                <div className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
                    <History user_id = {name.id} which = 'Big'/>
                </div>
            </div>
        );     
    };

    if (!data_middle_loaded) {
        ContentMiddle =  <div>Waiting for data</div>;
    }
    if (data_middle_loaded) {
        ContentMiddle = ContentMiddlePart.map((pf) => {
            return (
                
                <div key = {pf.id} >
                    
                    <div className = 'group bg-blue-600 rounded-full'> 
                        <button onClick = {() => handleOnclick(pf.id)} 
                       
                        className = {activeButton === pf.id ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-white hover:bg-blue-800'}>{pf.name}</button></div>
                </div>              
            )
        });
    };


return (
    <div className='bg-neutral-100'>
        <div className = 'md:invisible'>
        <SidebarSmall />
        </div>
        <div className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
             <UpdateUpperValue />
        </div>

        <div className = 'shadow-md rounded-lg flex flex-col md:flex-row bg-blue-600 content-center md:space-x-5 py-5 px-2 mx-2 mb-5 md:px-5 my-2 md:mr-5'>
            <div>
                <button className = 'group bg-blue-600 rounded-full'
                        onClick = {() => handleOnclickBis(1)}>
                    <div 
                        className = {activeButton === 123456789 ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}
                        >Overview
                    </div>
                </button>
            </div>

            <div> 
                <div className = 'grid grid-cols-2 md:flex md:content-center md:space-x-5'>
                    {ContentMiddle}
                    
                </div>
            </div>
            <div><AddPortfolioModal /></div>
        </div>
        
        <div >
            
            {contentLower}
        </div>
        <Footer />
    </div>
)
};

export default PortfoliosPage