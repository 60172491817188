import { useGetPriceHistoryQuery } from '../../store';
import ApexTestDetails from '../Charts/ApexTestDetails';
import { useState, useEffect } from 'react';

function StockAnalysisGraph (analysisData) {
    //console.log(analysisData);
    //console.log(analysisData.analysisData);
    const [chart, setChart] = useState();
    let chart2 = ''
    //const startDate = '2020-01-02'; -> should be 5 years ago
    //console.count();
    let endDate = new Date().toISOString().split('T')[0]  ;
    let startDate2 = '2022-09-02';
    let xxx = '';
    //console.log(endDate);

    function substractMonths(months) {
        const now = new Date();
        //console.log('now: ',now, months);

        let x = new Date(now.setMonth(now.getMonth() - months)).toISOString().split('T')[0];
        //console.log('in function ', x)
        return x;

    } 

    let startDate = substractMonths(60);
    //console.log('startDate', startDate);
    const { data, error, isLoading } = useGetPriceHistoryQuery([analysisData.analysisData, startDate, endDate]);

    function getDateAndData(data, startDate2) {
        //console.log('startdate2', startDate2);
        //console.log('data1', data);

        const resultDates = data.historical.filter(item => item.date > startDate2);
        //console.log(resultDates);
        //const result = data.historical.filter(item => item.adjClose > 155);
        return resultDates;
    }

    

    
    const onClickHandler = (value) => {
        //e.preventDefault();
        //console.log('years ---', value);
        //const starter = new Date();
        //const endstarted = starter.setMonth(starter.getMonth() - 4);
        //console.log(starter, endstarted);
        //console.log(starter.setMonth(starter.getMonth() - 4));
        //const jipie = substractMonths(12);
        //console.log('1 yr ago: ', jipie);
        //if (value === 5) {
          //  startDate2 = substractMonths(60);
            ///chart = <ApexTestDetails data = {xxx} />
        //}
        //if (value === 3) {
            startDate2 = substractMonths(value);
        //}
        //if (value === 3) {
         //   startDate2 = substractMonths(36);
       // }
       xxx = getDateAndData(data, startDate2).reverse();
       //console.log('in button: data ->', xxx);
       chart2 = null;
       setChart(<ApexTestDetails data = {xxx} />)
    }
    if (isLoading) {
        //setChart(<div>is Loading</div>);
    }
    if (data) {
        
        xxx = getDateAndData(data, startDate2);
       //console.log('in button: data ->', xxx);
       xxx = xxx.reverse();
       //setChart(<ApexTestDetails data = {xxx} />)
       chart2 = <ApexTestDetails data = {xxx} />
        //console.log('original data ',data);
        //const dates = data['historical'][0]['adjClose'];
         
        //console.log('xxx: ', xxx);
        //console.log(dates);
        //xxx = getDateAndData(data, startDate2);
        //setChart(<ApexTestDetails data = {xxx} />)
    }
    return (
            <div><button onClick ={() => onClickHandler(60)}>5y</button>  {' '}
                <button onClick ={() => onClickHandler(36)}>3y</button>  {' '}
                <button onClick ={() => onClickHandler(12)}>1y</button>  {' '}
                <button onClick ={() => onClickHandler(6)}>6m</button>  {' '}
                <button onClick ={() => onClickHandler(1)}>1m</button>
                <div>{chart}{chart2}
                </div>
            </div>)

}

export default StockAnalysisGraph