import React from 'react';
import Chart from "react-apexcharts";

const ApexTest = (donutData) => {
    //console.log(donutData.donutData);
  
  if (donutData) {
    const yydata = donutData.donutData.map((amountx) => {
      return (amountx.payout*amountx.frequency*amountx.amount);
    })
    const xxdata = donutData.donutData.map((column) => {
      return column.ticker;
    })
    //con sole.log(xxdata);

    const series = yydata
    const options = { 
    labels: xxdata ,
    legend: {
      show: false
    }
  };
 
  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="pie"
        width='400'     
      />
    </div>
  )
}
};

export default ApexTest;