import React from 'react';
import Chart from "react-apexcharts";

const ApexTest4 = (data) => { 
    const y1data = data.data.map((y) => {
        return y.StartCapital;
    });
   const y2data = data.data.map((yy) => {
        return yy.EndDiv;
    });
   const xxdata = data.data.map((x) => {
        return x.Year;
    });
    console.log(xxdata);

    const series = 
        [{
        name: 'Value portfolio',
        type: 'column',
        data: y1data
        },
        {name: 'Yearly dividend',
        type: 'line',
        data: y2data
        }
    ];

    const options = {
        chart: {id: 'titel'},
        labels: xxdata
        }

  return (
    <div>
      <Chart
        options={options}
        series={series}
        width='800'       
      />
    </div>
  )
}
;

export default ApexTest4;