import { useGetDividendsQuery } from '../../store';
import ApexTest7 from '../Charts/ApexTest7';
import DividendPricePercentage from './DividendPricePercentage';
import CreateTable from '../Table/CreateTable';

function DividendsLowerPart (id) {
    //console.log(id.x);
    const ticker = id.x;
    //const user = false
    const { data, error, isLoading } = useGetDividendsQuery(id.x);
    //console.log(data);
    let contentLower = '';
    let table;
    if (data) {
        //console.log('historical: ',data.historical)
        const config = [
            { label: 'Ex dividend date',
            render: (xx) => xx.date},
            { label: 'Payment Date',
            render: (xx) => xx.paymentDate},
            { label: 'Dividend',
            render: (xx) => xx.adjDividend},
        ];
        const keyFn = (iets) => {
            return iets.date
        }
        const dataForTable = data.historical
        table = <CreateTable data = {dataForTable} config = {config} keyFn = {keyFn} />
        let YearsArray = [];
        //console.log(YearsArray);
        //const r = {year: 2022};
        //YearsArray.push(r);
        //console.log('part 2: ',YearsArray);
        //console.log(data.historical.length)
        
            for (let year = 2012; year < 2023 ; year++){
                //console.log('Year: ', year)
                let totalDividend = 0
                for (let x = 0; x < data.historical.length; x++){                
                    let arrayYear = new Date(data.historical[x].date).getFullYear()
                    if (arrayYear === year) {
                         totalDividend = totalDividend + data.historical[x].dividend;
                         //totalDividend = parseFloat(totalDividend).toFixed(2)
                     }
                //console.log(totalDividend)
            }
            //console.log(totalDividend)
            totalDividend = parseFloat(totalDividend).toFixed(2);
            let toEnter = {Year: {year}, dividend: {totalDividend}};
            //console.log('To enter:', toEnter)
            YearsArray.push(toEnter)
            
            //console.log('in loop')
            //console.log(new Date(data.historical[x].date).getFullYear())
        }
        //console.log(YearsArray);
        //console.log(new Date(data.historical[0].date).getFullYear())
        let yData = YearsArray.map((column) => {
            return column.dividend.totalDividend
        }) 
        let xData = YearsArray.map((column) => {
            return column.Year.year
        })
        //console.log(yData);
        //console.log(xData);
        const name = 'Dividends per year'
        //const legend = 'close'
        contentLower = <ApexTest7 y_data = {yData} x_axis = {xData} name = {name} />
    }
     return (<>        
                <div className = 'flex flex-col md:flex-row'>
                    <div className = 'flex basis-1/2 rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                        <div className = 'flex-1'>{contentLower}</div>
                    </div>
                    <div className = 'flex basis-1/2 rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                        <div className = 'flex-1 '><DividendPricePercentage ticker = {ticker}  /></div>
                    </div>
                </div>
            <div className = 'shadow-md rounded-lg bg-white content-center py-2  mb-3 px-5 my-2 mr-5'>
                
                {table}
            </div>

     </>)
}
//{user ? <div>Paid</div> : <div>Not paid</div>} -> just above table
export default DividendsLowerPart;
