import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../Hooks/useFetch";
import ManualSignin from "./ManualSigin";


const Signup = () =>  {
    
    const [email, setEmail] = useState();
    const [password2, setPassword2] = useState();
    const [password, setPassword] = useState();
    const [buttonText, setButtonText] = useState('Create your account')
    const [test, setTest] = useState('')
    const [message, setMessage] = useState(null)
    
    //const { handleGoogle, loading, error } = useFetch('http://192.168.0.224:8800/signup');
    const { handleGoogle, loading, error } = useFetch('https://nodejs.mydividendcompagnon.com/signup');
    //let message;


    useEffect(() => {
        /* global google */
        if (window.google) {
            google.accounts.id.initialize({
                client_id: '79888983542-30b3s128pjmo57eekkv5lqr37o2khfqm.apps.googleusercontent.com',
                callback: handleGoogle
            });
            google.accounts.id.renderButton(document.getElementById('registerDiv'), {
                        theme: 'outline',
                        text: 'continue with',
                        shape: 'pill',
                        //size: 'sm all'
            })
        }
    }, [handleGoogle]);
  
        const handleManual = () => {
            if (password !== password2){
                console.log('password do not match')
                setTest('Passwords do not match')
            }
            if (password === password2){
            setButtonText('Processing')
            setTest(<ManualSignin email = {email} password = {password} />)}
           // 
           
           
        }
        console.log('test: ', test)
        if (test ==='Already') {
            setMessage('email is already registered, please log in')
        }
        
        

        

    return (
        <div className = 'h-screen bg-purple-200 flex items-center justify-center'> 
            <div className = 'rounded-md bg-gray-500 py-6 px-6 text-white'>
                
            <div className = 'flex items-center justify-center'>
                <div className = 'text-lg text-semibold'>
                    create your free account
                </div>
            </div>
            <div>
                <div>
                    Email
                </div>
                <input name = 'email'  placeholder='WarrenBuffet@gmail.con'
                    onChange = {(e) =>  {setEmail(e.target.value)}}
                    className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-72 text-blue-800'>
                </input>
            </div>
            <div className = 'mt-2'>
                <div>
                    Password
                </div>
                <input name = 'password' type = 'password'
                    onChange = {(e) =>  {setPassword(e.target.value)}}
                    className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-72 text-blue-800'>
                </input>
                <div>
                    Confirm password
                </div>
                <input name = 'password' type = 'password'
                    onChange = {(e) =>  {setPassword2(e.target.value)}}
                    className = 'bg-blue-50 border-2 border-blue-200 rounded-lg w-72 text-blue-800'>
                </input>
            </div>
            <div className = 'text-sm text-red-200'>
            {test}
            </div>
            <div className = 'flex'>
                <div className = 'w-full'>
                    <button onClick = {handleManual} className = 'w-full mb-5  mt-5 px-2 border  bg-blue-400 rounded-md'>
                    {buttonText}
                    </button>
                </div>
            </div>
            <div className = 'text-xs '>
                <Link to = '/login'>Already an account,<u className = 'text-blue-400'> please login</u></Link>
            </div>
            <div className = 'mt-10'>
            <h1>Or register with Google</h1>
            </div>

            <main style = {{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
            }}>
                {error && <p>{error}</p> }
                {loading ? <div>...Loading</div> : <div id = 'registerDiv'></div>}
            </main>
        </div>
           
        </div>
        )
}

export default Signup