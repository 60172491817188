//import { useGetStockScreenerQuery } from '../../store';
import { Link } from 'react-router-dom';
import SortableTable from '../Table/SortableTable';
import DropDown from '../DropDown';
import { useState } from 'react';
import StockScreenerSearch from './StockScreenerSearch';
 
function StockScreener() {
    const sector = 'Healthcare'
    const exchange = 'NYSE'
    const [contentLower, setContentLower] = useState('')
    const [sectorSelection, setSectorSelection] = useState('');
    const [dividend, setDividend] = useState(0);
    const [price, setPrice] = useState(0)
    
    const handleSelect = (option) => {
        setSectorSelection(option);
    };
    const options = [
        { label: 'Basic Materials', value: 'Basic Materials'},
        { label: 'Communication Services', value: 'Communication Services'},
        { label: 'Consumer Cyclical', value: 'Consumer Cyclical'},
        { label: 'Consumer Defensive', value: 'Consumer Defensive'},
        { label: 'Energy', value: 'Energy'},
        { label: 'Healthcare', value: 'Healthcare'},
        { label: 'Financial', value: 'Financial'},
        { label: 'Financial Services', value: 'Financial Services'},
        { label: 'Industrial Goods', value: 'Industrial Goods'},
        { label: 'Industrials', value: 'Industrials'},
        { label: 'Real Estate', value: 'Real Estate'},
        { label: 'Services', value: 'Services'},
        { label: 'Technology', value: 'Technology'},
        { label: 'Utilities', value: 'Utilities'},
    ];
    const handleSearch = () => {
        //console.log('in handlesearch: ', sectorSelection);
        const dataToSend = {sectorSelection, dividend, price}
        setContentLower(<StockScreenerSearch data2 = {dataToSend} />)
    }
 
    return (
        <div>
            <div>
                <label>Select sector</label>
                <DropDown options = {options} selection = { sectorSelection } onSelect = {handleSelect} />
            </div>
            <div>
                <label>Dividend higher than:</label>
                <input name = 'div'  value={dividend || ''} onChange = {(e) =>  {setDividend(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500' type='number' placeholder='0'>
                </input>
            </div>
            <div>
                <label>Price higher than:   </label>
                <input name = 'pri'  value={price || ''} onChange = {(e) =>  {setPrice(e.target.value)}} className = 'bg-blue-50 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-blue-500' type='number' placeholder='0'>
                </input>
            </div>
           
            <button className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600' 
            onClick = {handleSearch} >Search</button>
            {contentLower}
            
        </div>
    )
}

export default StockScreener