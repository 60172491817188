import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const detailsApi = createApi({
    reducerPath: 'details',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://nodejs.mydividendcompagnon.com"
    }),
    endpoints(builder) {
        return {

            getCompetitors: builder.query({
                query: (arg) => {
                    //const {ticker} = arg;
                    console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/competitors',
                        params: {
                            ticker: arg,                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),  
              
              getScreener: builder.query({
                query: (arg) => {
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/stockscreener',
                        params: {
                            divmorethan: arg[0],
                            sector: arg[1],
                            pricemorethan: arg[2]                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),

              getNews: builder.query({
                query: (arg) => {
                    //const {ticker} = arg;
                    //console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/news',
                        params: {
                            ticker: arg,                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),

              getPricePercentage: builder.query({
                query: (arg) => {
                    //const {ticker} = arg;
                    //console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/pricepercentage',
                        params: {
                            ticker: arg,                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),

              getStockAnalysis: builder.query ({
                query: (arg) => {  
                      
                    return {
                        url: '/analysis',
                        params: {
                           ticker: arg
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,


            getStockAnalysis_2: builder.query ({
                query: (arg) => {                 
                    return {
                        url: '/Analysis_2',
                        params: {
                           ticker: arg
                        },
                        method: 'GET'
                    };
                    
                },
            }) ,



            getPriceHistory: builder.query({
                query: (arg) => {
                    return {
                        url: '/pricehistory',
                        params: {
                            ticker: arg[0],
                            from: arg[1],
                            to: arg[2],                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),

              getDividends: builder.query({
                query: (arg) => {
                    //const {ticker} = arg;
                    console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/dividends',
                        params: {
                            ticker: arg,                   
                        },
                        method: 'GET',
                        
                    };
                }
              }),

              getStockDetails: builder.query({
                query: (arg) => {
                    //const {ticker} = arg;
                    console.log('arg: ', arg)
                    return {
                        //invalidatesTags:['stocksbypfid'],
                        url: '/profile',
                        params: {
                            ticker: arg,
                            //from: arg[1],
                            //to: arg[2],                    
                        },
                        method: 'GET',
                        
                    };
                }
              }),
            



        };
    },
});

export const { useGetDividendsQuery, useGetStockDetailsQuery, useGetPriceHistoryQuery, useGetStockAnalysisQuery, useGetStockAnalysis_2Query, useGetPricePercentageQuery, useGetScreenerQuery, useGetNewsQuery } = detailsApi;
export { detailsApi };