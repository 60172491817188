import GetHistorySingleOne from "./GetHistorySingleOne";
import { useState} from 'react'; 
import { useDeleteEntryMutation } from '../../store';

function DeleteInModal({ticker, portfolio,werktSluiten, totalAmount}) {
    const ListOfTickers = GetHistorySingleOne(portfolio.ietske);
    const [toBeDeleted, setToBeDeleted] = useState(true);
    let pfName;

   const toAdd = {pfId: portfolio.ietske, ticker: ticker}
  
    //const [updateEntry, results] = useEditEntryMutation();
    const [updateEntry, results] = useDeleteEntryMutation();
    const handleDelete = () => {
        updateEntry(toAdd);
        setToBeDeleted(false)
    };
    if (ListOfTickers){
        pfName = ListOfTickers[0].name
    }
    return (<div>
       { toBeDeleted ? (<div> Are you sure you want to delete {ticker} from {pfName}?
        <div>
            <button onClick = {handleDelete}
            className = 'border rounded-full text-red-600 border-2 border-red-600 px-2 mt-1 hover:text-white hover:bg-red-600'>Yes</button>
        
                </div></div>) : 'deleted' }


                <div className = 'mt-5'>
             <button onClick= {werktSluiten} 
             className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600'>
                Close</button>
                </div>
                <div className = 'mt-5 text-sm text-blue-600'>
                Note: Deleting all {ticker} is irreversible. 
                If you sold all your {ticker} stocks, you better 'Add new Ticker' and enter the negative amount you sold, this will keep your history clean
                </div>

                </div>)
}

export default DeleteInModal