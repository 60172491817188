function DividendBreakdown (array) {
    //console.log(array);
    //console.log(array);
    array = array.data;
   //console.log(array);
    return (
        <div>
        <table className = 'border-separate border-spacing-x-5' >
            <tbody>
            <tr>
                <td>Amount of dividends</td>
                <td className = 'ml-2'>{array.totalStocks}</td>
            </tr>
            <tr>
                <td>Total yield</td>
                <td>{parseFloat(array.totalYield).toFixed(2)}</td>
            </tr>
            <tr>
                <td>Largest dividend</td>
                <td>{array.maxDivTicker} - ${parseFloat(array.maxDiv).toFixed(2)}</td>
            </tr>
            <tr>
                <td> </td>
                <td>{parseFloat(array.max * 100 / array.totalValue).toFixed(2)}%</td>
            </tr>
            <tr>
                <td>Smallest dividend</td>
                <td>{array.minDivTicker} - ${parseFloat(array.minDiv).toFixed(2)}</td>
            </tr>
            <tr>
                <td> </td>
                <td>{parseFloat(array.minDiv * 100 / array.totalYield).toFixed(2)}%</td>
            </tr>
            <tr>
                <td>Biggest yield</td>
                <td>{array.maxYieldTicker} ({parseFloat(array.maxYield*100).toFixed(2)}%) </td>
            </tr>
            <tr>
                <td>Lowest yield</td>
                <td>{array.minYieldTicker} ({parseFloat(array.minYield*100).toFixed(2)}%)</td>
            </tr>
            <tr>
                <td>Smallest sector</td>
                <td>{array.minSector}</td>
            </tr>
            <tr>
                <td>Biggest sector</td>
                <td>{array.maxSector}</td>
            </tr>
            
            </tbody>
        </table>
        
        </div>
    )
}

export default DividendBreakdown