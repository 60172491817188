//import { useFetchSectorsQuery } from '../store';
//import GetSectorInfo from '../components/GetSectorInfo';
//import GetSectorInfoBis from './GetSectorInfoBis';
//import {useEffect, useState, useMemo } from 'react';
//import { useEffect } from 'react';
//import FinalGetSectorInfo from '../components/FinalGetSectorInfo';

function MinMax(newArray, xxx) {
    //console.log('---in MinMax----');
    //console.log('sectors array: ', xxx)
    //console.log('stocks array: ', newArray)
    let end_result_array = newArray.map(e => { 
        const bla = xxx.find((y) => 
            y.ticker === e.ticker);
            return { ...e, sector: bla.sector};
        })
    //console.log('end result ....')
    //console.log('end_result_array = ', end_result_array);
    ///function x (newArray12) {
    ///    let newArray3 = newArray12;
    ///    newArray3 = [...newArray12];
    ///    return newArray3
    ///}
    //console.log(newArray);
    //const newArray2 = useMemo(() => x(newArray), [newArray])
    //let newArrayOG2 = newArray;
      //  newArrayOG2 = [...newArray]
    
    //console.log('59')
    
    //const {result2, loaded2}  = <GetSectorInfoBis />;
    //console.log('looooooooooooooooooooade2');
    //console.log(loaded2);
    //console.log('data sent to GetSectorInfo: ', newArrayOG2)
    
   //useEffect (() = > { const {result, loaded}  = FinalGetSectorInfo(newArrayOG2);}), [])
   //useEffect(() => {
    //async function GetSectors() {
    //    const resultaat = await FinalGetSectorInfo(newArrayOG2);
    //    resultaat.then((result) => {
    //        console.log ('result in nieuwe functie: ', result)
    //        return result
    //})
    //    .catch((error) => {
    //       console.log(error)
    //    })
    //}
   //} , [])
    
    //const result = FinalGetSectorInfo();
    //let loaded = true;
    //let result = [];
    let loaded = true;
    //console.log('result from finalsector: ', result)
    //const {result, loaded}  = <GetSectorInfo data = {newArrayOG2} />;
    let sectors = 
        [{sector: 'Real Estate', value: 0},
         {sector: 'Healthcare', value: 0}, 
         {sector: 'Basic Materials', value: 0}, 
         {sector: 'Financial Services', value: 0}, 
         {sector: 'Consumer Cyclical', value: 0},
         {sector: 'Consumer staples', value: 0},
         {sector: 'Utilities', value:0},	
         {sector: 'Communication Services', value: 0},
         {sector: 'Energy', value: 0},	
         {sector: 'Industrials', value: 0}, 
         {sector: 'Technology', value:0}
        ];
        let sectors2 =[];

    
    
    

    let min= 100000000;
    let minDiv = 10000000;
    let max = 0;
    let maxDiv = 0;
    let totalStocks = 0;
    let totalValue = 0;
    let totalYield = 0;
    let minYield = 1000000;
    let maxYield = 0;
    let minTicker, maxTicker, minDivTicker, maxDivTicker, maxYieldTicker, minYieldTicker = '';
    newArray.forEach((x) => {
        totalStocks = totalStocks + 1;
        totalValue = totalValue + x.price;
        totalYield = totalYield + (x.amount * x.frequency * x.payout);
        //console.log(x);
        if (((x.frequency * x.payout)/(x.price/x.amount)) > maxYield) { 
            //console.log(((x.frequency * x.payout)/(x.price/x.amount)), x);
            maxYield = ((x.frequency * x.payout)/(x.price/x.amount));
            maxYieldTicker = x.ticker;
        }
        if (((x.frequency * x.payout)/(x.price/x.amount)) < minYield) { 
            minYield = ((x.frequency * x.payout)/(x.price/x.amount));
            minYieldTicker = x.ticker;
        }
        if (x.price > max) { 
            max = x.price;
            maxTicker = x.ticker;
        }
        if (x.price < min) { 
            min = x.price;
            minTicker = x.ticker;   
        }
        if ((x.payout * x.amount * x.frequency) > maxDiv) { 
            maxDiv = x.payout * x.amount * x.frequency;
            maxDivTicker = x.ticker;
        }
        //console.log('mindiv = ', minDiv)
        if ((x.payout * x.amount * x.frequency) < minDiv) { 
            minDiv = x.payout * x.amount * x.frequency;
            //console.log(minDiv);
            minDivTicker = x.ticker;   
            //console.log('minDiv ticker: ', minDivTicker)
        }
    })
    
    
    //console.log('61')
    //console.log(loaded);
    //console.log(result);
    let maxValueSector = 0;
    let minValueSector = 1230000000;
    let maxSector = '';
    let minSector = '';
    let totalSectors = 0;
    if(!loaded){
        console.log('waiting for data in MinMax')
    }
    if(loaded) {
        //console.log('result = ',end_result_array); 
        //console.log('loaded is okaaaaay'); 
        //console.log(sectors);
        sectors2 = sectors.map(e => { 
            //console.log(e.sector);
            const iets = end_result_array.filter((y) => 
                
                y.sector === e.sector);
                //console.log(iets);
                let total=0;
                iets.forEach((z) => {
                    total = total + z.price;
                })
                
                //console.log('totale waarde:' , total)
                return { ...e, value: total};
            })
        //console.log('in MinMax - Sectors2: ',sectors2);

        
        sectors2.forEach((x) => {
            //console.log(x.sector, x.value);
            //totalStocks = totalStocks + 1;
            //totalValue = totalValue + x.price;
            //totalYield = totalYield + (x.amount * x.frequency * x.payout);
            //console.log(x.payout * x.amount);
            if (x.value > 0) {
                totalSectors = totalSectors +1;
            }
            if (x.value > maxValueSector) { 
                maxValueSector = x.value;
                maxSector = x.sector;
            }
            if ((x.value < minValueSector) && (x.value > 0)) { 
                minValueSector = x.value;
                minSector = x.sector;
            }
            
        })
    }
    
    
    //console.log(min, minTicker, max, maxTicker, minDiv, minDivTicker, maxDiv, maxDivTicker, totalYield, totalValue);
    //const ccc = {min, minTicker, max, maxTicker, minDiv, minDivTicker, maxDiv, maxDivTicker, totalYield, totalValue};
    //console.log(ccc);
    return {min, minTicker, max, maxTicker, minDiv, minDivTicker, maxDiv, maxDivTicker, totalYield, totalValue, totalStocks, 
        maxSector, maxValueSector, minSector, minValueSector, totalSectors, maxYield, maxYieldTicker, minYield, minYieldTicker, sectors2};

}

export default MinMax