import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { useState } from 'react';

function Email (user) {
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);

    const handleNewsletter = () => {
        setIsChecked1(!isChecked1);
    }
    const handleChange = () => {
        setIsChecked2(!isChecked2);
    }
    const handlePayment = () => {
        setIsChecked3(!isChecked3);
    }
    const handleUpdates = () => {
        setIsChecked4(!isChecked4);
    }
    console.log('user = ', user)
    return (
        <div className = 'mt-5 flex flex-column rounded-lg bg-white shadow-md px-5 py-3'>
            <div className = 'basis-1/3'>
                <div >
                    <div className= 'text-blue-600 font-bold'>
                        Email Settings
                    </div>
                    <div>Select what mail you want to receive</div>
                </div>
            </div>
            <div className = 'basis-2/3 border-neutral-600 border-l-1'>
                <div className = 'flex flex-column'>
                    <div className = 'flex items center -ml-1 mr-2'>
                    <button onClick = {() => handleNewsletter()}>
                    {(isChecked1) ? <div><BiCheckboxChecked size = {22} /></div>: <div><BiCheckbox size = {22}/></div>}
                    </button>
                    </div>
                    <div>Newsletter</div>
                </div>
                <div className = 'flex flex-column'>
                    <div className = 'flex items center -ml-1 mr-2'>
                        <button  
                        onClick = {() => handleChange()}>
                        {(isChecked2) ? <div className = 'md:mt-2'><BiCheckboxChecked size = {22} /></div>: <div><BiCheckbox size = {22}/></div>}
                        </button>
                    </div>
                    <div>Change in dividend</div>
                    
                </div>
                <div className = 'flex flex-column'>
                    <div className = 'flex items-start  -ml-1 mr-2'>
                        <button  
                        onClick = {() => handlePayment()}>
                        {(isChecked3) ? <div className = 'mt-2'><BiCheckboxChecked size = {22} /></div>: <div><BiCheckbox size = {22}/></div>}
                        </button>
                    </div>
                    <div>New ex-dividend and payment day</div>
                    
                </div>
                <div className = 'flex flex-column'>
                    <div className = 'flex items-start -ml-1 mr-2'>
                        <button  
                        onClick = {() => handleUpdates()}>
                        {(isChecked4) ? <div className = 'mt-2'><BiCheckboxChecked size = {22} /></div>: <div><BiCheckbox size = {22}/></div>}
                        </button>
                    </div>
                    <div>New updates on MyDividendCompagnon</div>
                    
                </div>
            </div>
        </div>
    )
}

export default Email