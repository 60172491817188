import { useState } from 'react'; 
import Modal from './ModalNew';
import AddNewTicker from './AddNewTicker'

function TestPage({pf_id}) {
        const [showModal, setShowModal] = useState(false);
        const handleClick = () => {
          setShowModal(true);
        };
      
        const handleClose = () => {
          console.log('cllciked on close');
          console.log('llll');
          setShowModal(false);
        };
      
        const actionBar = <div><button onClick = {handleClose} >X</button>
                                
                        </div>;
        const modal = (<Modal Sluiten = {handleClose} >
              <div>
                  <AddNewTicker portfolio= {pf_id} Sluiten = {handleClose} />
              </div>
              </Modal>
        );
          
          return (
          <div>
              <button className = 'border rounded-full text-neutral-500 border-2 border-neutral-500 px-2 ml-5 ' onClick = {handleClick} > + Add Ticker</button>
              {showModal &&  modal}
              
          </div>
          );

} 

export default TestPage