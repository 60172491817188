import {  ElementsConsumer, CardElement, CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js"
import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';

function CheckoutForm () {
    const {id} = useParams();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [priceId, setPriceId] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        setPriceId('price_1N0dzuEmOCxfDzgdgj81UscT')
    }, []);

    const createSubscriptionBis = async(e) => {
        e.preventDefault();
        console.log('email: ',email);

        const response = await fetch('http://127.0.0.1:8800/subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    //paymentMethod: paymentMethod.id,
                    name,
                    email,
                    priceId
                }),
            }).then((res) => res.json());
            console.log(response);
            console.log(response.clientSecret);


            console.log('payment');
            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: name,
                    email: email
                },
            });
            console.log(paymentMethod);

            const confirm = await stripe.confirmCardPayment(response.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: name
                    }
                }
            })
            .then((result) => {
                if (result.error) {
                    alert(result.error.message)
                } else {
                    console.log('transaction suceeded succesfully!!!!')
                }
            });
            console.log(confirm);



    }


    const test = {
        layout: 'tabs'
    }
    return (
    <div>CheckoutForm
        <form>
        <input
            placeholder = "dont change"
            type = 'text'
            //value = {name}
            onChange = {(e) => setPriceId('price_1N0dzuEmOCxfDzgdgj81UscT')}
        />
        <br /> 
        <input
            placeholder = "name"
            type = 'text'
            value = {name}
            onChange = {(e) => setName(e.target.value)}
        />
        <br />
        <input
            placeholder = "email"
            type = 'text'
            value = {email}
            onChange = {(e) => setEmail(e.target.value)}
        />
        
        <CardElement  />
        <PaymentElement options = {test} />
       
        <button onClick = {createSubscriptionBis} disabled ={!stripe}>
            Subscribe
        </button>
        
        </form>
        and bottom
    </div>)

}

export default CheckoutForm