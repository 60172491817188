import classNames from 'classnames';

function Panel({children, className, ...rest }) {
    const finalClassNames = classNames(
        'border rounded p-1 shadow bg-white w-full border-blue-200',
        className
    );

    return <div {...rest} className = {finalClassNames}>{children}</div>

}  

export default Panel;