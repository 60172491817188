import GetHistoryBigOne from "./GetHistoryBigOne";
import GetHistorySingleOne from "./GetHistorySingleOne";
import SortableTable2 from '../Table/SortableTable2';

function History({user_id, which}) {
    //console.log('in history, user_id: ', user_id);
    let ttt;
    if (which === 'Big'){
    ttt = GetHistoryBigOne(user_id);
    //console.log('in big history, user_id: ', user_id);
    //console.log('in big history, which: ', which);
        }
    if (which === 'Small'){
        ttt = GetHistorySingleOne(user_id);
        //console.log('in singlehistory, pf_id: ', user_id);
        //console.log('in single history, which: ', which);
            }

    const config = [
        { label: 'Ticker',
        render: (xx) => xx.ticker,
        sortValue: (xx) => xx.ticker},
        { label: 'Amount',
        render: (xx) => xx.amount},
        { label: 'Price',
        render: (xx) => xx.buy_price},
        { label: 'Portfolio',
        render: (xx) => xx.name,
        sortValue: (xx) => xx.name
        },
        { label: 'Day',
        render: (xx) => xx.date,
        sortValue: (xx) => xx.date}

    ]


    const keyFn = (iets) => {
        return iets.id
    }
    let contentDown = ''
    //console.log('before if ttt, ttt = ', ttt)
    if (ttt) {
        
         contentDown =  <SortableTable2 content2 = {ttt} config ={config} keyFn = {keyFn} value = 'NOK' />
         //contentDown = {renderedRows}
         //console.log('yeeeaaah, er is iets');
         }
    //console.log('ttt in History is: ', ttt)
    return <div>Buy and sell history
       {contentDown}
    </div>
}

export default History