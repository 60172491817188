import { useState} from 'react';
import GetDetails from '../components/Details/StockDetails';
import DetailsLowerPart from '../components/Details/DetailsLowerPart';
import AnalysisLowerPart from '../components/Details/AnalysisLowerPart';
import DividendsLowerPart from '../components/Details/DividendsLowerPart';
import NewsLowerPart from '../components/Details/NewsLowerPart';
import { useParams } from 'react-router-dom';
import Competitors from '../components/Details/Competitors';
import SidebarSmall from '../components/SidebarSmall';
import Footer from '../components/footer';


function DetailsPage() {
    const {id} = useParams();
    //console.log('in detailspage, id = ' ,id);


    //const id = 'KMI';
    //let dataArray = '';
    let upperPart = '';
    let lowerPart = '';

    //const [currentPrice, setCurrentPrice] = useState('');
    //const [symbol, setSymbol] = useState('');
    //const [name, setName] = useState('');
    const [loaded, setLoaded] = useState(false);
    //const [contentLower, setContentLower] = useState('');
    const [lowerPart2, setLowerPart2] = useState('Data is gathering ...')
    const [activeButton, setActiveButton] = useState('info');
    const [prijs, setPrijs] = useState()

   
    
    const data = GetDetails({id});
    let dataPrice;
    
    //console.log(data);

    if (data && !loaded) {
        dataPrice = data['price'];
    //console.log('dataPrice = ', dataPrice)
        //setPrijs(dataPrice);
        //let newArrayOG = data;
    
       lowerPart =DetailsLowerPart(data);     
        
    }
    function im(iets) {
        const name = iets;
        const x = 'https://financialmodelingprep.com/image-stock/';
        let y = x.concat(name).concat('.png')
        return y}

    if(data) {
        //setPrijs(dataPrice);
        upperPart = (
        <div className = 'flex '>
            <img alt = 'rrr'  src = {im(data['symbol'])}  className = "bg-neutral-100 rounded-lg object-cover w-16 h-16  mr-2" />
           <div> 
                <div className = 'font-semibold text-neutral-800 -mt-2'>{data['symbol']}</div>
                <div className = 'font-semibold text-neutral-600'>{data['companyName']}</div>
                <div>{data['price']}</div>
           </div>
        </div>);
        
    }
    

    const handleClickAnalysis = (value) => {
        setLoaded(true);
        setActiveButton('analysis')
        setLowerPart2(<AnalysisLowerPart id = {id} price = {dataPrice} />)

        //console.log('why');
    };
    const handleClickDividends = (value) => {
        setActiveButton('dividends')
        setLoaded(true);
        setLowerPart2(<DividendsLowerPart x = {id} price = {dataPrice}/>)
        //console.log('why');
    };
    const handleClickNews = (value) => {
        setActiveButton('news')
        setLoaded(true);
        setLowerPart2(<NewsLowerPart x = {id} price = {dataPrice}/>)
        //console.log('why');
    };
    const handleClickCompetitors = (value) => {
        setActiveButton('competitors')
        setLoaded(true);
        setLowerPart2(<Competitors x = {id} price = {dataPrice}/>)
        //console.log('why');
    };
    const handleClickInfo = (value) => {
        setActiveButton('info')
        setLoaded(false);
        setLowerPart2('')
    };
    
    return (
    <div>
        <div className = 'md:invisible'>
        <SidebarSmall />
        </div>
        <div className = 'rounded-lg bg-white shadow-md mt-10 md:mt-5 px-5 py-3 mb-4 my-2 mr-5'>
        {upperPart}
        </div>
        <div> 
            <div className = 'shadow-md rounded-lg flex flex-row bg-blue-600 content-center space-x-5 py-5  mb-5 px-5 my-2 mr-5'>
            <button onClick= {() => handleClickInfo()}
                    className = {activeButton === 'info' ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}
                    >
                        Info 
            </button> 
            <button onClick= {() => handleClickAnalysis()} 
                    className = {activeButton === 'analysis' ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}>
                        Analysis
            </button> 
            <button onClick= {() => handleClickDividends()}
                    className = {activeButton === 'dividends' ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}>
                        Dividends
            </button>
            <button onClick= {() => handleClickCompetitors()}
                    className = {activeButton === 'competitors' ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}>
                        Competitors
            </button>
            <button onClick= {() => handleClickNews()}
                    className = {activeButton === 'news' ? 'rounded-full bg-blue-800 text-neutral-200' : 'rounded-full text-neutral-200 hover:text-neutral-200 hover:bg-blue-800'}>
                        News
            </button>
            </div>
        </div>
        
            {lowerPart}
            {lowerPart2}
             

        <Footer />
    </div>
    )
}

export default DetailsPage