//import GetSectorInfo from '../helperFunctions/GetSectorInfo';
//import axios from 'axios';
import { useState, useEffect } from 'react'; 
import { useFetchTickersQuery } from '../../store';
import { useAddTickerToPortfolioMutation } from '../../store';
 
function AddNewTicker({portfolio, Sluiten}) {
        let today = new Date();
    let thisDay = today.getDate();
    let thisMonth = today.getMonth()+1;
    let thisYear = today.getFullYear();
        const { data, error, isLoading } = useFetchTickersQuery();
        const [tickermatch, setTickermatch] = useState([]);
        
        //const [ticker, setTicker] = useState('');
        const [searchTerm, setSearchTerm] = useState('');
        const [number, setNumber] = useState('');
        const [price, setPrice] = useState('');
        const [year, setYear] = useState(thisYear);
        const [month, setMonth] = useState(thisMonth);
        const [day, setDay] = useState(thisDay);
        const [errorMessage, setErrorMessage] = useState('');

        let toShow = false;
    
    const [addTicker, results] = useAddTickerToPortfolioMutation();
    const [user, setUser] = useState({});

    useEffect (() => {
      const theUser = localStorage.getItem('user');
      if (theUser && !theUser.includes('undefined')){
        setUser(JSON.parse(theUser));
      }
  },[])
  let xxx = year+'-'+month+'-'+day;
  const toAdd = {pfId: portfolio, ticker: searchTerm, amount: number, buy_price: price, userId: user.userid, buyDate: xxx};
    //console.log(results.isSuccess);
 
 console.log('xxx = ', xxx);
        
        //let renderitems = '';
        let tickerNames = []
        if (isLoading) {
          //console.log('-------sector----Loading---');
            
        } else if (error) {
           // console.log('-------sector-----Error--');
            //loaded = false;        
        } else if (data) {
                //console.log(data[0]);
            tickerNames = [...data];
        };

        const search = (text) => {
                console.log('tekst: ',text);
                if (text.length > 0 ) {
                const str = text;
                setSearchTerm(text)
                
                let matches = tickerNames.filter((ticker) => {
                        const regex = new RegExp(str, "gi");
                        //console.log(ticker.ticker.match(regex))
                        return ticker.ticker.match(regex)
                        })
                        setTickermatch(matches)
                } else if (text.length < 1){
                        setTickermatch('')
                        setSearchTerm('')
                }       
                }
        const selectHandler = (x) => {
                setTickermatch('')
                //console.log('selected :', x);
                setSearchTerm(x);                
        }
        const addNumber = (y) => {
                setNumber(y)
                console.log(y)
        }
        const addPrice = (z) => {
                setPrice(z)
                console.log(z)
        }
        const addYear = (z) => {
                setYear(z)
                console.log(z)
        }
        const addMonth = (z) => {
                setMonth(z)
                console.log(z)
        }
        const addDay = (z) => {
                setDay(z)
                console.log(z)
        }

        let testresult = true
        const handleAddClose = () => {
                let test = tickerNames.filter((ticker) => {
                        return ticker.ticker.match(toAdd.ticker)
                });
                if (test.length < 1) {
                        console.log('Please select ticker from dropdown list')
                        setErrorMessage('Please select ticker from dropdown list')
                        return
                }
                if (isNaN(toAdd.amount)) {
                        console.log('entered amount is not a number')
                        setErrorMessage('The entered amount is not a number')
                        return
                } 
                if (isNaN(toAdd.buy_price)) {
                        console.log('entered amount is not a number')
                        setErrorMessage('The entered price is not a number')
                        return
                }
                addTicker(toAdd);
                setPrice('');
                setSearchTerm('');
                setNumber('');
                setErrorMessage('');
                Sluiten();
            }
        const handleAdd = () => {
                let test = tickerNames.filter((ticker) => {
                        return ticker.ticker.match(toAdd.ticker)
                });
                if (test.length < 1) {
                        console.log('Please select ticker from dropdown list')
                        setErrorMessage('Please select ticker from dropdown list')
                        return
                }
                if (isNaN(toAdd.amount)) {
                        console.log('entered amount is not a number')
                        setErrorMessage('The entered amount is not a number')
                        return
                } 
                if (isNaN(toAdd.buy_price)) {
                        console.log('entered amount is not a number')
                        setErrorMessage('The entered price is not a number')
                        return
                }
                addTicker(toAdd);
                setPrice('');
                setSearchTerm('');
                setNumber('');
                setErrorMessage('');
                
            }
        if (tickermatch.length > 0){
                //console.log('there is something');
                toShow =true;
        }

        return (
            <div>
                <div className = 'flex md:gap-5'>
                <div><div>
                        Please enter ticker:
                </div>
                <div>
                        <input placeholder = 'Search ticker'
                                value = {searchTerm}
                                onChange = {(e) => search(e.target.value)}
                        />                                        
                        {toShow ? (
                                <div className = "h-48 overflow-y-scroll w-60">
                                        {tickermatch && tickermatch.map((item,index) => {
                                                return (
                                                        <div  >
                                                                <div onClick = {() =>{selectHandler(item.ticker)}}> 
                                                                        <button >
                                                                                <div className = 'text-left'>{item.ticker}</div>
                                                                                <div className ='text-xs text-left'>
                                                                                  {item.name}
                                                                                </div>
                                                                        </button>
                                                                </div>
                                                                
                                                        </div>
                                                )       
                                        })}
                                </div>) : ''}
                 </div> </div>
                                
                <div><div>
                       <div> Number of stocks:</div>
                        <div><input 
                                className = 'border border-2 border-neutral-200 rounded-full'
                                placeholder = ' Number of stocks'
                                value = {number}
                                onChange = {(e) => addNumber(e.target.value)}
                        /></div>
                </div>
                <div>
                        <div>Cost per share:
                        </div>
                        <div><input 
                                className = 'border border-2 border-neutral-200 rounded-full'
                                placeholder = ' Cost per share'
                                value = {price}
                                onChange = {(e) => addPrice(e.target.value)}
                        />
                        </div>
                </div>

                <div>
                        <div>Date of purchase:
                                                </div>
                        <div className = 'flex flex-row'>
                                <div className = 'grid grid-cols-1'>   
                                        <div>YYYY</div>
                                        <div><input 
                                        className = 'placeholder:text-red-400 pl-2 w-14 border border-2 border-neutral-200 rounded-full'
                                        placeholder = {year}
                                        value = {year}
                                        onChange = {(e) => addYear(e.target.value)}
                                />
                                </div>
                                </div>
                                <div className = 'mx-2 grid grid-cols-1'>
                                           MM
                                        <input 
                                        className = 'pl-2 w-9 border border-2 border-neutral-200 rounded-full'
                                        placeholder = {thisMonth}
                                        value = {month}
                                        onChange = {(e) => addMonth(e.target.value)}
                                />
                                </div>
                                <div className = 'grid grid-cols-1'>   DD
                                        <input 
                                        className = 'pl-2 w-9 border border-2 border-neutral-200 rounded-full'
                                        placeholder = {thisDay}
                                        value = {day}
                                        onChange = {(e) => addDay(e.target.value)}
                                />
                                </div>
                        </div>
                </div>

                
                </div></div>
                <div className = 'text-red-600 text-xs'>{errorMessage}</div>
                        <div className = 'flex flex-col'>
                                <div className = 'flex flex-row'>
                                <div >
                                <button className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600' 
                                                onClick = {handleAdd}>
                                        add Ticker & add new
                                </button>
                                </div>
                                <div>
                                <button className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600' 
                                                onClick = {handleAddClose}>
                                        add Ticker & close
                                </button>
                                </div>
                        </div>
                <div>
                <button onClick= {Sluiten} 
                                className = 'border rounded-full text-blue-600 border-2 border-blue-600 px-2 mt-1 hover:text-white hover:bg-blue-600'>
                        Close
                </button>
                </div>
                </div>
                                
                       
        </div>
        )

}

export default AddNewTicker