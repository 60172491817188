import { Fragment } from 'react';
//import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { editValue } from '../../store';

function CreateTable({data, config, keyFn, value}) {
 
    const dispatch = useDispatch();
    
        const waarde = value;
        const action = editValue(waarde);
        useEffect(() => {dispatch(action);});
    

    //const x = 12345689
    //const action2 = editYesterday(x);
   // useEffect(() => {dispatch(action2);});


    //console.log(data);

    const headers = config.map((column) => {
       
        if (column.header) {
            return <Fragment key = {column.label}  >{column.header()}</Fragment>
        }
       
        return <th key ={column.label} >
                   {column.label}
            </th>
    }); 

    const renderedRows = data.map((rowData) => {
        const renderedCells = config.map((column) => {
            //console.log('column = ', column)
            //console.log('header = ', column.header)
            if (column.label === 'Ticker'){
                console.log('we are in === ticker')
                return <td key = { column.label} className = 'sticky left-0 bg-white text-sm text-left  border-b border-blue-200 '>{column.render(rowData)}</td>
            }
            return <td key = { column.label} className = 'text-sm text-left  border-b border-blue-200 '>{column.render(rowData)}</td>

        });
            //console.log(renderedCells)
            return (<tr key={keyFn(rowData)}
                    className = 'even:bg-neutral-50'>
                {renderedCells}
                </tr>)
        })  
        
    return (
        <table className='min-w-full max-width-md divide-y '>
            <thead>
                <tr key = 'werty' className = 'max-w-20 text-left'>
                    {headers} 
                </tr>
            </thead>
            <tbody>
                {renderedRows}
            </tbody>
        </table>

    ) ;

}

export default CreateTable