import { useGetStockAnalysisQuery } from '../../store';
import StockAnalysis from './StockAnalysis';
import StockAnalysis_2 from './StockAnalysis_2';
import StockAnalysisGraph from './StockAnalysisGraph';
import GetDetails from './StockDetails';
 
function AnalysisLowerPart(id, price) {
    //console.log('in AnalysisLowerPart, id.x = ', id.id);
    //console.log('in AnalysisLowerPart, id = ', id);
    //console.log('price ... :', id.price)
    const WhyNot = StockAnalysis_2(id.id);
    //console.log('in Analysis, rr_part2: ',WhyNot)
    const rr = StockAnalysis(id.id);
    //console.log('in Analysis, rr: ',rr)
    const rr_part2 = StockAnalysis_2(id.id);
    //console.log('in Analysis, rr_part2: ',rr_part2)
    const lowerPart = <div>lower</div>;
    //const data = GetDetails({id});
    const data2 = GetDetails(id);
    let dataPrice;
    //console.log('data2 = ', data2)
    //console.log(data);

    if (data2) {
        dataPrice = data2['price'];
    //console.log('dataPrice = ', dataPrice)
        //setPrijs(dataPrice);
        //let newArrayOG = data;
    
       //console.log('dataprice = ', dataPrice);     
        
    }
     
    if(rr && rr_part2){
        function twoDigits(number) {
            return parseFloat(number).toFixed(2)
        }
        return (
        <div >
            <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-2'>
                                    General
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Price
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {dataPrice}$
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Dividend Yield
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                                {twoDigits(rr['dividendYielPercentageTTM'])}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Payout Ratio
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr['payoutRatioTTM']*100)}
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-2'>
                                    Valuation Ratios
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Price to Equity (P/E)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr['peRatioTTM'])}
                            </div>
                            <div className = 'text-center mt-2'>
                                Price to Free Cash Flow (P/FCF)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr['priceToFreeCashFlowsRatioTTM'])}
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center' >
                                Price to Sales (P/S)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                                {twoDigits(rr['priceToSalesRatioTTM'])}
                            </div>
                            <div className = 'text-center mt-2'>
                                Price to Earnings to Grow (PEG)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                                {twoDigits(rr['pegRatioTTM'])}
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Price to Book (P/B)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr['priceToBookRatioTTM'])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-2'>
                                    Financial Ratios
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Current
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr['currentRatioTTM'])}
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Quick
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                                {twoDigits(rr['quickRatioTTM'])}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Debt to Equity
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr['debtEquityRatioTTM'])}
                            </div>
                        </div>

                    </div>

                </div>

                
            </div>


            <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-2'>
                                    Financial Efficiency ratios
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Return on Equity (RoE)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr['returnOnEquityTTM']*100)}%
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Return on Assets (RoA)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {twoDigits(rr['returnOnAssetsTTM']*100)}%
                            </div>
                            
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Return on InvestedCapital (ROIC)
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr_part2['roicTTM']*100)}%
                            </div>
                        </div>

                    </div>

                </div>
                </div>


                <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-2'>
                                    Enterprise Ratios
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/3'>
                            <div className = 'text-center'>
                                EV/Sales
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr_part2['evToSalesTTM'])}
                            </div>
                            <div className = 'text-center mt-2'>
                                EV/EBITDA
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr_part2['enterpriseValueOverEBITDATTM'])}
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                EV/Earnings
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                                {twoDigits(rr_part2['evToSalesTTM'])}
                            </div>
                            <div className = 'text-center mt-2'>
                                EV/EBIT
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr_part2['evToOperatingCashFlowTTM'])}
                            </div>
                            
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                EV/FCF
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                                {twoDigits(rr_part2['evToFreeCashFlowTTM'])}
                            </div>
                        </div>

                    </div>

                </div>
                </div>   


                <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <div className = 'flex flex-col justify-around'>
                    <div className = 'text-center text-blue-600 font-bold text-lg'> 
                    <button 
                disabled = {true}
                className = 'border rounded-full border-2  border-blue-600 px-2 mb-2'>
                                    Margins
                                </button>
                    </div>
                    <div className = 'flex justify-around'>
                        <div  className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Gross Margin
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr['grossProfitMarginTTM']*100)}%
                            </div>
                            <div className = 'text-center mt-2'>
                                Net Profit Margin
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr['netProfitMarginTTM']*100)}%
                            </div>
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Operational Margin
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'> 
                            {twoDigits(rr['operatingProfitMarginTTM']*100)}%
                            </div>
                            <div className = 'text-center mt-2'>
                                Tax Rate
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr['effectiveTaxRateTTM']*100)}%
                            </div>
                            
                        </div>
                        <div className = 'basis-1/3'>
                            <div className = 'text-center'>
                                Pretax Margin
                            </div>
                            <div className = 'text-center text-blue-600 font-bold text-lg'>
                            {twoDigits(rr['pretaxProfitMarginTTM']*100)}%
                            </div>
                        </div>

                    </div>

                </div>
                </div> 

            
            <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                <StockAnalysisGraph analysisData = {id.id} y_legend = 'close' />
            </div>
        </div>
            )
    }

    

    
}

export default AnalysisLowerPart;