import { useFetchStocksAndPricesQuery } from '../../store';
import CleanupArray from '../Portfolios/CleanupArray';
import CreateCalendar from './CreateCalendar';


function CalendarBigOne ({name}) {
       

        console.log(name);
        const { data, error, isLoading } = useFetchStocksAndPricesQuery(name.id);
        //console.log(data)
        let content_down;
        if (isLoading) {
            content_down = 'loading data ...';
            
        } else if (error) {
            content_down = 'error ...';
            
        } else if (data) {
            //console.log('nu ok');
            let newArrayOG = data;
            //console.log(data);
            newArrayOG = [...data]
            const { cleanArray, total2} = CleanupArray(newArrayOG);
            //console.log(cleanArray) ;


            content_down = <CreateCalendar data = {cleanArray} value = {total2} user = {name.id} type = 'big' />
        } 
      
    return <div >{content_down}</div>
}
export default CalendarBigOne