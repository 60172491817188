import { useAddPortfolioMutation } from '../../store';
import { useEffect, useState } from 'react';

function AddPortfolio({Sluiten}) {
    const [user, setUser] = useState({});
    const [addPortfolio, results] = useAddPortfolioMutation();
    const [portfolioName, setPortfolioName] = useState('');
    const [errorMessage, setErrorMessage] = useState('')


    useEffect (() => {
      const theUser = localStorage.getItem('user');
      if (theUser && !theUser.includes('undefined')){
        setUser(JSON.parse(theUser));
      }
  },[]);

    const name = {userId: user.userid, name: portfolioName};

    const handleAdd = () => {
      if (portfolioName !==''){
        addPortfolio(name);
        Sluiten()}
      if (portfolioName ===''){
        setErrorMessage('Portfolio name cannot be emty')
      }
    }
    const addName = (y) => {
      setPortfolioName(y)
      console.log(y)
}

    return (<div>
      <div className = 'items-center justify-center flex flex-col'>
        <div className = 'text-bold text-blue-600 pb-2 '>
          Enter portfolio name
        </div>
        <div>
          <input placeholder = 'New portfolio'
                                value = {portfolioName}
                                onChange = {(e) => addName(e.target.value)}
                                className = 'border-2 border-blue-800 p-1 rounded-md'
                        />
                        
          
                        
        </div>
        <div className = 'text-red-600 pb-2 '>
          {errorMessage}
          </div>
        
          <button onClick = {handleAdd} className = 'mt-10 p-1 rounded-md bg-blue-800 text-white'>add Portfolio</button>
          
          </div>
        </div>)
}

export default AddPortfolio