import CreateTable2 from './CreateTable2';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { editValue } from '../../store';

function SortableTable2 (props) {
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortBy, setSortBy] = useState('Ticker');
    const { config, content2 } = props;
    
  


    const handleClick = (label) => {
        if (sortBy && label !== sortBy) {
            setSortOrder('asc');
            setSortBy(label);
            return;
        }
        if (sortOrder === null) {
            setSortOrder('asc');
            setSortBy(label);
        }
        else if (sortOrder === 'asc') {
            setSortOrder('desc');
            setSortBy(label);
        }
        else if (sortOrder === 'desc') {
            setSortOrder(null);
            setSortBy(null);
        };
    }
    const updatedConfig = config.map((column) => {
        if (!column.sortValue) {
            return column;
        }

        return {
            ...column,
            header: () => (<th className = 'cursor-pointer hover:bg-gray-100' onClick = { () => handleClick(column.label)}>
                    {column.label}
                </th>
        )};
    });

    let sortedData = content2;
 
    
    if (sortOrder && sortBy) {
        const { sortValue } = config.find(column => column.label === sortBy);
        sortedData = [...content2].sort((a,b) => {
            const valueA = sortValue(a);
            const valueB = sortValue(b);

            const reverseOrder = sortOrder === 'asc'? 1 : -1;

            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB) * reverseOrder;
            } else {
                return (valueA - valueB) * reverseOrder;
            }
        });
    }
    //c onst Sortable = FinalCreateTable(sortedData, updatedConfig, keyFn);
    return (<div>
    <CreateTable2 {...props}  data = {sortedData} config = {updatedConfig}  />
</div>)
}

export default SortableTable2