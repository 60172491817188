//import GetStockNews from "./GetStockNews"
import Accordion2 from '../Accordion2';
import { useGetNewsQuery } from "../../store";

function NewsLowerPart(id) {
    console.log(id.x);
    //const data = GetStockNews(id.x)
    //console.log(data);
    const { data, error, isLoading } = useGetNewsQuery(id.x);
    

    if(data){
       //console.log(data[0]['symbol']);
       console.log(data); 
       //let newArrayOG = data;
       //newArrayOG = [...data]
       //return newArrayOG[0];
       const renderedItems = data.map((iets) =>{
        return (
            <div>
                <div className = 'font-semibold' key = {iets.title}>{iets.title}
                </div>
                <div>{iets.text}
                </div>
            </div>
        )
       })
       return <div>
            <div>
            <Accordion2 items = {data} />
            </div>
            </div>
    }
    
}



export default NewsLowerPart