import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Landing from "./Pages/LandingPage";
import Login from "./Pages/LoginStuff/Login";
import Sidebar from './components/Sidebar';
import SidebarSmall from "./components/SidebarSmall";
import Signup from "./Pages/LoginStuff/Signup";
import Home from "./Pages/HomePage";
import PortfoliosPage from "./Pages/PortfoliosPage";
import DiversificationPage from "./Pages/DiversificationPage"
import CalendarPage from './Pages/CalendarPage';
import ToolsPage from "./Pages/ToolsPage";
import CalculatorsPage from "./Pages/CalculatorsPage";
import DetailsPage from './Pages/DetailsPage';
import PricingPage from "./Pages/PricingPage";
import UpcomingPage from "./Pages/UpcomingPage";
import CheckoutForm from "./components/Pricing/CheckoutForm";
import CheckoutPage from "./Pages/CheckoutPage";
import SuccessPayment from './Pages/SuccessPayment';
import StepOne from './components/Pricing/StepOne'
import LogoutPage from "./Pages/LogoutPage";
import ProfilePage from './Pages/ProfilePage';
 



function App() {

  
  const [user, setUser] = useState({});
  useEffect (() => {
    const theUser = localStorage.getItem('user');
    console.log('the user: ', theUser);
    if (theUser && !theUser.includes('undefined')){
      setUser(JSON.parse(theUser));
      console.log(user.email);
    }
},[])
let SideBar = <Sidebar />;
 const SmallScreen = window.matchMedia('(max-width: 768px').matches;
 console.log('smallscreen = :', SmallScreen);
if (SmallScreen) {SideBar = null }

  return (<div><BrowserRouter>
    {user?.email ? (
      <div className = 'container mx-auto grid grid-cols-6 md:gap-4 min-h-screen bg-neutral-100 '>
       {SideBar}
      
        <div className= 'col-span-6 md:col-span-5'> 
          <Routes>
            <Route path = '/' element = {<PortfoliosPage user = {user} />} />
            
            <Route path = '/Portfolios' element = {<PortfoliosPage user = {user} />} />
            <Route path = '/Diversification' element = {<DiversificationPage user = {user}/>} />
            <Route path = '/Calendar' element = {<CalendarPage user = {user}/>} />
            <Route path = '/Tools' element = {<ToolsPage />} />
            <Route path = '/Calculators' element = {<CalculatorsPage />} />
            <Route path = '/Details/:id' element = {<DetailsPage />} />
            <Route path = '/login' element = {<PortfoliosPage user = {user}/>} />
            <Route path = '/signup' element = {<PortfoliosPage user = {user} />} />
            <Route path = '/home' element = {<Home user = {user}/>} />
            <Route path = '/logout' element = {<LogoutPage user = {user}/>} />
            <Route path = '/Profile' element = {<ProfilePage user = {user}/>} />
            
                <Route path = '/Pricing' element = {<PricingPage />} />
                <Route path = '/Checkout' element = {<StepOne user = {user}/>} />
              
              <Route path = '/Ok' element = {<SuccessPayment />} />

            
            <Route path = '/Upcoming' element = {<UpcomingPage />} />
           
          </Routes>
        </div>
      </div>
    ) : (
      <div>
        <Routes>
          <Route path = '/' element = {<Login />} />
          <Route path = '/home' element = {<Login />} />
          <Route path = '/Portfolios' element = {<Login />} />
          <Route path = '/Diversification' element = {<Login />} />
          <Route path = '/Calendar' element = {<Login />} />
          <Route path = '/Tools' element = {<Login />} />
          <Route path = '/Calculators' element = {<Login />} />
          <Route path = '/Details/:id' element = {<Login />} />
          <Route path = '/landing' element = {<Landing />} />
          <Route path = '/login' element = {<Login />} />
          <Route path = '/signup' element = {<Signup />} />
          <Route path = '/Pricing' element = {<Login />} />
          <Route path = '/Upcoming' element = {<Login />} />
          <Route path = '/logout' element = {<Login />} />
          <Route path = '/Profile' element = {<Login />} />
        </Routes>
      </div>

    )
    }
   </BrowserRouter>
    </div>
    );
}

export default App;
