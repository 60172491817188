import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
//import { useFetchDailyValueByUserIdQuery } from '../../store';
import { useDispatch } from 'react-redux';
//import { useEffect, useState } from 'react';
import { editYesterday } from '../../store';
import GetYesterdayValue from '../Diversification/GetYesterdayValue'
//import GetYesterdayValue from '../components/Diversification/GetYesterdayValue'
import GetYesterdayValueIndividual from '../Diversification/GetYesterdayValueIndividual';

function CalendarMonthly(props) {
    const {data, user, type } = props;
    const dispatch = useDispatch();
    console.log('user and type in CalendarMonthly: ', user, type);
    //console.log(data);
    let data2 = data;
    data2=[...data] 
    //const action2 = editYesterday(yydata.at(-1));
    //setTimeout(() => { dispatch(action2)}, 0);
    console.log(data2);
    //console.log(data2[0]);
    //console.log(data2[0][3]);
    let tttt = 0;
    //type = 'big'
    if (type === 'big'){
        //console.log('in type is big');
        tttt = GetYesterdayValue(user);
        console.log('ttt in big is ', tttt)
        const action2 = editYesterday(tttt);
    setTimeout(() => { dispatch(action2)}, 0);
    }
    if (type === 'individual'){
        //console.log('in type is SMALL');
        tttt = GetYesterdayValueIndividual(user);
        console.log('ttt in small is ', tttt)
        const action2 = editYesterday(tttt);
    setTimeout(() => { dispatch(action2)}, 0);
    }
    let list = [];
    for (let rows = 0; rows < data2.length; rows++){
        //console.log(data2[rows]);
        for (let columns = 1; columns < 12; columns++){
           // console.log(data.data[rows][columns]);
            if (data2[rows][columns] !== '-'){
                //console.log('ok');
                list.push({title: data2[rows][0], allday: true,start: '2023-03-04',  end: '2023-03-04' })
            }

        }

    }
    //console.log(list);
    const localizer = momentLocalizer(moment);
    
    return (
        <div className = 'myCustomHeight'>
            Kalender
        <Calendar
            localizer={localizer}
            events = {data2}
            startAccessor = "start"
            endAccessor = "end"
            style = {{height: 500, margin: '50px'}}
        />
        </div>
    )
}

export default CalendarMonthly