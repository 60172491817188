import className from 'classnames';

function Button({
    children,
primary,
secondary,
success,
warning,
danger,
outline,
rounded,
onClick,
}) {
    const classes = className('flex items-center px-2 mt-3 mb-3 border-2', {
        'border rounded-full text-blue-600 border-blue-600  hover:text-white hover:bg-blue-600': primary,
        'border-red-600 bg-red-600 text-white': danger,
        'border-gray-900 bg-gray-900 text-white': secondary,
        'border-yellow-400 bg-yellow-400 text-black': warning,
        'border-green-500 bg-green-500 text-white': success,
        'rounded-full': rounded,
        'bg-white text-black': outline,
        'text-yellow-800': outline && primary,
        'text-red-400': outline && danger,
        'text-green-400': outline && success,

    });
    return <button onClick = {onClick} className = {classes}>{children}</button>;
}

//Button.propTypes = {
//    checkVariationValue: ({ primary, secondary, success, warning, danger }) => {
//        const count = Number(!!primary) + Number(!!secondary) + Number(!!success) + Number(!!warning) + Number(!!danger);    
//        if ( count > 1) { 
//            return new Error('only 1 primary allowed');
//        }
//    }
//}

export default Button;