import { useState } from 'react'; 
import Modal from './ModalNew';
import EditInModal from './EditInModal';
import DeleteInModal from './DeleteInModal';
import {BiEdit, BiTrash} from 'react-icons/bi';

function EditDelete({pf_id, ticker, totalAmount}) {
  //console.log(pf_id.iestke,'Pfid');
        const [showModal, setShowModal] = useState(false);
        const [showModalDelete, setShowModalDelete] = useState(false);
        const handleClick = () => {
          setShowModal(true);
        };
        const handleClickDelete = () => {
          console.log('in handleclickdelete');
          setShowModalDelete(true);
        };
      
        const handleClose = () => {
          console.log('cllciked on close');
          console.log('llll');
          setShowModal(false);
        };
        
        const handleCloseDelete = () => {
          console.log('cllciked on close');
          console.log('llll');
          setShowModalDelete(false);
        };
      
        const actionBar = <div><button onClick = {handleClose} className = '-mt-5 text-sm bg-sky-300'>X</button>
                                
                        </div>;
                        // uit modal = actionBar = {actionBar}
        const modal = (<Modal Sluiten = {handleClose} >
              <div>
                  <EditInModal portfolio= {pf_id}  ticker = {ticker} werktSluiten = {handleClose}/>
              </div>
              </Modal>
        ); 
        
        const modalDelete = (<Modal Sluiten = {handleCloseDelete} >
          <div>
              <DeleteInModal portfolio= {pf_id}  ticker = {ticker} totalAmount = {totalAmount} werktSluiten = {handleCloseDelete}/>
          </div>
          </Modal>
    ); 
          return (
            <div>
          <div>
              <button  onClick = {handleClick} > 
            
              <BiEdit /></button>
              {showModal &&  modal}
              
            &nbsp; / &nbsp;
              <button  onClick = {handleClickDelete} > 
            
              <BiTrash /></button>
              {showModalDelete &&  modalDelete}
              
          </div>
          </div>
          );

}

export default EditDelete