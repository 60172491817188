import { useSelector } from 'react-redux';
//import { editValue } from '../store';
//import { useState } from 'react';


function UpdateUpperValue({sthng}) {
    //let yesterdayValue;
    //const [yesterdayVale, setYesterdayValue] =  useState(0);
    const yes = useSelector((state) =>{
        //console.log(state)
        return state.value[0]
    });
    //const yest erdayValue = 0;
    const yesterdayValue = useSelector((state) =>{
        //console.log(state.yesterday[1])
        if (isNaN(state.yesterday[1])){
            return 0
        }
        return state.yesterday[1]
    });

    let positive = true;
    let difference = yes - yesterdayValue;
    if (difference <= 0.1 && difference >= -0.1) {
        difference = 0;
        //positive = false;

    }
    if (difference < -0.5){
        positive = false;
    }
    return (
    <div>
        Current Value 
        <div className = 'flex gap-2'>
            <div >
                <p className = 'text-blue-600 font-bold text-lg'>${parseFloat(yes).toFixed(2)}</p>
            </div>
            <div className = 'pt-2  font-bold'> 
                <p className = {positive ? 'text-green-600 text-xs align-text-bottom' : 'text-red-500 text-xs align-text-bottom'} >{parseFloat(difference).toFixed(2)}</p>
            </div>
        
    </div>
    </div>)

}

export default UpdateUpperValue;