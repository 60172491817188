import { useFetchKingsAristocratsQuery } from "../../store";
import { Link } from "react-router-dom";
import GetName from "../../HelperFunctions/GetName";
import SortableTable from '../Table/SortableTable';
import { detailsApi } from "../../store/apis/detailsApi";
import UpcomingWeek from './UpcomingWeek';

function Kings() {
    const toPass = 'K'
    const { data, error, isLoading } = useFetchKingsAristocratsQuery(toPass);
    let lowerPart = '';
    let x,y,z, z1, z2, z3;
    x = "Dividend Kings are a select group of companies that have a long and impressive track record of consistently increasing their dividends year after year. ";
    y = "These are often considered some of the most reliable and stable dividend-paying companies in the stock market."
    z = "To be classified as a Dividend King, a company must meet the following criteria:";
    z1 = "1. Dividend history: The company must have increased its dividend payout annually at least 50 consecutive years. This demonstrates a long-standing commitment to rewarding shareholders through dividend payments";
    z2 = "2. Market capitalization: Dividend Kings are typically large-cap companies with substantial market capitalization. this creterion ensures that the companies are well established and and have the financial stability to sustain their dividend growth"
    z3 = "3. Consistent Performance: Dividend Kings tend to have a history of stable earnings and cash flow, which enables them to consistently raise their dividends over time. They are often leaders in their respective industries and have demonstrate their ability to navigate different economic conditions"
    
    
    const keyFn = (iets) => {
        return iets.ticker
    }

    function image(iets) {
        const name = iets;
        const x = 'https://financialmodelingprep.com/image-stock/';
        //const x = 'https://eodhistoricaldata.com/img/logos/US/'
        let y = x.concat(name).concat('.png')
        return y}
        let tablePart;
    const config = [
        { label: 'Ticker', 
        render: (xx) => (
            
            <div >
                 <div className = 'h-10 flex' >
                    <img alt = ' '  src = {image(xx.ticker)}  className = "object-cover w-8 h-8 pt-2 mr-2" />
                    
                        <div className = 'text-blue-500 font-semibold'>
                        <strong>
                            <Link key={'xxx'} to={`/Details/${xx.ticker}`}>
                                {xx.ticker}
                            </Link>
                        </strong>
                        <div className = ' text-gray-400 '>
                        <GetName ticker = {xx.ticker} />
                            </div>
                        </div>
                    
                </div>
           </div>),
        header: () => <th className = "bg-red-500 w-fit">ticker</th>,
        sortValue: (xx) => xx.ticker},
        { label: 'Price',
        render: (xx) => xx.price,
        sortValue: (xx) => xx.price},
        { label: 'Yield',
        render: (xx) => xx.div_yield,
        sortValue: (xx) => xx.div_yield},
        ];
    
    
    if (error){
        lowerPart = 'an error happening'
    }
    if (isLoading){
        lowerPart = 'Gathering data'
    }
    if(data){
        console.log(data);
        let data2 = Object.keys(data).map((key) => data[key]);
        console.log(data2);
        let content = data2;//CleanupArray(data);
        //const renderedItems = '';
        lowerPart = data.map((test) => {
            return <div>{test.ticker} - {test.price} - {test.div_yield}</div>
        })
        //console.log('x', x);
        //lowerPart = {x}
        tablePart = <SortableTable content2 = {data} config ={config} keyFn = {keyFn} />
    }

    let weekly = 'blablabla';
    weekly = <UpcomingWeek kind = 'K' />;
    

    return (
        <div>
            <div className = 'grid grid-cols-1 md:flex md:flex-column'>
                <div className = 'visible md:hidden'>
                    <div className = 'rounded-lg bg-white shadow-md px-5 py-3 mb-5 mx-3'>
                        {weekly}
                        </div></div>
                <div className = 'mx-3 md:basis-1/2 rounded-lg bg-white shadow-md px-5 py-3'>
                    {tablePart}
                </div>
                <div className = 'mx-3 md:basis-1/2'>
                    <div className = 'invisible md:visible rounded-lg bg-white shadow-md px-5 py-3 mb-5'>
                        {weekly}</div>
                    <div className = 'rounded-lg bg-white shadow-md px-5 py-3'>
                    {x}{y}
                    <p>{z}</p>
                    <p>{z1}</p>
                    <p>{z2}</p>
                    <p>{z3}</p>
                    </div>
                </div>
            </div>
        </div>)
}

export default Kings