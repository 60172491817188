import React from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
//import { useFetchDailyValueByUserIdQuery } from '../../store';
import { useDispatch } from 'react-redux';
//import { useEffect, useState } from 'react';
import { editYesterday } from '../../store';
//import UpdateYesterdayValue from '../Portfolios/UpdateYesterdayValue';

function ApexPortfolio ({data}) {
  //console.log(data);
  const dispatch = useDispatch();
  //const [yesterday, setYesterday] = useState(0)
  const todaysValue  = useSelector((state) =>{
    //console.log(state)
    return state.value
})

//let xx;


//console.log('todays value: ', todaysValue);
const value_of_today = parseFloat(todaysValue).toFixed(2)

    
    let data2 = data;
    data2= [...data];
    //console.log('last one: ',data2.at(-1));
    let value;
    let dataOk = data2.reduce((colBuy,y) => {
        const date = y.date;    
        value = +y.value;
        colBuy[date] = (colBuy[date] || 0) + value;
        return colBuy;
    },{}); 
   
    const arrayOk = Object.entries(dataOk).reduce((acc, curr) => {
        const [key, val] = curr;
        acc.push({
            date: key,
            value: val
        });
        return acc;
    },[]);
    //below is the last date !!!!!!!!!!!
    
     

    const yydata = arrayOk.map((column) => {
      return parseFloat(column.value).toFixed(2);
    })
    //console.log(yydata);
    const xxdata = arrayOk.map((column) => {
      return column.date;
    }) 
    //const today = new Date().toLocaleDateString()
    const today = new Date().toJSON().slice(0,10);
    //const today = nu.toJSON().slice(0,10);
    //console.log ('today is ', today)
    //console.log('in Apex, xxdata = ',xxdata)
    //console.log('in Apex, last yydata = ',yydata.at(-1))
    let action2 = editYesterday(yydata.at(-1));
    //console.log('in apexportfolio, yydata at -1: ', yydata.at(-1))
    //console.log('action2: ', action2);
    //useEffect(() => {editYesterday(yydata.at(-1));});
    setTimeout(() => { dispatch(action2)}, 0);
    xxdata.push(today);
    yydata.push(parseInt(value_of_today));
    //console.log('in Apex, xxdata = ',xxdata)
    //console.log('in Apex, yydata = ',yydata)
  const series = [ //data on the y-axis
    {
      name: "Daily value",
      data: yydata 
    }
  ];
  const options = { //data on the x-axis
    chart: { id: 'bar-chart',
    toolbar: {
      show: false
    }},
    stroke: {
        curve: 'smooth',
        width: 2,
      },
    xaxis: {
      categories: xxdata
    }
  };
  //const action2 = editYesterday(xx);
    //dispatch(action2);
  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
       
        
      />
      
    </div>
  )
}
;

export default ApexPortfolio;