import { useFetchSectorsQuery } from '../../store';
import { useState, useLayoutEffect } from 'react';

function FinalGetSectorInfo(id) {
    //const [user, setUser] = useState({});
    //const [userid, setUserid] = useState('');

    //useLayoutEffect (() => {
    //  const theUser = localStorage.getItem('user');
    //  console.log('the user: ', theUser);
    //  //console.log('userid ...: ', theUser[0].firstName)
    //  if (theUser && !theUser.includes('undefined')){
    //    setUser(JSON.parse(theUser));
    //    //setUserid(theUser.userid);
    //  }
  //},[])
  //const user.userid = 3;
  //console.log('in final get sector');
  //console.log(user);
  //console.log('in finalgetsector, userid = ', id)
    const { data, error, isLoading } = useFetchSectorsQuery({user_id: id});
    if (error){}
    if (isLoading) {}
    if (data) {
        //console.log(data);
        const key = 'ticker';
        let unique = [...new Map(data.map(item => [item[key], item])).values()]
        //console.log('unique: ', unique)
    return unique}
        

}

export default FinalGetSectorInfo