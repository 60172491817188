import ApexTestAll from '../Charts/ApexTestAll';

function PortfolioDiversification({data}) {
    //console.log(data)
    let yydata = [];
    let xxdata = []
    data.sectors2.map((iets) => {
        if( iets.value > 0){
            yydata.push(Number(iets.value));
            xxdata.push(iets.sector)
        }
        //return {xdata: xxdata, ydata: yydata};
        //return <div>hello hello<ApexTestAll y_data = {yydata} x_axis = {xxdata} /></div>
    })
    //console.log(xxdata, yydata);
    return (
        <div className = 'flex'>
            <div className = 'flex-1 justify-center justify-items-center'>
                
                <ApexTestAll y_data = {yydata} x_axis = {xxdata} />
               
            </div></div>
    )
       
}

export default PortfolioDiversification