import { useFetchDailyValueByUserIdQuery } from "../../store";

function GetYesterdayValue(user) {
        console.log(user);
        const { data, error, isLoading } = useFetchDailyValueByUserIdQuery(user);
        if (error) {}
        if (isLoading) {}
        if(data){
            if(data.length === 0){
                return 0;
        }
            let data2 = data;
    data2= [...data];
    //console.log('last one: ',data2.at(-1));
    let value;
    let dataOk = data2.reduce((colBuy,y) => {
        const date = y.date;    
        value = +y.value;
        colBuy[date] = (colBuy[date] || 0) + value;
        return colBuy;
    },{}); 
   
    const arrayOk = Object.entries(dataOk).reduce((acc, curr) => {
        const [key, val] = curr;
        acc.push({
            date: key,
            value: val
        });
        return acc;
    },[]);
    //below is the last date !!!!!!!!!!!
    //console.log(arrayOk.at(-1));
    

    const x = arrayOk.at(-1)
    let xx = x.value
            return xx
        }
    
return 0
}

export default GetYesterdayValue