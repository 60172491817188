import { useFetchStocks2Query } from '../../store';
import CleanupArray from '../Portfolios/CleanupArray';
import MinMax from '../../HelperFunctions/MinMax';
import DiversificationBreakdown from './DiversificationBreakdown';
import ApexTest3 from '../Charts/ApexTest3';
//import ApexTest5 from './ApexTest5';
import ApexTest6 from '../Charts/ApexTest6';
import DividendBreakdown from './DividendBreakdown';
//import { useFetchSectorsQueryByPfId } from '../store';
//import classname from 'classnames';
//import GetSectorInfo from '../../TBD/GetSectorInfo';
//import { useMemo } from 'react';
import PortfolioDiversification from './PortfolioDiversification';
import SectorDiversification from './SectorDiversification';
//import GetSectorsByPfId from './GetSectorsByPfId';
import TabulatedCycles from './TabulatedCycles';
import FinalGetSectorInfo from './FinalGetSectorInfo';



function DiversificationIndividual({ietske, name}) {

    const xxx = FinalGetSectorInfo(name.id)


const { data, error, isLoading } = useFetchStocks2Query(ietske);
//const data2 = useMemo(() => data,[]);

    let content_down; 
    let result;
    let newArray;
    //let loaded = false;
    if (isLoading) {
        content_down = 'loading data ...';
        
        
    } else if (error) {
        content_down = 'error ...';
        
         
    } else if (data) {
        //const data2 = useMemo(() => data,[]);
    const data2 = [...data]
    console.log(data2);
        //console.log('nu ok');
        let newArrayOG = data2;
        newArrayOG = [...data2]
        const { cleanArray, total2} = CleanupArray(newArrayOG);
        //const x = useMemo(() => CleanupArray(newArrayOG) ,[]);
        //console.log(x);
        

       
        newArray = cleanArray;
        newArray = [...cleanArray]
        //console.log('before minmax',newArray);
        //const pf = ietske.ietske
         result = MinMax(newArray, xxx);
         //result = <MinMax newArray = {newArray} />;
        
         //console.log(result.min);
         //return result;
        
        
        //console.log('value in listBigOne :', total2)
        //content_down = <SortableTable content2 = {newArray} config ={config} keyFn = {keyFn} value = {total2} />;
        
        //console.log(value);
        //console.log('sectors2 in newarray: ', newArray.sectors2)
        //loaded = true;
        content_down = (
            <div className = ''>
                 <div className = 'grid grid-cols-1 md:flex md:flex-row'>
                    <div className = 'md:flex-1 rounded-lg bg-white shadow-md md:px-5 md:py-3 mb-5 my-2 md:mr-5 mx-3 pt-5 md:pt-0'>
                        <div className = 'flex justify-center  text-bold text-blue-600 '>
                        <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3 md:my-3'>
                                        Diversification Breakdown
                                    </button>
                        </div>
                        <div  className = 'grid grid-cols-1 md:flex md:flex-column md:content-center '>
                            <div className = 'md:w-1/2'>
                            <DiversificationBreakdown data = {result} value = {total2} type = 'individual' user = {ietske} />
                            </div>
                            <div className = 'md:w-1/2 md:ml-5'>
                            <ApexTest3 donutData = {newArray} />
                            </div>
                        </div>
                    </div>
                </div> 
                

                <div className = 'grid grid-cols-1 md:flex md:flex-row'>
                    <div className = 'flex-1 rounded-lg bg-white shadow-md md:px-5 md:py-3 mb-5 my-2 md:mr-5 mx-3 pt-10'>
                        <div className = 'flex justify-center  text-blue-600'>
                        <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3 md:my-3'>
                                        Dividend Breakdown
                                    </button>
                        </div>
                        <div  className = 'grid grid-cols-1 md:flex md:flex-column md:content-center'>
                        <div className = 'md:w-1/2'>
                            <DividendBreakdown data = {result}  />
                        </div>
                        <div className = 'md:w-1/2 md:ml-5'>
                            <ApexTest6 donutData = {newArray} />
                        </div>
                        </div>
                    </div>
                </div>

                <div className = 'flex flex-col md:flex-row'>                    
                    <div className = 'flex basis-1/2 rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                        <div className = 'flex-1'>              
                            <div className = 'flex justify-center  text-blue-600'>
                            <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3'>
                                        Sector Breakdown
                                    </button>
                            </div>   
                                                    
                            <PortfolioDiversification data = {result} /> 
                        </div>             
                    </div>
                
                    <div className = 'flex basis-1/2 rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>
                        <div className = 'flex-1'>              
                            <div className = 'flex justify-center  text-blue-600'>
                            <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3'>
                                        Cycle Breakdown
                                    </button>
                            </div>
                
                        <SectorDiversification data = {result} />
                        </div>
                    </div>
                </div>
            



                
               
                
                
                <div className = 'flex-1 flex-row'>
                    <div className = 'shadow-md bg-white rounded-lg space-x-5 py-2  mb-3 pl-5 my-2 mr-5'>
                    <div className = 'flex justify-center  text-blue-600'>
                        <button 
                                disabled = {true}
                                className = 'border rounded-full border-2 font-bold border-blue-600 px-2 mb-3'>
                                        Tabulated Cycles
                                    </button>
                        </div>
                    
                    <TabulatedCycles stockData = {newArray} stockSector = {xxx} />
                    </div>
                </div>
                
            </div>
        )
    }
return <div>{content_down}</div>
}

export default DiversificationIndividual