import { configureStore, createSlice } from '@reduxjs/toolkit';
//import { stocksReducer, addTicker } from './slices/stocksSlice';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { portfoliosApi } from './apis/portfoliosApi';
import { stocks2Api } from './apis/stocksApi';
import { detailsApi } from './apis/detailsApi';
import { priceApi } from './apis/currentPriceApi';
import { dailyValueApi } from './apis/dailyValueApi';
import { stockDetailsApi } from './apis/stockDetailsApi';
import { usersApi } from './apis/usersApi';
import { searchApi } from './apis/searchApi';

const valueSlice = createSlice({
    name: 'value',
    initialState: [0,0],
    reducers: {
        editValue(state, action){
            //console.log(action.payload);
            //console.log('received in store');
            state.splice(0,1, action.payload)
            return state.value;
        },
    }
});
const yesterdayValueSlice = createSlice({
    name: 'yesterday',
    initialState: [0,0],
    reducers: {
        editYesterday(state, action2){
            //console.log(action2.payload);
            //console.log('received in store');
            state.splice(1,1, action2.payload)
            return state.yesterday;
        },
    }
});

const store = configureStore({
    reducer: {  
        value: valueSlice.reducer,
        yesterday: yesterdayValueSlice.reducer,
        users: usersApi.reducer,
        stockDetails: stockDetailsApi.reducer,
        portfolios: portfoliosApi.reducer,
        stocks2: stocks2Api.reducer,
        currentPrice: priceApi.reducer,
        dailyValue: dailyValueApi.reducer,
        search: searchApi.reducer,
        details: detailsApi.reducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
        .concat(portfoliosApi.middleware)
        .concat(stocks2Api.middleware)
        .concat(priceApi.middleware)
        .concat(dailyValueApi.middleware)
        .concat(stockDetailsApi.middleware)
        .concat(usersApi.middleware)
        .concat(searchApi.middleware)
        .concat(detailsApi.middleware)
    }
});

//const startingState = store.getState();
//console.log(JSON.stringify(startingState));




//console.log(valueSlice.actions);
//const finalState = store.getState();
//console.log(JSON.stringify(finalState));

setupListeners(store.dispatch);
//from the 4th one useGetStockScreenerQuery, useGetStockNewsQuery 
export { store };
export const { editValue } = valueSlice.actions;
export const { editYesterday } = yesterdayValueSlice.actions;
//export const { editArray } = dataArraySlice.actions;
export { useGetDividendsQuery, useGetStockDetailsQuery, useGetPriceHistoryQuery, useGetStockAnalysisQuery, useGetStockAnalysis_2Query, useGetPricePercentageQuery, useGetScreenerQuery, useGetNewsQuery } from './apis/detailsApi';
export { useSignupMutation, useLoginQuery } from './apis/usersApi';
export { useFetchTickersQuery, useFetchNameQuery } from './apis/searchApi';
export { useGetStockHistoryQuery,  } from './apis/stockDetailsApi'
export { useFetchPortfoliosQuery, useFetchSinglePortfolioQuery, useAddPortfolioMutation } from './apis/portfoliosApi';
export { useDeleteEntryMutation, useEditEntryMutation, useFetchHistorySingleQuery, useFetchHistoryAllQuery, useGetCompetitorsQuery ,useAddTickerToPortfolioMutation, useFetchStocks2Query, useFetchStocksByUserIdQuery, useFetchStocksAndPricesQuery, useFetchDividendsYearQuery } from './apis/stocksApi';
export { useFetchCurrentPriceQuery, useFetchBulkCurrentPriceQuery } from './apis/currentPriceApi';
export { useFetchUpcomingWeekQuery, useFetchKingsAristocratsQuery, useFetchDailyValueByPfIdQuery, useFetchDailyValueByUserIdQuery, useFetchSectorsQuery, useFetchSectorsByPfQuery } from './apis/dailyValueApi';