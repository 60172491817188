//import { Fragment } from 'react';
//import CyclesDiversification from './CyclesDiversification';

function TabulatedCycles({stockData, stockSector}) {

    
    const Cyclical = [{id:1, name: 'Basic materials'}, {id:2, name:'Consumer Cyclical'}
                    , {id:3, name:'Financial Services'}, {id:4, name:'Real Estate'}];
    const Defensive = [{id: 5, name: 'Consumer staples'}, {id: 6, name: 'Healthcare'},
                        {id: 7, name: 'Utilities'}];
    const Sensitive = [{id: 8, name:'Communication Services'},{id: 9, name: 'Energy'}, 
                        {id: 10, name:'Industrials'}, {id: 11, name: 'Technology'}];  
    
    
                        
    //stockData.map((iets) => {
      
      //  iets.sector = 'sect'
    //})
    //console.log('stockdata nieuw: ', stockData)
    stockData = stockData.map(e => {
        const bla = stockSector.find((y) => 
            y.ticker === e.ticker);
        return {...e, sector: bla.sector};
    });
    //console.log('... stockdata nieuw ...: ', stockData)

    //const t = stockData.filter((z) => 
      //  z.sector === 'Real Estate')


    //console.log('dit is t: ', t)
    //let arr = [];
    function RenderedRows({iets}) {
        //console.log(iets);
        if (!iets.length) {
            return <div key = {Math.random()} className = 'ml-4'>-</div>
        } 
        if (iets.length) {
            const ttt = iets.map((r) => {
                //console.log(r);
                //console.log('ticker in ...: ', r.ticker)
                //let result2 = result + </table>;
                return <div key= {Math.random()} className = 'ml-4'>{r.ticker}</div>
        })
        return ttt
    }
    }
    const renderedStuff = Cyclical.map((cycle) => {
        //console.log(cycle);
        const t2 = stockData.filter((z) => 
        z.sector === cycle.name)
        //console.log('t2: ',t2)
        const ja = <renderedRows iets = {t2} />;
        //console.log(ja);
        return <div key= {Math.random()} className = 'ml-2 '><div key= {Math.random()} className = 'text-blue-600'>{cycle.name}</div><div><RenderedRows iets = {t2} /></div></div>
        })
    const renderedStuff2 = Defensive.map((cycle) => {
        //console.log(cycle);
        const t2 = stockData.filter((z) => 
        z.sector === cycle.name)
        //console.log('t2: ',t2)
        const ja = <renderedRows iets = {t2} />;
        //console.log(ja);
        return <div key= {Math.random()} className = 'ml-2 '><div key= {Math.random()} className = 'text-blue-600'>{cycle.name}</div><div><RenderedRows iets = {t2} /></div></div>
        })
    const renderedStuff3 = Sensitive.map((cycle) => {
            //console.log(cycle);
            const t2 = stockData.filter((z) => 
            z.sector === cycle.name)
            //console.log('t2: ',t2)
            const ja = <renderedRows iets = {t2} />;
            //console.log(ja);
            return <div key= {Math.random()} className = 'ml-2 '><div key= {Math.random()} className = 'text-blue-600'>{cycle.name}</div><div><RenderedRows iets = {t2} /></div></div>
            })
        
        
    
        //console.log('sector in double mapping', renderedStuff)
    
    //console.log('renderedStuff; ',renderedStuff);
    //const render = renderedStuff.map((rowone) => {
      //  const rer = rowone?.map((r) => {
      //      console.log(rowone)
      //      return <div key = {r.ticker}>{r.ticker}</div>
      //  })
      //  return rer
        

    //})
   

    
    return(
        
            <div className = 'flex flex-column'>
                <div className = 'w-1/3'>
                    <div className = 'text-blue-600 font-bold'>Cyclical</div>
                    {renderedStuff}
                </div>
                <div className = 'w-1/3 '>
                <div className = 'text-blue-600 font-bold'>Defensive</div>
                    {renderedStuff2}
                </div>
                <div className = 'w-1/3'>
                <div className = 'text-blue-600 font-bold'>Sensitive</div>
                    {renderedStuff3}
                </div>
            </div>
            
        
    )
}

export default TabulatedCycles