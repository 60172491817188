import { useFetchUpcomingWeekQuery } from "../../store";

function UpcomingWeek({kind}) {
    console.log('Kind = ', kind)
    const dateOffset = (date, offset) => {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + offset);
        return newDate
    }
    const getFirstDayOfWeek = (date) => {
        const day = (date.getDay() + 6) % 7;
        return dateOffset(date, 7 - day);
    }
    const formattedDay = (date) => {
        const year = date.getFullYear();
        const month = ('0'+ (date.getMonth() + 1)).slice(-2);
        const day = ('0'+ date.getDate()).slice(-2);
        let xxx = year+'-'+month+'-'+day;
        return xxx
    }
    const firstDay = formattedDay(getFirstDayOfWeek(new Date()));
    const lastDay = formattedDay(dateOffset(getFirstDayOfWeek(new Date()),5));
    let lowerPart;
    const { data, error, isLoading } = useFetchUpcomingWeekQuery([kind, firstDay, lastDay]);
    if (isLoading) {
        lowerPart = 'Collecting Data';
        }
    if (error) {
        lowerPart = 'Oops, something went wrong'
    }
    if (data){
        console.log('in upcoming week, data = ', data);
        lowerPart = 'Jihaaaa';
        lowerPart = data.map((data2) => {
            return(
                <div>
                    {data2['next_ex_date']}: 
                    {data2['ticker']} ({data2['payout']})
                </div>
            )
        })
    }



    const currDate = new Date();
    console.log('first day of next week = :', getFirstDayOfWeek(new Date()));
    console.log('first day of next week:', formattedDay(getFirstDayOfWeek(new Date())));
    //let first = formattedDay(getFirstDayOfWeek(new Date()));
    let last_day = formattedDay(dateOffset(getFirstDayOfWeek(new Date()),5));
    //getFirstDayOfWeek(new Date()).setDate;
    console.log('last day should be: ', last_day)
    //console.log('first day of next week = :', dateOffset(currDate, 7));

    

    //console.log('first day of next week = ', formattedDay(dateOffset(currDate, 7)));
    
    return (
        <div>
            <div className = 'font-bold'>
                Ex-dividend dates upcoming week
            </div>
            <div>
                {lowerPart}
            </div>
        </div>
    )

}

export default UpcomingWeek