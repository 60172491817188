//import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { editValue } from '../../store';
import ApexTest7 from '../Charts/ApexTest7';
import CalendarMonthly from './CalendarMonthly';



function CreateCalendar(props) {
 
    const {data, value, user, type } = props;
    //console.log('user and type : ', user, type);
    const dispatch = useDispatch();
    const waarde = value;
    const action = editValue(waarde);
    useEffect(() => {dispatch(action);});




    //const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const today = new Date().toJSON().slice(0,10);
    //console.log(new Date());
    //console.log(today);
    //console.log(data.length);
    //console.log(new Date(today).getMonth())
    const start_month = new Date(today).getMonth() + 1;
    //console.log(start_month);
    const months2 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //const thisMonth = new Date(today).getMonth() + 1;

    const now = new Date();
    let month = now.getMonth();
    //console.log(month);
    //let month=4;
    let year = now.getFullYear();
    //console.log(month);

    const monthsOk = [''];
    for ( let x = 0; x < 12; x++){
        monthsOk.push(months2[month] + ' ' + year.toString().slice(2,4));
        if (++month === 12) {
            month = 0;
            ++year
        }
    }
    //console.log(monthsOk)
    
    function addMonth (entry, add) {
        let date = new Date(entry);
        let datedate = new Date(date.setMonth(date.getMonth()+add));
        let final = datedate.toISOString().split('T')[0]
        return final
    }




    //console.log(today.toLocaleString('default', {month: 'short'}))
    
    //console.log(months[i]);
    //console.log('data to work with: ',data)
    console.log('start_month (i): ', start_month);
    let complete_array = [];
    let date_array = [];
    //complete_array.push(months);
        for (let ticker = 0; ticker < data.length; ticker++) {
            const array_ticker = [data[ticker]['ticker']]; 
            //const date_array_ticker = [data[ticker]['ticker']];
            for (let i = start_month; i < start_month + 12; i++){
                //console.log(i);
            //console.log(data[ticker]['ticker'], data[ticker]['payout']);
            //console.log(new Date(data[ticker]['next_div']).getMonth())
            const m = new Date(data[ticker]['next_div']).getMonth()+1;
            //console.log('m -> ', m, data[ticker])
            if ( ((i%3) === m || (i%3) === (m-3) || (i%3) === (m-6) || (i%3) === (m-9) || (i%3) === (m-12) || (i%3) === (m+3) || (i%3) === (m+6)) && (data[ticker]['frequency'] !== 12)) {
                console.log(data[ticker]['ticker'], 'payout in month: ', m);
                //console.log(data[ticker]['ticker'], 'payout in month: ', m);
                array_ticker.push(parseFloat(data[ticker]['payout']*data[ticker]['amount']).toFixed(2));
                //console.log(data[ticker]['next_div']);
                                
                date_array.push({title: data[ticker]['ticker'],
                                        allDay: true,
                                        start: addMonth(data[ticker]['next_div'], i%m),
                                        end: addMonth(data[ticker]['next_div'], i%m)});
                                        //start: data[ticker]['next_div'],
                                        //end: data[ticker]['next_div']
                                    //});
                                    
                    
                
                //if (m > month +3 ){
                //    console.log(data[ticker]['ticker']);
                //    date_array.push({title: data[ticker]['ticker'],
                //                        allDay: true,
                //                        start: addMonth(data[ticker]['next_div'], i-3-start_month),
                //                        end: addMonth(data[ticker]['next_div'], i-3-start_month)});

                //}
            }
            if (data[ticker]['frequency'] === 12 ) {
                //console.log('payout every month')
                array_ticker.push(parseFloat(data[ticker]['payout']*data[ticker]['amount']).toFixed(2));
                date_array.push({title: data[ticker]['ticker'],
                                        allDay: true,
                                        start: addMonth(data[ticker]['next_div'], i-start_month),
                                        end: addMonth(data[ticker]['next_div'], i-start_month)});
            }
            if ( (i%3) !== m && (i%3) !== (m-3) && (i%3) !== (m-6) && (i%3) !== (m-9) && (i%3) !== (m-12) && data[ticker]['frequency'] !== 12) {
                //console.log(data[ticker]['ticker'], 'nothing at all in', months[i]);
                array_ticker.push('-');
               // date_array_ticker.push('-');
            }
            
            //if (data[ticker]['next'])
        }
        complete_array.push(array_ticker);
        //date_array.push(date_array_ticker);
        
    }
    //const keyFn = (iets) => {
    //    return iets.ticker
    //}
    //console.log(complete_array);
    let complete_array2 = JSON.parse(JSON.stringify(complete_array));
    //complete_array2 = [...complete_array];
    //console.log(complete_array2);
    //complete_array2 = complete_array2.concat(complete_array2.splice(1,month));
    //console.log(complete_array2);
    //console.log(complete_array);



    //console.log(complete_array2)
        //console.log('complete array: ',complete_array);
        //console.log('complete array2: ',complete_array2);
        //console.log('date array: ',date_array);
        let perMonth = [];
        for (let columns = 1; columns < 13; columns++){
            let total = 0;
            for (let rows = 0; rows < complete_array.length; rows++){
                let subtotal = 0;
                //console.log(complete_array2[rows][columns+1])
                if (complete_array[rows][columns] === '-'){
                    complete_array[rows][columns] = 0;
                }
                total = Number(complete_array[rows][columns]) + total;
            }
            //console.log(total);
            perMonth.push(parseFloat(total).toFixed(2));

        }
        //perMonth  = perMonth.concat(perMonth.splice(0,month));
        //console.log(perMonth);


    const topRow = monthsOk.map((column) => {
        if (column === ''){return <th className = 'sticky left-0 bg-white'>{column}</th>}
        return <th key ={column}>{column}</th>
    });

    //complete_array = complete_array.concat(complete_array.splice(0,month));
    console.log('Complete array before renderedRows: ',complete_array);
    const renderedRows = complete_array.map((rowData) => {
        //rowData = rowData.concat(rowData)//);
        
        const rowDataArray = Object.keys(rowData).map((key) => rowData[key])
            //console.log('in rendering rows: ',rowDataArray);
            const renderedCells = rowDataArray.map((column) => {
            if (isNaN(column)){
                console.log('has to be sticky,', column);
                return <td key = {Math.random()} className = 'sticky left-0 bg-white'>{column}</td>
                }
            if (column === 0){
                column = '-';
            }

            return <td key={Math.random()}>{column}</td>

        });
            //console.log(renderedCells)
            return (<tr key={rowData[0]}>
                {renderedCells}
                </tr>)
        }) ;

        let Mon = perMonth;
        Mon = [...perMonth]
        ////console.log(Mon);
        //console.log(monthsOk.slice(1))
        //console.log(months2.slice(1));
        //console.log(complete_array);
        //console.log(date_array);
        const name = 'Monthly dividend'
    
    return (
    <div>
    <div  className='overflow-x-scroll  overflow-y-hidden rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
        <table className='w-full'>
            <thead>
                <tr key = 'werty'>
                    {topRow}
                </tr>
            </thead>
            <tbody>
                {renderedRows}
            </tbody>
        </table>
        
        
       
    </div>
    <div  className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
        <ApexTest7 y_data = {perMonth} x_axis = {monthsOk.slice(1)} name = {name}/>
    </div>
    <div  className='rounded-lg bg-white shadow-md px-2 mx-2 md:px-5 py-3 mt-10 md:mt-5 mb-5 my-2 md:mr-5'>
        <CalendarMonthly data = {date_array} user = {user} type = {type}/>
    </div>
    </div>)
} 

export default CreateCalendar