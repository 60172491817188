import { useFetchNameQuery } from '../store';

function GetName(ticker) {

    let result = ''
    const { data, error, isLoading } = useFetchNameQuery(ticker.ticker);
    if (isLoading || error) {result = 'Gathering data ...'}
    if (data) {
        if(data[0]) {result = data[0].name}
    }

    return <div >{result}</div>
 
    
}

export default GetName