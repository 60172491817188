import React from 'react';
import Chart from "react-apexcharts";
//import { useFetchDailyValueByPfIdQuery } from '../store';

const ApexTestAll = ({y_data, x_axis}) => {
    //console.log('in apex part');
    //console.log('xx: ',x_axis);
    //console.log('yy: ',y_data);
    
    
    //const yydata = y_data.map((column) => {
    //  return column.value;
    //})
    //const xxdata = x_axis.map((column) => {
    //  return column.date;
    //})

  //const series = [ //data on the y-axis
  //  {
  //    name: "Dividends per year",
  //    data: y_data 
  //  }
  //];
  const series = y_data;
  const options = { //data on the x-axis
    //chart: { id: 'bar-chart'},
    //stroke: {
    //    curve: 'smooth',
    //    width: 2,
    //  },
   // xaxis: {
    //  categories: x_axis
    //}
    labels: x_axis,
    legend: {
      show: true,
      position: 'bottom'
    }
  };

  return (
    <div >
      <Chart
        options={options}
        series={series}
        type="pie"
        width="100%"
       />
      
    </div>
  )
};

export default ApexTestAll;