import { useGetCompetitorsQuery } from "../../store"
import GetName from '../../HelperFunctions/GetName';
import { Link } from 'react-router-dom';

function Competitors(x, price) {
const { data, error, isLoading } = useGetCompetitorsQuery(x.x);
    let lowerPart;
    console.log('in competitors, price = ', price)
    
    if (isLoading || error) {lowerPart = 'Gathering data ...'}
    if (data) {
        lowerPart = data[0].peersList.map((something) => {
            return (<div key = {something}>
                
                   <div className = 'flex flex-col'>  
                          
                <div >
                    <Link key={'xxx'} to={`/Details/${something}`}>{something}</Link>
                </div>
                <div className = 'text-sm text-neutral-400'><GetName ticker = {something} /></div>
                </div>
            </div>)
        })}
    return <div className='rounded-lg bg-white shadow-md px-5 py-3 mb-5 my-2 mr-5'>{lowerPart}</div>
}

export default Competitors