import SidebarSmall from '../components/SidebarSmall'

function SuccessPayment() {
    return (
    <div>
        <div className = 'md:invisible'>
    <SidebarSmall />
    
    </div>
    <div className = 'mt-10 md:mt-0'>Congratulations with your subscription</div>
    </div>)
}

export default SuccessPayment