//import GetRecentPrice from './getRecentPrice';




function CleanupArray (data) {
    //console.log('in CleanupArray');
    //console.log(data);
    //const test2 = GetRecentPrice('AAPL')
    
        let colAmount = data.reduce((colAmount,x) => {
            const ticker = x.ticker;
            const amount = +x.amount;
            colAmount[ticker] = (colAmount[ticker] || 0) + amount;
            return colAmount;
        },{});

        
        const arrayAmount = Object.entries(colAmount).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
                ticker: key,
                amount: val
            });
            return acc;
        },[]);
        //console.log(arrayAmount);

        




        let colPrice = data.reduce((colPrice,x) => {
            const ticker = x.ticker;
            const price = +(x.amount * x.price);
            colPrice[ticker] = (colPrice[ticker] || 0) + price;
            return colPrice;
        },{});    
        //console.log(colPrice);

        const arrayPrice = Object.entries(colPrice).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
                ticker: key,
                price: val
            });
            return acc;
        },[]);



        //const i = 0;
        let colFreq = data.reduce((colFreq,x) => {
            const ticker = x.ticker;
            const frequency = x.frequency;
            colFreq[ticker] = (colFreq[ticker] || 0) + frequency;
            return colFreq;
        },{});    
        //console.log(i);
        //console.log(colFreq);

        const arrayFreq = Object.entries(colFreq).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
                ticker: key,
                frequency: parseInt(val)
            });
            return acc;
        },[]);
        let colPay = data.reduce((colPay,x) => {
            const ticker = x.ticker;
            const payout =  x.payout;
            colPay[ticker] = (colPay[ticker] || 0) + payout;
            return colPay;
        },{});    
        //console.log(colPay);

        const arrayPay = Object.entries(colPay).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
                ticker: key,
                payout: val
            });
            return acc;
        },[]);
        const arrayNextDiv = Object.entries(arrayPay).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
                //ticker: key,
                next_div: val
            });
            return acc;
        },[]);





        ////console.log('---calculate buy price');
        //console.log(data);
        let buy_price;
        let colBuy = data.reduce((colBuy,y) => {
            const ticker = y.ticker;   
            //console.log(ticker);   
           
            buy_price = +(y.amount * y.buy_price);
            
            

            //console.log(y.amount, y.buy_price);
            colBuy[ticker] = (colBuy[ticker] || 0) + buy_price;
            return colBuy;
        },{});    
        //console.log(colBuy);
        //console.log('------------------')

        const arrayBuy = Object.entries(colBuy).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
                ticker: key,
                buy_price: val
            });
            return acc;
        },[]);
        

        const totalValue = arrayPrice.reduce((partial, a) => 
            partial + a.price, 0       
        );
        //console.log('total value: ', totalValue)
        //console.log('calc total');
        //console.log(arrayPrice);
        //console.log(totalValue);

        //console.log(arrayPrice);
        //create array
        //console.log('end_result_obj2');
        
        let end_result_obj2 = arrayAmount.map((item, i) => Object.assign({}, item, arrayAmount[i]));
        //console.log(end_result_obj2);//let end_result = Object.entries(end_result_obj);
        //console.log('end_result_obj3');
        let end_result_obj3 = end_result_obj2.map((item, i) => Object.assign({}, item, arrayPrice[i]));
        //console.log(end_result_obj3);
        //console.log('end_result_obj4');
        let end_result_obj4 = end_result_obj3.map((item, i) => Object.assign({}, item, arrayBuy[i]));
        //console.log(end_result_obj4);
        let end_result_obj5 = end_result_obj4.map((item, i) => Object.assign({}, item, arrayFreq[i]));
        let end_result_obj6 = end_result_obj5.map((item, i) => Object.assign({}, item, arrayPay[i]));
        let end_result_obj7 = end_result_obj6.map((item, i) => Object.assign({}, item, arrayNextDiv[i]));
        
        //const end_resulta = Object.keys(end_result_obj4).map((key) => end_result_obj4[key]);
        const end_result = Object.keys(end_result_obj7).map((key) => end_result_obj7[key]);
        //console.log(end_result);
        let newArrayOG = data;

        newArrayOG = [...data]
        //console.log(newArrayOG);

        //const end_result3 = end_result.reduce((a,b) => {
          //  const a1 = data.find(e => e.ticker === b.ticker)||{};
          //  return a.concat(Object.assign(a1,b));
        //},[])
      //console.log(end_result);
      //console.log(newArrayOG);

      
        
            
      //end_result.forEach((x) => {
        
        //console.log(x.ticker);
        //const bla = newArrayOG.find((y) => y.ticker === x.ticker);
        //console.log(bla.frequency);
        //Object.assign(bla, end_result)
        //x.frequency = bla.frequency;
       // x.frequency.splice(0,1, bla);
            
        //});

        let end_result3333 = end_result.map(e => { 
            const bla = newArrayOG.find((y) => 
                y.ticker === e.ticker);
                return { ...e, frequency: bla.frequency};
            })

        let end_result4444 = end_result3333.map(e => { 
            const bla = newArrayOG.find((y) => 
                y.ticker === e.ticker);
                return { ...e, payout: bla.payout};
            })
            let end_result5555 = end_result4444.map(e => { 
                const bla = newArrayOG.find((y) => 
                    y.ticker === e.ticker);
                    return { ...e, next_div: bla.next_div};
                })


        //console.log(end_result3333);
        //console.log(end_result4444);
        
      

        //const end_result2 = end_result.map(item => {
        //    const payout = data.find(i2 => i2.ticker === item.ticker);
         //   return payout ? {...item, ...payout}: payout;
        //})
        //const end_result3 = end_result2.map(item => {
        //    const frequency = data.find(i2 => i2.ticker === item.ticker);
        //    return frequency ? {...item, ...frequency}: frequency;
        //})
        
        //console.log('converting to array')
        //const da = [{name: 'AAPL', amount: 2},
                   // {name: 'eee', amount:34}];
        //console.log(da);
        //console.log(typeof da);
        //console.log(typeof end_result_obj);
        //console.log(end_result);
        //console.log(end_result2);
        //console.log(end_result_obj6);
        //console.log(end_result_obj5);
        //console.log(typeof end_result);
        //console.log(totalValue);
        //console.log('total value: ', totalValue)

        


        return {cleanArray: end_result5555, total2: totalValue};
}
 

export default CleanupArray;