import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//import dotenv from 'dotenv';

//const portnumber = 1;
const portfoliosApi = createApi({
    reducerPath: 'portfolios',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://nodejs.mydividendcompagnon.com"
    }),
    endpoints(builder) {
        return {
            






            addPortfolio: builder.mutation({
                invalidatesTags:['portfolios'],
                query: (arg) => {
                    //const {userId, name} = arg;
                    //console.log('arg: ', arg)
                    return {
                        url: '/addportfolio',
                        params: {
                            userId: arg.userId,
                            name: arg.name,

                           
                            
                        },
                        method: 'POST',
                        
                    };
                }
              }),

           


            fetchPortfolios: builder.query({
                providesTags: ['portfolios'],
                query: (name) => {
                    //console.log(name);
                    //console.log('base_url in portfoliosapi: ', process.env.REACT_APP_BACKEND_SERVER)
                    return {
                        url: '/allportfolios',
                        params: {
                            user_id: name.id 
                        },
                        method: 'GET',
                    };
                    
                },
            }),

            fetchSinglePortfolio: builder.query({
                
                query: (name) => {
                    console.log(name);
                    return {
                        url: '/singleportfolio',
                        params: {
                            id: name.pf 
                        },
                        method: 'GET',
                    };
                    
                },
            }),
        };
    },

});

export const { useFetchPortfoliosQuery, useFetchSinglePortfolioQuery, useAddPortfolioMutation } = portfoliosApi;
export { portfoliosApi };