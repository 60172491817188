import { useFetchHistoryAllQuery } from "../../store";

function GetHistoryBigOne(user_id) {
    //console.log('in GetHistoryBigOne; ', user_id);
        const { data, error, isLoading } = useFetchHistoryAllQuery(user_id);
        if (error) {}
        if (isLoading) {}
        if(data){
            if(data.length === 0){
                return 0;
        }
            //let data2 = data;
            //data2= [...data];
            //console.log('last one: ',data2.at(-1));
            //console.log('history data ...: ', data);
    
            return data;
        }
    
return data
}

export default GetHistoryBigOne