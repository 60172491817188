import { useEffect, useState } from 'react';
import { useFetchPortfoliosQuery } from '../store';
import CleanupArray from '../components/Portfolios/CleanupArray';

function MiddlePart() {
    //const name = {id:6};
     const [user, setUser] = useState({});
    const [userid, setUserid] = useState('');

    useEffect (() => {
      const theUser = localStorage.getItem('user');
      //console.log('the user: ', theUser);
      //console.log('userid ...: ', theUser[0].firstName)
      if (theUser && !theUser.includes('undefined')){
        setUser(JSON.parse(theUser));
        setUserid(theUser.userid);
      }
  },[])
  //console.log('user: ', user);
  //console.log('user_id from usestate: ', userid)
  //setUserid(user.userid);
  const name = {id: user.userid};
  //console.log('name to pass: ',name);
    //console.log(props);
    //const name = {props};
    //console.log(name);
    const { data, error, isLoading } = useFetchPortfoliosQuery(name);

    


    

      let content,content2, big_one, data3;
      let loaded= false;
    if (isLoading){
        content = 'wait';
        //loaded = false;
        //return content;
    } else if (error){
        content = 'there is an error';
       // loaded = false;
        //return content;
    } else {
        //big_one = data;
        //console.log(data);
        //return data
        data3 = Object.keys(data).map((key) => data[key]);
        //console.log(data3)
        content = data3;//CleanupArray(data);
        loaded = true;
        //console.log('in data part')
        //console.log(data3);

        };
    //{data ?  content = big_one : content = content2}
    return {ContentMiddlePart: content, data_middle_loaded: loaded};
    }

    export default MiddlePart;